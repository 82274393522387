import dayjs from 'dayjs';
import { boolean, object, string } from 'yup';

import { isFullyQualifiedDomainName } from '../../common/utils/domains-regex';

export const DOMAIN_IMPORTS_FORM_ERRORS = Object.freeze({
  contactSetId: 'Select a contact set.',
  invalidDomainName: 'Type a valid domain name.',
  registryCreationDate: 'Type a valid registry creation date.'
});

export const importDomainSchema = handleInvalidForm =>
  object({
    contactSetId: object({
      helperText: string().default(''),
      isError: boolean().oneOf([false]).default(false),
      value: string()
        .default('')
        .min(1, () =>
          handleInvalidForm(
            'contactSetId',
            DOMAIN_IMPORTS_FORM_ERRORS.contactSetId
          )
        )
    }),
    domainName: object({
      helperText: string().default(''),
      isError: boolean().oneOf([false]).default(false),
      isImportBtnDisabled: boolean().default(true),
      value: string()
        .default('')
        .test('is-valid-domain-name', value =>
          isFullyQualifiedDomainName(value)
            ? true
            : handleInvalidForm(
                'domainName',
                DOMAIN_IMPORTS_FORM_ERRORS.invalidDomainName
              )
        )
    }),
    registryCreationDate: object({
      helperText: string().default(''),
      isError: boolean().default(false),
      touched: boolean().default(false),
      value: string()
        .default('')
        .test('is-valid', value => {
          return value === '' || dayjs(value).isValid()
            ? true
            : handleInvalidForm(
                'registryCreationDate',
                DOMAIN_IMPORTS_FORM_ERRORS.registryCreationDate
              );
        })
    })
  });

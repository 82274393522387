import { generateId } from '../../common/utils/generate-id';
import { recordSchema } from './dns-records-schema';

const getRecord = ({
  handleHexStringValidation,
  handleKeyTagValidation,
  handleRequiredValidation,
  value
}) =>
  recordSchema({
    handleHexStringValidation,
    handleKeyTagValidation,
    handleRequiredValidation,
    value
  }).getDefault();

export const dnsRecordsReducer = (state, action) => {
  switch (action.type) {
    case 'INIT':
      state = action.value.map(value => {
        return getRecord({
          handleHexStringValidation: action.handleHexStringValidation,
          handleKeyTagValidation: action.handleKeyTagValidation,
          handleRequiredValidation: action.handleRequiredValidation,
          value: value
        });
      });
      break;
    case 'ADD_FIELD':
      state = state.concat(
        getRecord({
          handleHexStringValidation: action.handleHexStringValidation,
          handleKeyTagValidation: action.handleKeyTagValidation,
          handleRequiredValidation: action.handleRequiredValidation,
          value: { id: generateId, isNewDnsRecord: true, touched: true }
        })
      );
      state.touched = true;
      break;
    case 'REMOVE_FIELD':
      const recordToRemove = state.find(
        dnsRecord => dnsRecord.id === action.id
      );
      if (recordToRemove.isNewDnsRecord) {
        state = state.filter(dnsRecord => dnsRecord.id !== action.id);
      } else {
        recordToRemove.isDeleted = true;
        recordToRemove.touched = true;
      }
      break;
    case 'UPDATE':
      const recordToUpdate = state.find(
        dnsRecord => dnsRecord.id === action.id
      );
      recordToUpdate[action.field].value = action.value;
      recordToUpdate[action.field].touched = true;
      recordToUpdate[action.field].errorMessage = '';
      recordToUpdate[action.field].hasError = false;
      recordToUpdate.touched = true;
      break;
    case 'SET_ERROR':
      const [errorIndex, errorPath] = action.path.split('.');
      const convertedErrorIndex = errorIndex.replace(/[[\]']+/g, '');
      state[convertedErrorIndex][errorPath].errorMessage = action.errorMessage;
      state[convertedErrorIndex][errorPath].hasError = true;
      break;
    default:
      break;
  }
  return [...state];
};

import { Stack } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import {
  DataGridPro,
  // eslint-disable-next-line import/named
  getGridDateOperators,
  // eslint-disable-next-line import/named
  getGridSingleSelectOperators,
  useGridApiRef
} from '@mui/x-data-grid-pro';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import { exportTypes } from '../../common/components/domains-grid-toolbar-export/export-types';
import { DomainsTabs } from '../../common/components/domains-tabs/DomainsTabs';
import { DomainsToolbar } from '../../common/components/domains-toolbar/DomainsToolbar';
import { LabelCell } from '../../common/components/label/LabelCell';
import { LabelsFilterInput } from '../../common/components/labels-filter-input/LabelsFilterInput';
import { config } from '../../common/config/config';
import { useAppInfoQuery } from '../../common/store/app-info-api-slice';
import { selectCustomerName } from '../../common/store/customers-slice';
import {
  useGetCustomerDomainsQuery,
  useGetCustomerNameServersQuery
} from '../../common/store/domains-api-slice';
import {
  useGetLabelAssociationsQuery,
  useGetLabelsQuery
} from '../../common/store/labels-api-slice';
import { selectReseller } from '../../common/store/reseller';
import { useGetTldsPricingByCustomerQuery } from '../../common/store/tlds-api-slice';
import { gridBasePaths } from '../../common/utils/grid-base-paths';
import { gridColumnWidths } from '../../common/utils/grid-column-widths';
import { gridHandleOnPaginationModelChange } from '../../common/utils/grid-handle-page-change';
import { selectIsSuperAdmin } from '../auth/auth-slice';
import { AttributesCell } from './AttributesCell';
import { AutoRenewCell } from './AutoRenewCell';
import { AutomationFilterInput } from './AutomationFilterInput';
import { DomainsDetails } from './DomainsDetails';
import { ExpiresCell } from './ExpiresCell';
import { LockCell } from './LockCell';
import { NameServersFilterInput } from './NameServersFilterInput';
import {
  selectApiFilters,
  selectAutomationData,
  updateFilters
} from './domain-filters-slice';
import {
  DEFAULT_DOMAINS_SORT_DIR,
  DEFAULT_DOMAINS_SORT_FIELD
} from './domains-sort-defaults';
import {
  selectSelectedDomainIds,
  setSelectedDomains
} from './selected-domains-slice';

const dataGridInitialState = {
  columns: {
    columnVisibilityModel: {
      manual: false
    }
  }
};

export const Domains = ({ archived }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const sortModel = [
    {
      field: searchParams.get('sort') ?? DEFAULT_DOMAINS_SORT_FIELD,
      sort: searchParams.get('dir') ?? DEFAULT_DOMAINS_SORT_DIR
    }
  ];

  const isSuperAdmin = useSelector(selectIsSuperAdmin);

  const { automationType, find, fromDate, labelIds, nameServers, toDate } =
    useSelector(selectApiFilters);

  const automationData = useSelector(selectAutomationData);

  const { page = 0, pageSize = config.DEFAULT_TABLE_ROWS_PER_PAGE } =
    useParams();

  const offset = Number(page) * Number(pageSize);

  const reseller = useSelector(selectReseller);
  const customerName = useSelector(selectCustomerName);

  const apiRef = useGridApiRef();
  const selectedDomainIds = useSelector(selectSelectedDomainIds);
  useEffect(() => {
    apiRef.current.setRowSelectionModel(selectedDomainIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiRef, selectedDomainIds.length]);

  const { data: appInfoData, isSuccess: isAppInfoSuccess } = useAppInfoQuery();
  const { data: associationsData, isSuccess: isLabelsAssociationsSuccess } =
    useGetLabelAssociationsQuery(
      {
        customerName,
        reseller
      },
      { skip: !customerName }
    );
  const { data: labelsData, isSuccess: isLabelsSuccess } = useGetLabelsQuery(
    {
      customerName,
      reseller
    },
    {
      skip: [customerName, isLabelsAssociationsSuccess, true].some(
        isTruthy => !isTruthy
      )
    }
  );
  const { data: nameServersData, isSuccess: isNameServersSuccess } =
    useGetCustomerNameServersQuery(
      {
        customerName,
        reseller
      },
      { skip: !customerName }
    );
  const { data: tldsData, isSuccess: isTldsSuccess } =
    useGetTldsPricingByCustomerQuery(
      {
        customerName,
        reseller
      },
      { skip: !customerName }
    );

  const { data, isFetching, isUninitialized } = useGetCustomerDomainsQuery(
    {
      active: !archived,
      appInfoData,
      associationsData,
      automationType,
      customerName,
      dir: sortModel[0].sort,
      find,
      fromDate,
      labelIds,
      labelsData: labelsData?.flat,
      maxResults: pageSize,
      nameServers,
      offset,
      reseller,
      sort: sortModel[0].field,
      tldsData,
      toDate
    },
    {
      refetchOnMountOrArgChange: true,
      skip: [
        isLabelsAssociationsSuccess,
        isLabelsSuccess,
        isTldsSuccess,
        isAppInfoSuccess,
        isNameServersSuccess
      ].some(isSuccess => !isSuccess)
    }
  );

  // updates checkedDomains when a refetch is triggered from one of the child components
  useEffect(() => {
    if (data?.totalCount && apiRef?.current) {
      dispatch(
        setSelectedDomains(
          Array.from(apiRef.current.getSelectedRows()).map(row => row[1])
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, apiRef]);

  const handleSortModelChange = newSortModel =>
    setSearchParams(
      new URLSearchParams({
        dir: newSortModel[0].sort,
        sort: newSortModel[0].field
      }).toString()
    );

  const handleFilterModelChange = newFilterModel =>
    dispatch(
      updateFilters({
        nameServersData,
        newFilterModel
      })
    );

  const handlePaginationModelChange = ({ page, pageSize }) =>
    gridHandleOnPaginationModelChange({
      dir: sortModel[0].sort,
      gridBasePath: archived ? gridBasePaths.archived : gridBasePaths.domains,
      navigate,
      page,
      pageSize,
      sort: sortModel[0].field
    });

  const handleSelectionModelChange = (newSelectionModel, { api }) => {
    dispatch(
      setSelectedDomains(Array.from(api.getSelectedRows()).map(row => row[1]))
    );
  };

  const columns = useMemo(
    () => {
      const columns =
        archived === true
          ? [
              {
                field: 'fullDomainName',
                filterable: false,
                flex: 0.8,
                headerName: 'Domain Name',
                valueGetter: params => params.row.domainName
              },
              {
                field: 'addedDate',
                filterOperators: getGridDateOperators().filter(operator =>
                  ['after', 'before', 'is'].includes(operator.value)
                ),
                flex: 0.75,
                headerName: 'Added to Registrar',
                valueGetter: params =>
                  params.row.$registrarCreationDateFormatted
              },
              {
                field: 'status',
                filterable: false,
                flex: 0.75,
                headerName: 'Status',
                valueGetter: params => params.row.$mostRecentActionType
              },
              {
                ...gridColumnWidths.labels,
                field: '$labels',
                filterOperators: getGridSingleSelectOperators()
                  .filter(operator => operator.value === 'is')
                  .reverse()
                  .map(operator => ({
                    ...operator,
                    InputComponent: LabelsFilterInput,
                    InputComponentProps: { labelsData }
                  })),
                headerName: 'Labels',
                renderCell: ({ value }) => <LabelCell value={value} />,
                sortable: false
              }
            ]
          : [
              {
                ...gridColumnWidths.domainName,
                field: 'fullDomainName',
                filterable: false,
                headerName: 'Domain Name',
                valueGetter: params => params.row.domainName
              },
              {
                ...gridColumnWidths.formattedDate,
                field: 'expirationDate',
                filterable: false,
                headerName: 'Expires',
                renderCell: params => <ExpiresCell params={params} />,
                width: 150
              },
              {
                ...gridColumnWidths.formattedDate,
                field: 'renewalDate',
                filterable: false,
                headerName: 'Renews',
                valueGetter: params => params.row.$renewalDateFormatted
              },
              {
                ...gridColumnWidths.formattedDate,
                field: 'addedDate',
                filterOperators: getGridDateOperators().filter(operator =>
                  ['after', 'before', 'is'].includes(operator.value)
                ),
                headerName: 'Added to Registrar',
                valueGetter: params =>
                  params.row.$registrarCreationDateFormatted
              },
              {
                field: 'nameServerInfos',
                filterOperators: getGridSingleSelectOperators()
                  .filter(operator =>
                    ['isAnyOf', 'not'].includes(operator.value)
                  )
                  .reverse()
                  .map(operator => ({
                    ...operator,
                    InputComponent: NameServersFilterInput,
                    InputComponentProps: {
                      nameServers,
                      nameServersData
                    }
                  })),
                flex: 1,
                headerName: 'Name Servers',
                sortable: false,
                valueGetter: params => params.row.$nameServerInfosFormatted
              },
              {
                ...gridColumnWidths.labels,
                field: '$labels',
                filterOperators: getGridSingleSelectOperators()
                  .filter(operator => operator.value === 'is')
                  .reverse()
                  .map(operator => ({
                    ...operator,
                    InputComponent: LabelsFilterInput,
                    InputComponentProps: { labelsData }
                  })),
                headerName: 'Labels',
                renderCell: ({ value }) => <LabelCell value={value} />,
                sortable: false
              },
              {
                ...gridColumnWidths.icon,
                align: 'center',
                field: 'lockStrength',
                filterable: false,
                headerAlign: 'center',
                headerName: 'Lock',
                renderCell: params => <LockCell params={params} />,
                width: 60
              },
              {
                ...gridColumnWidths.icon,
                align: 'center',
                field: 'autoBilling',
                filterable: false,
                headerAlign: 'center',
                headerName: 'Auto Renew',
                renderCell: params => <AutoRenewCell params={params} />
              },
              {
                ...gridColumnWidths.icon,
                align: 'center',
                field: 'usingLocalPresence',
                filterable: false,
                headerAlign: 'center',
                headerName: 'Attributes',
                renderCell: params => <AttributesCell params={params} />
              }
            ];

      if (isSuperAdmin) {
        columns.push({
          field: 'manual',
          filterOperators: getGridSingleSelectOperators()
            .filter(operator => operator.value === 'is')
            .reverse()
            .map(operator => ({
              ...operator,
              InputComponent: AutomationFilterInput,
              InputComponentProps: { automationData }
            })),
          flex: 0,
          headerName: 'Automation',
          sortable: false
        });
      }

      return columns;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data?.entries, labelsData]
  );

  return (
    <Stack sx={{ direction: 'column', display: 'flex', height: '100%' }}>
      <DomainsTabs index={archived === true ? 3 : 0} />
      <Grid
        container
        spacing={0}
        sx={{ flexGrow: 1, height: 'calc(100% - 48px)' }}
      >
        <Grid sx={{ height: '100%' }} xs={8}>
          <DataGridPro
            apiRef={apiRef}
            checkboxSelection
            columns={columns}
            disableRowSelectionOnClick={true}
            disableVirtualization={import.meta.env.MODE === 'test'}
            filterMode="server"
            initialState={dataGridInitialState}
            loading={isUninitialized || isFetching}
            onFilterModelChange={handleFilterModelChange}
            onPaginationModelChange={handlePaginationModelChange}
            onRowSelectionModelChange={handleSelectionModelChange}
            onSortModelChange={handleSortModelChange}
            pageSizeOptions={config.DEFAULT_TABLE_ROWS_PER_PAGE_OPTIONS}
            pagination={true}
            paginationMode="server"
            paginationModel={{ page: Number(page), pageSize: Number(pageSize) }}
            rowCount={data?.totalCount ?? 0}
            rows={data?.entries ?? []}
            slotProps={{
              filterPanel: { linkOperators: ['and'] },
              toolbar: {
                archived: archived,
                exportType: exportTypes.TRADITIONAL_DOMAINS
              }
            }}
            slots={{
              toolbar: DomainsToolbar
            }}
            sortModel={sortModel}
            sortingMode="server"
            sortingOrder={['asc', 'desc']}
            sx={{
              backgroundColor: 'white'
            }}
          />
        </Grid>
        <Grid sx={{ height: '100%' }} xs={4}>
          <DomainsDetails archived={archived} />
        </Grid>
      </Grid>
    </Stack>
  );
};

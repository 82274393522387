import { array, boolean, number, object, string } from 'yup';

/**
 *
 * @param {Function} handleRequiredValidation
 * @returns
 */
const defaultFieldSchema = (handleRequiredValidation, defaultValue) =>
  object({
    errorMessage: string().default(''),
    hasError: boolean().default(false),
    touched: boolean().default(false),
    value: string()
      .required(handleRequiredValidation)
      .default(defaultValue ?? '')
  });

/**
 *
 * @param {*} param0
 * @returns
 */
export const recordSchema = ({
  handleHexStringValidation,
  handleKeyTagValidation,
  handleRequiredValidation,
  value
}) =>
  object({
    algorithmType: defaultFieldSchema(
      handleRequiredValidation,
      () => value?.algorithmType || ''
    ),
    digest: object({
      errorMessage: string().default(''),
      hasError: boolean().default(false),
      touched: boolean().default(false),
      value: string()
        .required(handleRequiredValidation)
        .matches(/^[0-9a-fA-F]+$/, handleHexStringValidation)
        .label(value?.id)
        .default(() => value?.digest || '')
    }),
    digestType: defaultFieldSchema(
      handleRequiredValidation,
      () => value?.digestType || ''
    ),
    id: string().default(value?.id || undefined),
    isDeleted: boolean().default(false),
    isNewDnsRecord: boolean().default(value?.isNewDnsRecord),
    keyTag: object({
      errorMessage: string().default(''),
      hasError: boolean().default(false),
      touched: boolean().default(false),
      value: number()
        .typeError(handleKeyTagValidation)
        .nullable(true)
        .default(() => value?.keyTag || undefined)
    }),
    touched: boolean().default(value?.touched)
  });

/**
 *
 * @param {*} param0
 * @returns
 */
export const formSchema = ({
  handleHexStringValidation,
  handleKeyTagValidation,
  handleRequiredValidation,
  value
}) =>
  array().of(
    recordSchema({
      handleHexStringValidation,
      handleKeyTagValidation,
      handleRequiredValidation,
      value
    })
  );

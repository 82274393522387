import { Autocomplete, TextField } from '@mui/material';
import React from 'react';

export const AutomationFilterInput = props => {
  const { applyValue, automationData, item } = props;

  const handleFilterChange = (event, value) => applyValue({ ...item, value });

  return (
    <Autocomplete
      isOptionEqualToValue={(option, value) => option.id === value.id}
      limitTags={-1}
      onChange={handleFilterChange}
      options={automationData}
      renderInput={params => (
        <TextField {...params} label="Automation" variant="standard" />
      )}
      size="small"
      sx={{ width: '100%' }}
    />
  );
};

import React from 'react';
import { useSelector } from 'react-redux';

import { TableDetailsHeader } from '../../common/components/table-details-header/TableDetailsHeader';
import { DomainDetailsInfoMulti } from './DomainDetailsInfoMulti';
import { DomainDetailsInfoSingle } from './DomainDetailsInfoSingle';
import { selectSelectedDomains } from './selected-domains-slice';
import { domainsTabs } from './tabs';

export const DomainDetailsInfo = () => {
  const selectedDomains = useSelector(selectSelectedDomains);
  return (
    <>
      {selectedDomains.length === 0 && (
        <TableDetailsHeader
          headerText={domainsTabs.INFO.label}
          noneSelectedText={domainsTabs.INFO.noneSelectedMessage}
        />
      )}
      {selectedDomains.length === 1 && <DomainDetailsInfoSingle />}
      {selectedDomains.length > 1 && <DomainDetailsInfoMulti />}
    </>
  );
};

import { LoadingButton } from '@mui/lab';
import React from 'react';
import { useSelector } from 'react-redux';

import { selectCustomerName } from '../../common/store/customers-slice';
import { selectReseller } from '../../common/store/reseller';
import { useGetRestrictionsForUserQuery } from '../../common/store/restricted-users-api-slice';
import { selectUserEmail } from '../auth/auth-slice';
import { useUpdateAutoRenewForDomainsMutation } from './auto-renew-api-slice';
import {
  selectAnyAutoRenew,
  selectAnyDoNotAutoRenew,
  selectSelectedDomains
} from './selected-domains-slice';

export const DomainDetailsRenewSetAutoRenewForDomains = () => {
  const domains = useSelector(selectSelectedDomains);
  const reseller = useSelector(selectReseller);
  const customerName = useSelector(selectCustomerName);
  const userEmail = useSelector(selectUserEmail);
  const [updateAutoRenew, { isLoading }] =
    useUpdateAutoRenewForDomainsMutation();
  const { data: restrictions, isSuccess } = useGetRestrictionsForUserQuery({
    customerName,
    reseller,
    userEmail
  });
  const { hasAutoRenewRestriction } = restrictions ?? {};
  const anyAutoRenew = useSelector(selectAnyAutoRenew);
  const anyDoNotAutoRenew = useSelector(selectAnyDoNotAutoRenew);

  const handleAutoRenewClick = ({ autoRenew }) =>
    updateAutoRenew({
      autoRenew,
      customerName,
      domains: domains.map(({ domainName }) => domainName),
      reseller
    });

  return isSuccess && domains.length > 1 ? (
    <>
      {anyDoNotAutoRenew && (
        <LoadingButton
          disabled={hasAutoRenewRestriction}
          loading={isLoading}
          onClick={() => handleAutoRenewClick({ autoRenew: true })}
          variant="outlined"
        >
          Auto renew on
        </LoadingButton>
      )}
      {anyAutoRenew && (
        <LoadingButton
          disabled={hasAutoRenewRestriction}
          loading={isLoading}
          onClick={() => handleAutoRenewClick({ autoRenew: false })}
          variant="outlined"
        >
          Auto renew off
        </LoadingButton>
      )}
    </>
  ) : null;
};

// if more than one domain is selected:
// display two buttons: one to turn auto renew on, one to turn auto renew off
//       <div ng-show="Domains.domainsActions._perm.selectedMoreThan(1)" style="padding-top: 5px;">
//   <button
//     id="domains-auto-renew-on"
//     class="button primary"
//     ng-show="Domains.domainsActions._perm.oneHasProperty('autoBilling', false)"
//     ng-disabled="G.ThisUser.hasRestriction('AUTO_RENEW')"
//     ng-click="Domains.domainsActions.setAutoRenew(true)">
//     Auto renew on
//   </button>
//   <button
//     id="domains-auto-renew-off"
//     class="button primary"
//     ng-show="Domains.domainsActions._perm.oneHasProperty('autoBilling', true)"
//     ng-disabled="G.ThisUser.hasRestriction('AUTO_RENEW')"
//     ng-click="Domains.domainsActions.setAutoRenew(false)">
//     Auto renew off
//   </button>
// </div>

import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Link,
  List,
  ListItem,
  Stack,
  Typography
} from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import {
  selectAccessToken,
  selectCanWrite
} from '../../../features/auth/auth-slice';
import { selectCustomerName } from '../../store/customers-slice';
import { useGetNotesQuery } from '../../store/notes-api-slice';
import { selectReseller } from '../../store/reseller';
import { downloadFileFromArrayBuffer } from './download-file-from-array-buffer-util';
import { NotesReminders } from './notes-reminders/NotesReminders';

export const NotesList = ({ entityType, selectedDomains }) => {
  const reseller = useSelector(selectReseller);
  const customerName = useSelector(selectCustomerName);
  const canWrite = useSelector(selectCanWrite);

  const accessToken = useSelector(selectAccessToken);
  const id = selectedDomains?.[0]?.id;

  const { data, isLoading } = useGetNotesQuery(
    {
      customerName,
      id,
      noteEntityType: entityType.value,
      reseller
    },
    {
      skip: !customerName || selectedDomains.length !== 1
    }
  );

  const handleDownloadNoteClick = ({ id }, { downloadKey, filename }) => {
    try {
      downloadFileFromArrayBuffer({
        accessToken,
        customerName,
        downloadKey,
        filename,
        noteId: id,
        reseller
      });
    } catch (error) {
      window.alert('Failed to download the file. Try again.');
    }
  };

  return (
    <Stack spacing={2}>
      {isLoading && (
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            minHeight: '200px',
            width: 'auto'
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {data?.length === 0 && (
        <Typography variant="body1">
          This {entityType.label} has no notes.
        </Typography>
      )}
      {data?.map(note => (
        <Card key={note.id} sx={{ my: 2 }} variant="outlined">
          <CardContent sx={{ overflowWrap: 'anywhere' }}>
            <Stack
              direction="row"
              sx={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <Typography variant="h6">{note.subject}</Typography>
              {canWrite && (
                <NotesReminders
                  entityType={entityType}
                  noteId={note.id}
                  selectedDomains={selectedDomains}
                />
              )}
            </Stack>
            <Typography>
              {note.$createdFormatted} | {note.author.screenName}
            </Typography>
            <Typography>{note.message}</Typography>
            {note?.noteAttachments?.length > 0 && (
              <List>
                {note?.noteAttachments?.map(noteAttachment => (
                  <ListItem disableGutters key={noteAttachment.id}>
                    <Link
                      component="button"
                      onClick={() =>
                        handleDownloadNoteClick(note, noteAttachment)
                      }
                    >
                      {noteAttachment.filename}
                    </Link>
                  </ListItem>
                ))}
              </List>
            )}
          </CardContent>
        </Card>
      ))}
    </Stack>
  );
};

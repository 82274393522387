import dayjs from 'dayjs';
import { boolean, date, object, string } from 'yup';

export const NOTES_REMINDERS_ERRORS = Object.freeze({
  emptyDate: 'Select a reminder date.',
  emptyTime: 'Select a reminder time.',
  pastDateTime: 'Reminder date and time must be in the future'
});

/**
 * @param {Function} handlePastDateError
 * @param {Function} handleEmptyDateError
 * @param {Function} handleEmptyTimeError
 * @returns
 */
export const dateTimeFormSchema = (
  handlePastDateError,
  handleEmptyDateError,
  handleEmptyTimeError
) =>
  object({
    dateField: object({
      errorMessage: string().default(''),
      isError: boolean().default(false),
      touched: boolean().oneOf([true], handleEmptyDateError).default(false)
    }),
    dateTime: object({
      errorMessage: string().default(''),
      isError: boolean().default(false),
      value: date()
        .min(new Date(), handlePastDateError)
        .default(dayjs().minute(0).second(0).millisecond(0).toString())
    }),
    timeField: object({
      errorMessage: string().default(''),
      isError: boolean().default(false),
      touched: boolean().oneOf([true], handleEmptyTimeError).default(false)
    })
  });

import { fieldSchema } from '../../common/components/name-servers/name-server-schema';

const getField = (handleNameServersValidation, value) =>
  fieldSchema(handleNameServersValidation, value).getDefault();

export const nameServerFieldsReducer = (state, action) => {
  switch (action.type) {
    case 'INIT':
      state = action.value.map(value =>
        getField(action.validationFunction(), value)
      );

      break;
    case 'COPY':
      state = [];
      state = action.value.map(value =>
        getField(action.validationFunction(), value)
      );

      break;
    case 'ADD_FIELD':
      state = state.concat(getField(action.validationFunction()));
      break;
    case 'REMOVE_FIELD':
      state = state.filter(field => field.key !== action.key);
      break;
    case 'REMOVE_ALL_FIELDS':
      state = [];
      break;
    case 'UPDATE_FIELD':
      state = state.map(field => {
        if (field.key === action.key) {
          field.value = action.value;
          field.touched = true;
          field.errorMessage = '';
          field.hasError = false;
        }
        return field;
      });

      break;
    case 'SET_ERROR':
      // Pull the array index from the path
      const erroredIndex = Number(action.path.match(/(\d+)/)[0]);
      state = state.map((field, index) => {
        if (index === erroredIndex) {
          field.errorMessage = action.errorMessage;
          field.hasError = field.touched || action.forceTouch;
        }
        return field;
      });

      break;
    case 'SET_UNIQUE_ERROR':
      state = state.map(field => {
        if (
          state.filter(otherField => field.value === otherField.value).length >
          1
        ) {
          field.errorMessage = 'Name servers must be unique.';
          field.hasError = field.touched;
        } else {
          field.errorMessage = '';
          field.hasError = false;
        }
        return field;
      });

      break;
  }

  return state;
};

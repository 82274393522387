import {
  apiSlice,
  CUSTOMER_TAG,
  DOMAIN_TAG
} from '../../common/store/api-slice';

const autoRenewApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    updateAutoRenewForDomains: builder.mutation({
      invalidatesTags: [CUSTOMER_TAG, DOMAIN_TAG],
      query: ({ autoRenew, customerName, domains, reseller }) => ({
        body: [
          {
            autoRenew,
            domains
          }
        ],
        method: 'POST',
        url: `resellers/${reseller}/customers/${customerName}/updateAutoRenewals`
      })
    }),
    updateMaxAutoRenewForDomains: builder.mutation({
      invalidatesTags: [CUSTOMER_TAG, DOMAIN_TAG],
      query: ({ autoRenew, customerName, domains, reseller }) => ({
        body: [
          {
            autoRenew,
            domains
          }
        ],
        method: 'POST',
        url: `resellers/${reseller}/customers/${customerName}/updateAutoRenewals/max`
      })
    })
  })
});

export const {
  useUpdateAutoRenewForDomainsMutation,
  useUpdateMaxAutoRenewForDomainsMutation
} = autoRenewApiSlice;

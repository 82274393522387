import { Stack, Typography } from '@mui/material';
import React from 'react';

import { POLLING_MAX_ITEMS_DISPLAYED } from '../../common/store/job-response-api-slice';

export const DomainDetailsOrderUpdateErrorMessage = ({
  failedDomainUpdates,
  maxDisplayed = POLLING_MAX_ITEMS_DISPLAYED
}) => (
  <Stack>
    <Typography sx={{ mb: 2 }} variant="body2">
      Could not update the following domains:
    </Typography>
    {failedDomainUpdates?.slice(0, maxDisplayed)?.map(failure => (
      <Typography
        key={failure.domainName}
        sx={{ fontWeight: 600, mr: 1 }}
        variant="caption"
      >
        {failure.domainName}
      </Typography>
    ))}
    {failedDomainUpdates?.length > maxDisplayed ? (
      <Typography variant="caption">
        + {failedDomainUpdates.length - maxDisplayed} more
      </Typography>
    ) : null}
  </Stack>
);

import {
  faCartShopping,
  faCircleInfo,
  faGlobe,
  faLockKeyhole,
  faNoteSticky,
  faRectangleHistory,
  faTag,
  faTrash,
  faUser
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Stack, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { TabWithTooltip } from '../../common/components/tabs/TabWithTooltip';
import { tabPanelStyles } from '../../common/components/tabs/tab-panel-styles';
import { useAppInfoQuery } from '../../common/store/app-info-api-slice';
import { selectCanWrite, selectIsSuperAdmin } from '../auth/auth-slice';
import { DomainDetailsActionHistory } from './DomainDetailsActionHistory';
import { DomainDetailsContactSets } from './DomainDetailsContactSets';
import { DomainDetailsDelete } from './DomainDetailsDelete';
import { DomainDetailsDomainLabels } from './DomainDetailsDomainLabels';
import { DomainDetailsInfo } from './DomainDetailsInfo';
import { DomainDetailsLockUnlock } from './DomainDetailsLockUnlock';
import { DomainDetailsNameServers } from './DomainDetailsNameServers';
import { DomainDetailsNotes } from './DomainDetailsNotes';
import { DomainDetailsRenew } from './DomainDetailsRenew';
import { domainsTabs } from './tabs';

// eslint-disable-next-line complexity
export const DomainsDetails = ({ archived }) => {
  const theme = useTheme();
  const [tabValue, setTabValue] = React.useState(!archived ? 'info' : 'labels');
  const handleTabChange = (event, newValue) => setTabValue(newValue);

  const isSuperAdmin = useSelector(selectIsSuperAdmin);

  const canWrite = useSelector(selectCanWrite);

  const {
    data: appInfoData = {
      deletesEnabled: false
    }
  } = useAppInfoQuery();

  const [canDelete, setCanDelete] = useState(false);

  useEffect(() => {
    if (appInfoData) {
      setCanDelete(appInfoData.deletesEnabled);
    }
  }, [appInfoData]);

  return (
    <Stack direction="row" sx={{ height: '100%' }}>
      <TabContext value={tabValue}>
        <TabList
          aria-label="Domain Details Tabs"
          onChange={handleTabChange}
          orientation="vertical"
          sx={{ flexShrink: 0, width: '90px' }}
        >
          {!archived && (
            <TabWithTooltip
              aria-label={domainsTabs.INFO.label}
              icon={<FontAwesomeIcon icon={faCircleInfo} />}
              title={domainsTabs.INFO.label}
              value={domainsTabs.INFO.value}
            />
          )}
          {!archived && (
            <TabWithTooltip
              aria-label={domainsTabs.RENEW.label}
              hide={!canWrite}
              icon={<FontAwesomeIcon icon={faCartShopping} />}
              title={domainsTabs.RENEW.label}
              value={domainsTabs.RENEW.value}
            />
          )}
          {!archived && (
            <TabWithTooltip
              aria-label={domainsTabs.LOCK_UNLOCK.label}
              hide={!canWrite}
              icon={<FontAwesomeIcon icon={faLockKeyhole} />}
              title={domainsTabs.LOCK_UNLOCK.label}
              value={domainsTabs.LOCK_UNLOCK.value}
            />
          )}
          {!archived && (
            <TabWithTooltip
              aria-label={domainsTabs.NAME_SERVERS.label}
              icon={<FontAwesomeIcon icon={faGlobe} />}
              title={domainsTabs.NAME_SERVERS.label}
              value={domainsTabs.NAME_SERVERS.value}
            />
          )}
          {!archived && (
            <TabWithTooltip
              aria-label={domainsTabs.CONTACT_SETS.label}
              icon={<FontAwesomeIcon icon={faUser} />}
              title={domainsTabs.CONTACT_SETS.label}
              value={domainsTabs.CONTACT_SETS.value}
            />
          )}
          <TabWithTooltip
            aria-label={domainsTabs.LABELS.label}
            hide={!canWrite}
            icon={<FontAwesomeIcon icon={faTag} />}
            title={domainsTabs.LABELS.label}
            value={domainsTabs.LABELS.value}
          />
          <TabWithTooltip
            aria-label={domainsTabs.NOTES.label}
            icon={<FontAwesomeIcon icon={faNoteSticky} />}
            title={domainsTabs.NOTES.label}
            value={domainsTabs.NOTES.value}
          />
          <TabWithTooltip
            aria-label={domainsTabs.ACTION_HISTORY.label}
            icon={<FontAwesomeIcon icon={faRectangleHistory} />}
            title={domainsTabs.ACTION_HISTORY.label}
            value={domainsTabs.ACTION_HISTORY.value}
          />
          {!archived && (
            <TabWithTooltip
              aria-label={domainsTabs.DELETE.label}
              hide={!isSuperAdmin || !canDelete}
              icon={<FontAwesomeIcon icon={faTrash} />}
              title={domainsTabs.DELETE.label}
              value={domainsTabs.DELETE.value}
            />
          )}
        </TabList>
        {tabValue === domainsTabs.INFO.value && (
          <TabPanel
            sx={tabPanelStyles({ theme })}
            value={domainsTabs.INFO.value}
          >
            <DomainDetailsInfo />
          </TabPanel>
        )}
        {tabValue === domainsTabs.RENEW.value && (
          <TabPanel
            sx={tabPanelStyles({ theme })}
            value={domainsTabs.RENEW.value}
          >
            <DomainDetailsRenew />
          </TabPanel>
        )}
        {tabValue === domainsTabs.LOCK_UNLOCK.value && (
          <TabPanel
            sx={tabPanelStyles({ theme })}
            value={domainsTabs.LOCK_UNLOCK.value}
          >
            <DomainDetailsLockUnlock />
          </TabPanel>
        )}
        {tabValue === domainsTabs.NAME_SERVERS.value && (
          <TabPanel
            sx={tabPanelStyles({ theme })}
            value={domainsTabs.NAME_SERVERS.value}
          >
            <DomainDetailsNameServers />
          </TabPanel>
        )}
        {tabValue === domainsTabs.CONTACT_SETS.value && (
          <TabPanel
            sx={tabPanelStyles({ theme })}
            value={domainsTabs.CONTACT_SETS.value}
          >
            <DomainDetailsContactSets />
          </TabPanel>
        )}
        {tabValue === domainsTabs.LABELS.value && (
          <TabPanel
            sx={tabPanelStyles({ theme })}
            value={domainsTabs.LABELS.value}
          >
            <DomainDetailsDomainLabels />
          </TabPanel>
        )}
        {tabValue === domainsTabs.NOTES.value && (
          <TabPanel
            sx={tabPanelStyles({ theme })}
            value={domainsTabs.NOTES.value}
          >
            <DomainDetailsNotes />
          </TabPanel>
        )}
        {tabValue === domainsTabs.ACTION_HISTORY.value && (
          <TabPanel
            sx={tabPanelStyles({ p: 0, theme })}
            value={domainsTabs.ACTION_HISTORY.value}
          >
            <DomainDetailsActionHistory />
          </TabPanel>
        )}
        {tabValue === domainsTabs.DELETE.value && (
          <TabPanel
            sx={tabPanelStyles({ theme })}
            value={domainsTabs.DELETE.value}
          >
            <DomainDetailsDelete />
          </TabPanel>
        )}
      </TabContext>
    </Stack>
  );
};

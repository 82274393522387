import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectCustomerName } from '../../common/store/customers-slice';
import { useUpdateDomainsMutation } from '../../common/store/domains-api-slice';
import { selectReseller } from '../../common/store/reseller';
import { selectIsSuperAdmin } from '../auth/auth-slice';
import {
  selectAreAllDomainsManual,
  selectHaveSameExpirationDate,
  selectSelectedDomains
} from './selected-domains-slice';

export const DomainDetailsRenewSetExpirationDate = () => {
  const [expirationDate, setExpirationDate] = useState();
  const isSuperAdmin = useSelector(selectIsSuperAdmin);

  const haveSameExpirationDate = useSelector(selectHaveSameExpirationDate);
  const areAllManual = useSelector(selectAreAllDomainsManual);

  const selectedDomains = useSelector(selectSelectedDomains);
  const customerName = useSelector(selectCustomerName);
  const reseller = useSelector(selectReseller);
  // TODO handle errors
  const [updateDomains] = useUpdateDomainsMutation();

  useEffect(() => {
    setExpirationDate(
      haveSameExpirationDate ? dayjs(selectedDomains[0].expirationDate) : null
    );
  }, [haveSameExpirationDate, selectedDomains]);

  const handleExpirationDateChange = newValue => {
    setExpirationDate(newValue);

    updateDomains({
      body: selectedDomains.map(({ domainName }) => ({
        domainName,
        expirationDate: newValue.toISOString()
      })),
      customerName,
      reseller
    });
  };

  return (
    <>
      {areAllManual && isSuperAdmin && (
        <DatePicker
          label="Expiration Date"
          onChange={handleExpirationDateChange}
          renderInput={params => <TextField {...params} />}
          value={expirationDate}
        />
      )}
    </>
  );
};

// if all are manual renew AND it's a super admin:
// then display a date picker to set the expiration date
//   <div ng-hide="!Domains.domainsActions._perm.allManual()
//   || !G.Auth.isSuperAdmin()">
//     <span class="date-input-single future-date">
//     <button type="button" class="button primary" ng-click="Domains.calendarOpen = true">
//       <i class="fas fa-calendar"></i>
//     </button>
//     <input type="text"
//             uib-datepicker-popup="MM-dd-yyyy"
//             is-open="Domains.calendarOpen"
//             ng-model="Domains.expirationDate"
//             ng-disabled="!Domains.domainsActions._perm.allManual() || !G.Auth.isSuperAdmin()"
//             show-button-bar="false"
//             show-weeks="false"
//             ng-change="Domains.domainsActions.updateExpirationDate(Domains.expirationDate)"/>
//     </span>
//   </div>
//   <br/>
// </div>

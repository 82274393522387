import { faCopy } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, IconButton } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import React from 'react';

export const CLIPBOARD_BUTTON_TYPES = Object.freeze({
  button: 'button',
  iconButton: 'iconButton'
});

export const CopyToClipboard = ({
  buttonLabel = 'Copy to clipboard',
  buttonType = CLIPBOARD_BUTTON_TYPES.button,
  textToCopy,
  ...rest
}) => {
  const copy = () => {
    if (!window.isSecureContext) {
      window.console.warn('Copy to clipboard only works in a secure context.');
    } else {
      navigator.clipboard.writeText(textToCopy);

      enqueueSnackbar('Copied to clipboard', {
        preventDuplicate: true,
        variant: 'success'
      });
    }
  };

  switch (buttonType) {
    case CLIPBOARD_BUTTON_TYPES.button:
      return (
        <Button
          data-testid="button"
          onClick={copy}
          size="small"
          startIcon={<FontAwesomeIcon data-testid="faCopy" icon={faCopy} />}
          {...rest}
        >
          {buttonLabel}
        </Button>
      );
    case CLIPBOARD_BUTTON_TYPES.iconButton:
      return (
        <IconButton
          aria-label={buttonLabel}
          data-testid="iconButton"
          onClick={copy}
          size="small"
          {...rest}
        >
          <FontAwesomeIcon data-testid="faCopy" icon={faCopy} />
        </IconButton>
      );
  }
};

import { Box, Typography } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { MultipleDomainsAsChips } from '../../common/components/multiple-domains-as-chips/MultipleDomainsAsChips';
import { SyncDomains } from './SyncDomains';
import {
  removeSelectedDomain,
  selectSelectedDomains
} from './selected-domains-slice';

export const DomainDetailsInfoMulti = () => {
  const dispatch = useDispatch();
  const selectedDomains = useSelector(selectSelectedDomains);

  const handleDeselectDomain = $id => dispatch(removeSelectedDomain($id));

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Typography variant="h6">Multiple Domains</Typography>
        <MultipleDomainsAsChips
          domains={selectedDomains}
          onDeselect={handleDeselectDomain}
        />
      </Box>
      <SyncDomains />
    </>
  );
};

import { faPlus, faTrashCan } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Button,
  Divider,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CopyToClipboard } from '../../common/components/copy-to-clipboard/CopyToClipboard';
import { SnackbarCloseAction } from '../../common/components/snackbar-close-action/SnackbarCloseAction';
import {
  CUSTOMER_TAG,
  DNSSEC_TAG,
  apiSlice
} from '../../common/store/api-slice';
import { selectCustomerName } from '../../common/store/customers-slice';
import { useGetDnssecRecordsQuery } from '../../common/store/domains-api-slice';
import {
  LINE_ITEM_STATUSES,
  POLLING_INTERVAL,
  POLLING_MAX_REQUESTS,
  usePollJobResponseQuery
} from '../../common/store/job-response-api-slice';
import { useCreateOrderMutation } from '../../common/store/orders-api-slice';
import { selectReseller } from '../../common/store/reseller';
import { dnssecOptions } from '../../common/utils/dnssec-options';
import { generateId } from '../../common/utils/generate-id';
import { selectCanWrite, selectClientId } from '../auth/auth-slice';
import styles from './DomainDetailsDnssec.module.css';
import { DomainDetailsOrderUpdateErrorMessage } from './DomainDetailsOrderUpdateErrorMessage';
import { DomainDetailsOrderUpdatePendingMessage } from './DomainDetailsOrderUpdatePendingMessage';
import { dnsRecordsReducer } from './dns-records-reducer';
import { formSchema } from './dns-records-schema';
import { selectSelectedDomains } from './selected-domains-slice';

export const DnsRecords = () => {
  const dispatch = useDispatch();
  const reseller = useSelector(selectReseller);
  const clientId = useSelector(selectClientId);
  const canWrite = useSelector(selectCanWrite);
  const [lineItemsToUpdate, setLineItemsToUpdate] = useState([]);
  const customerName = useSelector(selectCustomerName);
  const [validationErrors, setValidationErrors] = useState(false);
  const selectedDomains = useSelector(selectSelectedDomains);
  const [refetchCount, setRefetchCount] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const [createOrder, { isError: isCreateOrderError, isLoading }] =
    useCreateOrderMutation();

  const isLoadingOrFetching = isLoading || lineItemsToUpdate?.length > 0;

  const { data: jobResultData, refetch } = usePollJobResponseQuery(
    {
      clientId,
      clientLineItemIds: lineItemsToUpdate.map(
        lineItem => lineItem.clientLineItemId
      )
    },
    { skip: !clientId || !lineItemsToUpdate.length }
  );

  const handleKeyTagValidation = ({ path }) =>
    // @ts-ignore
    formDataDispatch({
      errorMessage: 'Key tag must be number',
      path,
      type: 'SET_ERROR'
    });

  const handleHexStringValidation = params => {
    // @ts-ignore
    formDataDispatch({
      errorMessage: 'Digest must be a hexadecimal value',
      forceTouch: false,
      path: params.path,
      type: 'SET_ERROR'
    });
  };

  const handleRequiredValidation = ({ path }) =>
    // @ts-ignore
    formDataDispatch({
      errorMessage: 'This field is required',
      path,
      type: 'SET_ERROR'
    });

  const { data: dnssecRecords, isSuccess: isGetDnssecSuccess } =
    useGetDnssecRecordsQuery(
      {
        customerName,
        domainName: selectedDomains[0].domainName,
        reseller
      },
      { skip: !customerName }
    );

  const [formData, formDataDispatch] = useReducer(dnsRecordsReducer, []);

  useEffect(() => {
    if (isGetDnssecSuccess && dnssecRecords?.length) {
      // @ts-ignore
      formDataDispatch({
        handleHexStringValidation,
        handleKeyTagValidation,
        handleRequiredValidation,
        type: 'INIT',
        value: dnssecRecords
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dnssecRecords, isGetDnssecSuccess]);

  const handleChange = (event, id, field) => {
    setValidationErrors(false);
    // @ts-ignore
    formDataDispatch({
      field,
      id,
      type: 'UPDATE',
      value: event.target.value
    });
  };
  const handleAddMore = () =>
    // @ts-ignore
    formDataDispatch({
      type: 'ADD_FIELD'
    });

  // @ts-ignore
  const handleRemove = id => formDataDispatch({ id, type: 'REMOVE_FIELD' });

  const getNewDnsRecords = () =>
    formData
      .filter(dnsRecord => dnsRecord.isNewDnsRecord)
      .map(({ algorithmType, digest, digestType, keyTag }) => ({
        added: true,
        algorithmType: algorithmType.value,
        digest: digest.value,
        digestType: digestType.value,
        keyTag: keyTag.value
      }));

  const getDeletedDnsRecords = () =>
    formData
      .filter(dnsRecord => !dnsRecord.isNewDnsRecord && dnsRecord.isDeleted)
      .map(({ algorithmType, digest, digestType, id, keyTag }) => ({
        added: false,
        algorithmType: algorithmType.value,
        digest: digest.value,
        digestType: digestType.value,
        id,
        keyTag: keyTag.value
      }));

  const handleSubmit = event => {
    event.preventDefault();
    // @ts-ignore
    formSchema({
      handleHexStringValidation,
      handleKeyTagValidation,
      handleRequiredValidation
    })
      .validate(formData)
      .then(() => {
        setValidationErrors(false);
      })
      .then(() => {
        const lineItems = selectedDomains.map((domain, index) => ({
          clientLineItemId: generateId(),
          domain: domain.domainName,
          domainDnssec: [...getNewDnsRecords(), ...getDeletedDnsRecords()],
          lineItemNum: index,
          lineItemType: 'DOMAIN_DNSSEC'
        }));
        const orderOptions = {
          currency: 'USD',
          paymentType: 'NONE'
        };
        setLineItemsToUpdate(lineItems);
        createOrder({
          clientId,
          customerName,
          lineItems,
          orderOptions,
          reseller
        });
      })
      .catch(e => {
        window.console.error(e);
        setValidationErrors(true);
      });
  };

  const failedDomainUpdates = jobResultData?.messages
    .filter(message => message.status === LINE_ITEM_STATUSES.failure)
    .map(message => ({
      ...message,
      domainName: lineItemsToUpdate.find(
        lineItem => lineItem.clientLineItemId === message.clientLineItemId
      )?.domain
    }));

  const pendingDomainUpdates = jobResultData?.messages
    .filter(message => message.status === LINE_ITEM_STATUSES.pending)
    .map(message => ({
      ...message,
      domainName: lineItemsToUpdate.find(
        lineItem => lineItem.clientLineItemId === message.clientLineItemId
      )?.domain
    }));

  const haveUpdatesThatHaveBeenPolled =
    lineItemsToUpdate.length &&
    jobResultData?.messages.some(
      message =>
        message.clientLineItemId === lineItemsToUpdate[0].clientLineItemId
    );

  // Handles job polling
  useEffect(() => {
    if (jobResultData?.anyPending && refetchCount < POLLING_MAX_REQUESTS) {
      setTimeout(refetch, POLLING_INTERVAL.active);
      setRefetchCount(refetchCount + 1);
    } else if (refetchCount >= POLLING_MAX_REQUESTS) {
      enqueueSnackbar(
        <Stack spacing={1}>
          <DomainDetailsOrderUpdatePendingMessage
            pendingDomainUpdates={pendingDomainUpdates}
          />
          {failedDomainUpdates?.length > 0 && (
            <DomainDetailsOrderUpdateErrorMessage
              failedDomainUpdates={failedDomainUpdates}
            />
          )}
        </Stack>,
        {
          action: snackbarId => (
            <Stack direction="horizontal" spacing={1}>
              <CopyToClipboard
                sx={{
                  borderColor: '#fff !important',
                  color: '#fff !important'
                }}
                textToCopy={failedDomainUpdates
                  .concat(pendingDomainUpdates)
                  .map(({ domainName }) => domainName)
                  .toString()}
                variant="outlined"
              />
              <SnackbarCloseAction snackbarId={snackbarId} />
            </Stack>
          ),
          persist: true,
          variant: 'warning'
        }
      );
      setLineItemsToUpdate([]);
      dispatch(apiSlice.util.invalidateTags([CUSTOMER_TAG, DNSSEC_TAG]));
      setRefetchCount(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobResultData, lineItemsToUpdate]);

  // Handles snackbar after polling
  useEffect(() => {
    if (jobResultData?.allComplete && haveUpdatesThatHaveBeenPolled) {
      if (jobResultData.anyFailures) {
        enqueueSnackbar(
          <DomainDetailsOrderUpdateErrorMessage
            failedDomainUpdates={failedDomainUpdates}
          />,
          {
            action: snackbarId => (
              <Stack direction="horizontal" spacing={1}>
                <CopyToClipboard
                  sx={{
                    borderColor: '#fff !important',
                    color: '#fff !important'
                  }}
                  textToCopy={failedDomainUpdates
                    .map(({ domainName }) => domainName)
                    .toString()}
                  variant="outlined"
                />
                <SnackbarCloseAction snackbarId={snackbarId} />
              </Stack>
            ),
            persist: true,
            variant: 'error'
          }
        );
      } else {
        enqueueSnackbar('Successfully updated the selected domain(s).', {
          variant: 'success'
        });
      }
      setLineItemsToUpdate([]);
      dispatch(apiSlice.util.invalidateTags([CUSTOMER_TAG, DNSSEC_TAG]));
      setRefetchCount(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lineItemsToUpdate, jobResultData]);

  //Create Order request fails
  useEffect(() => {
    if (isCreateOrderError) {
      enqueueSnackbar(
        'Encountered an error trying to submit name server changes.',
        {
          variant: 'error'
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreateOrderError]);

  const hasEntriesToDisplay = formData?.some(entry => !entry.isDeleted);
  return (
    <>
      <Divider sx={{ my: 2 }} />
      <Typography>DNSSEC Entries</Typography>
      <form noValidate={true} onSubmit={handleSubmit}>
        <Stack>
          {validationErrors && (
            <Alert severity="error" sx={{ maxWidth: '280px', mt: 1 }}>
              Fix form errors before submitting.
            </Alert>
          )}
          {!hasEntriesToDisplay && (
            <Typography sx={{ mt: 1 }} variant="body2">
              No DNSSEC entries configured.
            </Typography>
          )}
          {formData?.map(
            (
              {
                algorithmType,
                digest,
                digestType,
                id,
                isDeleted,
                isNewDnsRecord,
                keyTag
              },
              index
            ) => (
              <Stack
                className={isDeleted && styles.recordHidden}
                key={index}
                spacing={2}
                sx={{ mb: 1.5, position: 'relative' }}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  sx={{ mt: 1.5 }}
                >
                  <TextField
                    disabled={!isNewDnsRecord}
                    error={keyTag?.hasError}
                    helperText={keyTag?.errorMessage}
                    label="Key Tag"
                    onChange={event => handleChange(event, id, 'keyTag')}
                    size="small"
                    sx={{ width: '40%' }}
                    value={keyTag?.value ?? ''}
                  />
                </Stack>
                <Stack direction="row" spacing={1}>
                  <TextField
                    disabled={!isNewDnsRecord}
                    error={algorithmType?.hasError}
                    helperText={algorithmType?.errorMessage}
                    label="Algorithm Type"
                    onChange={event => handleChange(event, id, 'algorithmType')}
                    select
                    size="small"
                    sx={{ width: '50%' }}
                    value={algorithmType?.value ?? ''}
                  >
                    {dnssecOptions.algorithmType.map(item => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    disabled={!isNewDnsRecord}
                    error={digestType?.hasError}
                    helperText={digestType?.errorMessage}
                    label="Digest Type"
                    onChange={event => handleChange(event, id, 'digestType')}
                    select
                    size="small"
                    sx={{ width: '50%' }}
                    value={digestType?.value ?? ''}
                  >
                    {dnssecOptions.digestType.map(item => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </TextField>
                </Stack>
                <TextField
                  disabled={!isNewDnsRecord}
                  error={digest?.hasError}
                  helperText={digest?.errorMessage}
                  label="Digest"
                  onChange={event => handleChange(event, id, 'digest')}
                  value={digest?.value ?? null}
                />
                {canWrite && (
                  <IconButton
                    aria-label="Remove"
                    disabled={isLoadingOrFetching}
                    onClick={() => handleRemove(id)}
                    size="small"
                    sx={{ position: 'absolute', right: 0 }}
                  >
                    <FontAwesomeIcon icon={faTrashCan} />
                  </IconButton>
                )}
                <Divider />
              </Stack>
            )
          )}
        </Stack>
        {canWrite && (
          <Stack
            direction={{ lg: 'row', xs: 'column' }}
            sx={{
              alignItems: 'flex-start',
              display: 'flex',
              flexWrap: 'wrap',
              gap: 2,
              paddingTop: 1.5,
              width: '100%'
            }}
          >
            <Button
              color="secondary"
              disabled={isLoadingOrFetching}
              onClick={handleAddMore}
              startIcon={<FontAwesomeIcon icon={faPlus} />}
              variant="contained"
            >
              Add more
            </Button>
            <LoadingButton
              disabled={!formData.some(dnsRecord => dnsRecord.touched)}
              loading={isLoadingOrFetching}
              sx={{ ml: 0, width: 134 }}
              type="submit"
              variant="contained"
            >
              Save
            </LoadingButton>
          </Stack>
        )}
      </form>
    </>
  );
};

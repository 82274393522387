import { Link, Typography } from '@mui/material';
import React from 'react';

import { useAppInfoQuery } from '../../store/app-info-api-slice';

export const DomainServiceAgreementLabel = () => {
  const { data: appInfoData } = useAppInfoQuery();

  return (
    <Typography variant="body2">
      {appInfoData?.$domainServiceAgreementParts?.prefix}
      <Link
        href={appInfoData?.$domainServiceAgreementParts?.url}
        rel="noopener"
        target="_blank"
      >
        {appInfoData?.$domainServiceAgreementParts?.linkText}
      </Link>
      {appInfoData?.$domainServiceAgreementParts?.suffix}
    </Typography>
  );
};

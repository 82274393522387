import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import dayjs from 'dayjs';
import { bindDialog, bindToggle } from 'material-ui-popup-state';
import React, { useEffect, useState } from 'react';

import { DomainServiceAgreementForm } from '../../common/components/domain-service-agreement/DomainServiceAgreementForm';
import { LocalPresenceConfirmButton } from './LocalPresenceConfirmButton';

export const LocalPresenceConfirm = ({ popupState }) => {
  const domainAgreementInitialState = Object.freeze({
    accepted: false,
    date: null
  });
  const [domainAgreement, setDomainAgreement] = useState(
    domainAgreementInitialState
  );
  const handleDomainAgreementCheckboxClick = ({ target }) =>
    setDomainAgreement({
      accepted: target.checked,
      date: dayjs().toISOString()
    });

  useEffect(() => {
    // Reset domain agreement on dialog close
    if (popupState.isOpen === false) {
      setDomainAgreement(domainAgreementInitialState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popupState.isOpen]);

  return (
    <Dialog {...bindDialog(popupState)}>
      <DialogTitle>
        Are you sure you want to purchase local presence?
      </DialogTitle>
      <DialogContent>
        <DomainServiceAgreementForm
          checkboxComponent={
            <Checkbox
              checked={domainAgreement.accepted}
              onChange={handleDomainAgreementCheckboxClick}
            />
          }
        />
      </DialogContent>
      <DialogActions>
        <Button {...bindToggle(popupState)}>Cancel</Button>
        <LocalPresenceConfirmButton
          domainAgreement={domainAgreement}
          popupState={popupState}
        />
      </DialogActions>
    </Dialog>
  );
};

import React from 'react';
import { useSelector } from 'react-redux';

import { Notes } from '../../common/components/notes/Notes';
import { noteEntityType } from '../../common/components/notes/note-entity-type';
import { selectSelectedDomains } from './selected-domains-slice';
import { domainsTabs } from './tabs';

export const DomainDetailsNotes = () => {
  const selectedDomains = useSelector(selectSelectedDomains);

  return (
    <Notes
      detailsTab={domainsTabs.NOTES}
      entityType={noteEntityType.domain}
      selectedDomains={selectedDomains}
    />
  );
};

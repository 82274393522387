import {
  Backdrop,
  Button,
  CircularProgress,
  Stack,
  useTheme
} from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SignOffDialog } from '../../common/components/sign-offs/SignOffDialog';
import { SnackbarCloseAction } from '../../common/components/snackbar-close-action/SnackbarCloseAction';
import { CUSTOMER_TAG, apiSlice } from '../../common/store/api-slice';
import { selectCustomerName } from '../../common/store/customers-slice';
import {
  LINE_ITEM_STATUSES,
  POLLING_INTERVAL,
  POLLING_MAX_REQUESTS,
  usePollJobResponseQuery
} from '../../common/store/job-response-api-slice';
import { useCreateOrderMutation } from '../../common/store/orders-api-slice';
import { selectReseller } from '../../common/store/reseller';
import { useGetRestrictionsForUserQuery } from '../../common/store/restricted-users-api-slice';
import { generateId } from '../../common/utils/generate-id';
import { selectClientId, selectUserEmail } from '../auth/auth-slice';
import { defineJobDomainStatus, defineJobStatus } from './registrar-locks';
import {
  selectAreSomeDomainsManual,
  selectSelectedDomains
} from './selected-domains-slice';

export const LockUnlockAll = ({ position }) => {
  const selectedDomains = useSelector(selectSelectedDomains);
  const numberSelectedDomains = selectedDomains.length;
  const areSomeDomainsManual = useSelector(selectAreSomeDomainsManual);
  const userEmail = useSelector(selectUserEmail);

  const theme = useTheme();
  const dispatch = useDispatch();

  const [isBackdropOpen, setIsBackdropOpen] = useState(false);
  const [fetchCount, setFetchCount] = useState(0);
  const [isSignOffDialogOpen, setIsSignOffDialogOpen] = useState(false);

  const clientId = useSelector(selectClientId);
  const reseller = useSelector(selectReseller);
  const customerName = useSelector(selectCustomerName);

  const { data: restrictions, isFetching: isRestrictionsDataFetching } =
    useGetRestrictionsForUserQuery({
      customerName,
      reseller,
      userEmail
    });
  const { hasTransferLockRestriction } = restrictions ?? {};

  const isDisabledDueToRestrictions =
    isRestrictionsDataFetching || hasTransferLockRestriction;

  const [createOrder, { data: createOrderData }] = useCreateOrderMutation();

  const lineItems = useRef([]);
  const operation = useRef({ type: '', value: false });
  const clientLineItemIds = useRef([]);
  const jobStatus = useRef({
    failureCount: 0,
    pendingCount: 0,
    successCount: 0,
    total: 0
  });
  const jobDomainStatus = useRef([]);

  const shouldSkipPolling =
    createOrderData?.signOffRequired !== false ||
    Object.keys(lineItems.current).length === 0;

  const getLineItems = () => {
    const getDomainStatuses = domain => {
      const { deleteLockSupport, transferLockSupport, updateLockSupport } =
        domain.$tld;

      const domainStatuses = {};
      const value = operation.current.value;

      if (updateLockSupport) {
        domainStatuses.clientUpdateProhibited = value;
      }
      if (transferLockSupport) {
        domainStatuses.clientTransferProhibited = value;
      }
      if (deleteLockSupport) {
        domainStatuses.clientDeleteProhibited = value;
      }

      return domainStatuses;
    };

    const anyLockAvailableSelectedDomains = selectedDomains.filter(
      domain => domain.$registrarLocks.anyAvailable
    );

    return anyLockAvailableSelectedDomains.map((domain, index) => ({
      clientLineItemId: generateId(),
      domain: domain.domainName,
      domainStatuses: getDomainStatuses(domain),
      lineItemNum: index,
      lineItemType: 'UPDATE_DOMAIN_STATUSES'
    }));
  };

  const sendOrder = () => {
    setFetchCount(0);
    lineItems.current = getLineItems();

    clientLineItemIds.current = lineItems.current.map(
      lineItem => lineItem.clientLineItemId
    );

    createOrder({
      clientId,
      customerName,
      lineItems: lineItems.current,
      orderOptions: { paymentType: 'NONE' },
      reseller
    });

    setIsBackdropOpen(true);
  };

  const handleLockAll = () => {
    operation.current = { type: 'lock all', value: true };
    sendOrder();
  };

  const handleUnlockAll = () => {
    operation.current = { type: 'unlock all', value: false };
    sendOrder();
  };

  const clearLineItems = () => {
    lineItems.current = [];
    clientLineItemIds.current = [];
  };

  const { data: jobResultData, refetch } = usePollJobResponseQuery(
    { clientId, clientLineItemIds: clientLineItemIds.current },
    { skip: shouldSkipPolling }
  );

  const handleCloseSignOffDialog = () => {
    setIsSignOffDialogOpen(false);
    clearLineItems();
  };

  const showSnackbar = ({ persist = false, snackbarContent, variant }) => {
    enqueueSnackbar(snackbarContent, {
      action: snackbarId =>
        persist && <SnackbarCloseAction snackbarId={snackbarId} />,
      persist,
      variant
    });
  };

  useEffect(() => {
    // sign off required - no sign off pending
    if (
      createOrderData?.signOffRequired &&
      !createOrderData?.forceUpdateRequired
    ) {
      showSnackbar({
        snackbarContent: 'Your request is now pending Sign-Off',
        variant: 'info'
      });
      clearLineItems();
      setIsBackdropOpen(false);
    }
    // sign off required - sign off pending
    else if (
      createOrderData?.signOffRequired &&
      createOrderData?.forceUpdateRequired
    ) {
      setIsSignOffDialogOpen(true);
      setIsBackdropOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createOrderData]);

  const handleForceUpdate = () => {
    createOrder({
      clientId,
      customerName,
      lineItems: lineItems.current,
      orderOptions: { paymentType: 'NONE' },
      reseller,
      signOffForceUpdate: true
    });
    setIsBackdropOpen(true);
    setIsSignOffDialogOpen(false);
    clearLineItems();
  };

  useEffect(() => {
    if (jobResultData?.anyPending && fetchCount < POLLING_MAX_REQUESTS) {
      setFetchCount(prevCount => prevCount + 1);
      setTimeout(refetch, POLLING_INTERVAL.active);
    } else {
      const messageObject = jobResultData?.messages.filter(message =>
        clientLineItemIds.current.includes(message.clientLineItemId)
      );

      const isMessageObjectDetermined = messageObject?.length > 0;
      if (isMessageObjectDetermined) {
        jobStatus.current = defineJobStatus(messageObject);
        jobDomainStatus.current = defineJobDomainStatus(
          messageObject,
          lineItems.current
        );
        setIsBackdropOpen(false);
        showSnackbar(getJobSnackbarProperties());
      }
      if (isMessageObjectDetermined && jobStatus.current.successCount > 0) {
        dispatch(apiSlice.util.invalidateTags([CUSTOMER_TAG]));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobResultData]);

  const renderBackdrop = () => {
    return (
      <Backdrop
        open={isBackdropOpen}
        sx={{
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          bottom: 0,
          color: theme.palette.primary.main,
          left: 0,
          position: 'absolute',
          right: 0,
          top: 0,
          zIndex: theme => theme.zIndex.drawer + 1
        }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  const getJobSnackbarProperties = () => {
    const jobStatuses = jobStatus?.current;
    const isAllSuccess = jobStatuses.successCount === jobStatuses.total;
    const isAllFailure = jobStatuses.failureCount === jobStatuses.total;
    const anyPending = jobStatuses.pendingCount > 0;

    const displaySuccessMessage = () => {
      return areSomeDomainsManual
        ? `A request to ${operation.current.type} statuses has been submitted. This change requires a manual process. Your account manager has been notified.`
        : `Successfully ${operation.current.value ? 'locked' : 'unlocked'} ${
            jobStatuses.total > 1
              ? 'all selected domains'
              : 'the selected domain'
          }`;
    };

    const displayMixedCaseMessage = () => {
      if (anyPending) {
        return `Of the ${numberSelectedDomains} selected domains, ${
          operation.current.value ? 'enabling' : 'disabling'
        } locks succeeded for ${jobStatuses.successCount} and failed for ${
          numberSelectedDomains - jobStatuses.successCount
        }. ${jobStatuses.pendingCount} domains are pending response.`;
      } else if (jobStatuses.failureCount <= 5) {
        const failedDomainNames = jobDomainStatus.current
          .filter(job => job.status === LINE_ITEM_STATUSES.failure)
          .map(failedJob => failedJob.domain);
        return `Of the ${numberSelectedDomains} selected domains, ${
          operation.current.value ? 'enabling' : 'disabling'
        } locks failed for ${
          jobStatuses.failureCount > 1
            ? `these ${jobStatuses.failureCount}`
            : 'this one'
        } : ${failedDomainNames.join(',\n')}`;
      } else if (jobStatuses.successCount <= 5) {
        const succeededDomainNames = jobDomainStatus.current
          .filter(job => job.status === LINE_ITEM_STATUSES.success)
          .map(suceededJob => suceededJob.domain);
        return `Of the ${numberSelectedDomains} selected domains, ${
          operation.current.value ? 'enabling' : 'disabling'
        } locks succeeded for ${
          jobStatuses.successCount > 1
            ? `these ${jobStatuses.successCount}`
            : 'this one'
        } : ${succeededDomainNames.join(',\n')}`;
      } else {
        return `Of the ${numberSelectedDomains} selected domains, ${
          operation.current.value ? 'enabling' : 'disabling'
        } locks succeeded for ${jobStatuses.successCount} and failed for ${
          numberSelectedDomains - jobStatuses.successCount
        }.`;
      }
    };

    const displayMessage = () => {
      if (isAllSuccess) {
        return displaySuccessMessage();
      } else if (isAllFailure) {
        return `An error was encountered while ${
          operation.current.value ? 'enabling' : 'disabling'
        } the locks for the selected ${
          jobStatuses.total > 1 ? 'domains' : 'domain'
        }`;
      } else {
        return displayMixedCaseMessage();
      }
    };

    return {
      // in case of mixed success / failure message, the snackbar should be persisted.
      persist: !(isAllSuccess || isAllFailure),
      snackbarContent: displayMessage(),
      // eslint-disable-next-line no-nested-ternary
      variant: isAllSuccess ? 'success' : isAllFailure ? 'error' : 'warning'
    };
  };

  return (
    <Stack
      direction={{ lg: 'row', xs: 'column' }}
      spacing={{ lg: 2, xs: 1 }}
      sx={{
        alignItems: position === 'leftRow' ? 'flex-start' : 'center',
        display: 'flex',
        paddingLeft: position === 'leftRow' ? 2 : { lg: 2 },
        paddingTop: 2,
        width: '100%'
      }}
    >
      <Button
        disabled={
          isDisabledDueToRestrictions
            ? true
            : selectedDomains.some(domain => domain.registryLocked === true)
        }
        onClick={handleLockAll}
        sx={{ width: { xl: 150, xs: 130 } }}
        variant="contained"
      >
        Lock all
      </Button>
      <Button
        disabled={
          isDisabledDueToRestrictions
            ? true
            : selectedDomains.some(domain => domain.registryLocked === true)
        }
        onClick={handleUnlockAll}
        sx={{ width: { xl: 150, xs: 130 } }}
        variant="contained"
      >
        Unlock all
      </Button>
      {renderBackdrop()}
      <SignOffDialog
        handleClose={handleCloseSignOffDialog}
        handleSubmit={handleForceUpdate}
        isOpen={isSignOffDialogOpen}
      />
    </Stack>
  );
};

import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '../../common/store/store';
import { ManualOrderEntryApiResponse } from './manual-orders';

type ManualOrdersSliceType = {
  isSubmittingManualOrders: boolean;
  selectedManualOrders: ManualOrderEntryApiResponse[] | null;
};

const initialState: ManualOrdersSliceType = {
  isSubmittingManualOrders: false,
  selectedManualOrders: null
};

const manualOrdersSlice = createSlice({
  initialState: initialState,
  name: 'manualOrders',
  reducers: {
    setIsSubmittingManualOrders: (state, { payload }) => {
      state.isSubmittingManualOrders = payload;
    },
    setSelectedManualOrders: (state, { payload }) => {
      state.selectedManualOrders = payload;
    }
  }
});

export const manualOrdersReducer = manualOrdersSlice.reducer;

export const { setIsSubmittingManualOrders, setSelectedManualOrders } =
  manualOrdersSlice.actions;

export const selectSelectedManualOrders = (state: RootState) =>
  state.manualOrders.selectedManualOrders;

export const selectIsSubmittingManualOrders = (state: RootState) =>
  state.manualOrders.isSubmittingManualOrders;

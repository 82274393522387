import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { selectCustomerName } from '../../common/store/customers-slice';
import { useSendAuthCodeMutation } from '../../common/store/domains-api-slice';
import { selectReseller } from '../../common/store/reseller';
import { useGetRestrictionsForUserQuery } from '../../common/store/restricted-users-api-slice';
import { selectCanWrite, selectUserEmail } from '../auth/auth-slice';
import { selectSelectedDomains } from './selected-domains-slice';

export const SendAuthCode = () => {
  const selectedDomains = useSelector(selectSelectedDomains);
  const customerName = useSelector(selectCustomerName);
  const reseller = useSelector(selectReseller);
  const canWrite = useSelector(selectCanWrite);
  const userEmail = useSelector(selectUserEmail);
  const domainName = selectedDomains[0].domainName;
  const { enqueueSnackbar } = useSnackbar();

  const [
    sendAuthCode,
    {
      error: sendAuthError,
      isError: isSendAuthError,
      isLoading: isSendAuthLoading,
      isSuccess: isSendAuthSuccess
    }
  ] = useSendAuthCodeMutation();

  const { data: restrictions, isFetching: isRestrictionsDataFetching } =
    useGetRestrictionsForUserQuery({
      customerName,
      reseller,
      userEmail
    });
  const { hasAuthCodeRequestRestriction } = restrictions ?? {};

  useEffect(() => {
    if (isSendAuthSuccess) {
      enqueueSnackbar('The authorization code email was successfully sent.', {
        variant: 'success'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSendAuthSuccess]);

  useEffect(() => {
    if (isSendAuthError) {
      enqueueSnackbar(
        sendAuthError?.data?.message ??
          'The authorization code email failed to send.',
        {
          variant: 'error'
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSendAuthError]);

  const handleEmailRequest = () =>
    sendAuthCode({
      customerName,
      domainName: domainName,
      reseller
    });

  return canWrite ? (
    <LoadingButton
      disabled={hasAuthCodeRequestRestriction || isRestrictionsDataFetching}
      loading={isSendAuthLoading}
      onClick={handleEmailRequest}
      variant="outlined"
    >
      Request via email
    </LoadingButton>
  ) : null;
};

import { LoadingButton } from '@mui/lab';
import { usePopupState } from 'material-ui-popup-state/hooks';
import React from 'react';
import { useSelector } from 'react-redux';

import { LocalPresenceConfirm } from './LocalPresenceConfirm';
import { selectCanEnableLocalPresence } from './selected-domains-slice';

// Filter on *.de to find domains in test with local presence

export const LocalPresenceEnable = () => {
  const canEnable = useSelector(selectCanEnableLocalPresence);

  const popupState = usePopupState({
    popupId: 'localPresenceEnableDialog',
    variant: 'dialog'
  });

  const handleEnableLocalPresence = popupState.open;

  return (
    canEnable && (
      <>
        <LoadingButton onClick={handleEnableLocalPresence}>
          Enable local presence
        </LoadingButton>
        <LocalPresenceConfirm popupState={popupState} />
      </>
    )
  );
};

// if all selected domains/tlds have local presence offered
// then if more than one domain is selected, show two buttons to enable/disable the local presence status

//   <div ng-if="Domains.domainsActions._perm.allHaveTldProperty('localPresenceOffered', true)">
//     <div ng-show="Domains.domainsActions._perm.selectedMoreThan(1)" style="padding-top: 5px;">
//       <button
//         class="button"
//         ng-show="Domains.domainsActions._perm.oneHasProperty('localPresenceInUse', false)"
//         ng-click="Domains.domainsActions.setLocalPresence(true)">
//         Enable Local Presence
//       </button>
//       <button
//         class="button"
//         ng-show="Domains.domainsActions._perm.oneHasProperty('localPresenceInUse', true)"
//         ng-click="Domains.domainsActions.setLocalPresence(false)">
//         Disable Local Presence
//       </button>
//     </div>
//   </div>
// </div>

import { Tab, Tabs } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router';

import { gridBasePaths } from '../../utils/grid-base-paths';

export const DomainsTabs = ({ index }) => {
  const navigate = useNavigate();

  const handleTabChange = (event, newValue) => {
    switch (newValue) {
      case 0:
        navigate(gridBasePaths.domains);
        break;
      case 1:
        navigate(gridBasePaths.preregistrations);
        break;
      case 2:
        navigate(gridBasePaths.web3);
        break;
      case 3:
        navigate(gridBasePaths.archived);
        break;
      default:
        break;
    }
  };

  return (
    <Tabs onChange={handleTabChange} sx={{ pl: 3 }} value={index}>
      <Tab label="Domains" />
      <Tab label="Pre-orders" />
      <Tab label="Web3" />
      <Tab label="Archived" />
    </Tabs>
  );
};

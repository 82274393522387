import { Chip, Stack, Typography } from '@mui/material';
import React from 'react';

/**
 * Display multiple domains as a list of chips
 * @param {Object} params
 * @param  {Object[]} params.domains
 * @param  {Number} [params.limit]
 * @param  {Function} params.onDeselect
 */
export const MultipleDomainsAsChips = ({
  domains = [],
  limit = 10,
  onDeselect
}) => {
  const domainsToShow = domains.slice(0, limit);
  const extra = domains.length - domainsToShow.length;
  const showExtra = extra > 0;

  return (
    <>
      <Stack direction="row" flexWrap="wrap" spacing={1} sx={{ gap: 1, mt: 3 }}>
        {domainsToShow.map(domain => (
          <Chip
            color="primary"
            key={domain.$id}
            label={domain.domainName}
            onDelete={() => onDeselect(domain.$id)}
            sx={{ ml: '0 !important' }}
            variant="outlined"
          />
        ))}
      </Stack>
      {showExtra && (
        <Typography sx={{ mt: 1 }} variant="body2">
          +{extra} more
        </Typography>
      )}
    </>
  );
};

import { LoadingButton } from '@mui/lab';
import { Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { selectCustomerName } from '../../common/store/customers-slice';
import { useSyncDomainsMutation } from '../../common/store/domains-api-slice';
import { selectReseller } from '../../common/store/reseller';
import { selectCanWrite } from '../auth/auth-slice';
import { selectSelectedDomains } from './selected-domains-slice';

export const SyncDomains = () => {
  const selectedDomains = useSelector(selectSelectedDomains);
  const accountName = useSelector(selectCustomerName);
  const resellerName = useSelector(selectReseller);

  const { enqueueSnackbar } = useSnackbar();

  const canWrite = useSelector(selectCanWrite);
  // TODO convert to selector
  const allManual = selectedDomains.every(domain => domain.manual);
  const canSync = canWrite && !allManual;

  const [
    syncDomains,
    {
      error: domainSyncError,
      isError: isDomainSyncError,
      isLoading: isDomainSyncLoading,
      isSuccess: isDomainSyncSuccess
    }
  ] = useSyncDomainsMutation();

  const handleDomainSyncing = () => {
    const selectedDomainsNames = Array.from(
      selectedDomains.map(domain => domain.domainName)
    );

    syncDomains({
      body: selectedDomainsNames,
      customerName: accountName,
      reseller: resellerName
    });
  };

  useEffect(() => {
    if (isDomainSyncSuccess) {
      if (selectedDomains.length > 1) {
        enqueueSnackbar('The selected domains were synced successfully.', {
          variant: 'success'
        });
      } else {
        enqueueSnackbar('The selected domain was synced successfully.', {
          variant: 'success'
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainSyncSuccess]);

  useEffect(() => {
    if (isDomainSyncError) {
      enqueueSnackbar(
        domainSyncError.data?.message ??
          'An error occurred while syncing the selected domain(s).',
        { variant: 'error' }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainSyncError]);

  return (
    canSync && (
      <Stack
        spacing={2}
        sx={{
          borderBottom: 'solid rgb(189, 189, 194)',
          borderBottomWidth: 1,
          borderTop: 'solid rgb(189, 189, 194)',
          borderTopWidth: 1,
          marginTop: 3,
          paddingBottom: 2,
          paddingLeft: 1,
          paddingTop: 2
        }}
      >
        <Typography variant="body2">
          Sync
          {selectedDomains.length > 1 ? " these domains' " : " this domain's "}
          data with the data found at the registry.
        </Typography>
        <LoadingButton
          loading={isDomainSyncLoading}
          onClick={() => handleDomainSyncing()}
          sx={{ width: 150 }}
          variant="outlined"
        >
          {selectedDomains.length > 1 ? 'Sync domains' : 'Sync domain'}
        </LoadingButton>
      </Stack>
    )
  );
};

import {
  faDollarSign,
  faExclamationTriangle,
  faGlobe
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Stack, Tooltip, useTheme } from '@mui/material';
import React from 'react';

export const AttributesCell = ({ params }) => {
  const theme = useTheme();
  return (
    <Stack direction="row" spacing={1}>
      {params.value && (
        <Tooltip title="Using local presence">
          <FontAwesomeIcon color={theme.palette.success.main} icon={faGlobe} />
        </Tooltip>
      )}
      {params.row.premiumRenewal && (
        <Tooltip title="Premium Domain Name">
          <FontAwesomeIcon
            color={theme.palette.success.main}
            icon={faDollarSign}
          />
        </Tooltip>
      )}
      {params.row.errorMessage.length > 0 && (
        <Tooltip title={params.row.errorMessage}>
          <FontAwesomeIcon
            color={theme.palette.error.main}
            icon={faExclamationTriangle}
          />
        </Tooltip>
      )}
    </Stack>
  );
};

import { Stack } from '@mui/material';
import { noop } from 'lodash-es';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { ContactSetsDialog } from '../../common/components/contact-sets/ContactSetsDialog';
import { TableDetailsHeader } from '../../common/components/table-details-header/TableDetailsHeader';
import { ContactSetsActions } from './ContactSetsActions';
import { ContactSetsDialogTrigger } from './ContactSetsDialogTrigger';
import { LocalPresenceExpirationDate } from './LocalPresenceExpirationDate';
import { LocalPresenceToggle } from './LocalPresenceToggle';
import { selectSelectedDomains } from './selected-domains-slice';
import { domainsTabs } from './tabs';

const allDomainsHaveSameContactSet = domains => {
  const contactSetIdToCheck = domains[0].customerContactSetId;
  return domains.every(
    domain => domain.customerContactSetId === contactSetIdToCheck
  );
};

export const DomainDetailsContactSets = () => {
  const selectedDomains = useSelector(selectSelectedDomains);

  const [contactSetId, setContactSetId] = useState(undefined);

  // Initialize form with any contact sets already assigned to selected domains
  useEffect(() => {
    // When dealing with only one domain show selected
    if (selectedDomains.length === 1) {
      setContactSetId(selectedDomains[0].customerContactSetId);
    }
    // Multiple domains with the same contact set, show selected
    else if (
      selectedDomains.length > 1 &&
      allDomainsHaveSameContactSet(selectedDomains)
    ) {
      setContactSetId(selectedDomains[0].customerContactSetId);
    } else {
      setContactSetId(-1);
    }
  }, [contactSetId, selectedDomains]);

  return (
    <>
      <TableDetailsHeader
        headerText={domainsTabs.CONTACT_SETS.label}
        noneSelectedText={
          selectedDomains.length < 1
            ? domainsTabs.CONTACT_SETS.noneSelectedMessage
            : undefined
        }
      />
      {selectedDomains.length > 0 && (
        <Stack direction="column" spacing={2}>
          <ContactSetsDialog
            contactSetId={contactSetId}
            domains={selectedDomains.map(domain => domain.domainName)}
            handleApplyContactSet={noop}
            renderActions={props => <ContactSetsActions {...props} />}
            renderTrigger={props => <ContactSetsDialogTrigger {...props} />}
          />
          <LocalPresenceExpirationDate />
          <LocalPresenceToggle />
        </Stack>
      )}
    </>
  );
};

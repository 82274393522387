import { faChevronDown } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
  useTheme
} from '@mui/material';
import React from 'react';

export const ActionHistory = ({ data, isError, isLoading }) => {
  const theme = useTheme();

  if (isLoading) {
    return (
      <Stack alignItems="center" direction="column" sx={{ pt: 10 }}>
        <CircularProgress />
      </Stack>
    );
  } else if (isError) {
    return <Typography>An error occurred. Try again.</Typography>;
  } else if (data?.length) {
    return data?.map(actionHistoryEntry => (
      <Accordion key={actionHistoryEntry.id}>
        <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />}>
          <Typography
            sx={{
              color: theme.palette.text.primary,
              display: 'inline-block',
              m: 0,
              p: 0
            }}
          >
            {actionHistoryEntry.historyActionType}{' '}
          </Typography>
          <Typography
            sx={{
              color: theme.palette.text.secondary,
              display: 'inline-block',
              ml: 1,
              p: 0
            }}
          >
            {actionHistoryEntry.$historyActionDateFormatted}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List>
            <ListItem>
              <ListItemText
                primary="Created"
                secondary={actionHistoryEntry.$createdFormatted}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Expires"
                secondary={actionHistoryEntry.$expirationDateFormatted}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="History Action Date"
                secondary={actionHistoryEntry.$historyActionDateFormatted}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Notes"
                secondary={
                  <pre
                    style={{
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    {actionHistoryEntry.notes}
                  </pre>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Username"
                secondary={actionHistoryEntry.userName || 'n/a'}
              />
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>
    ));
  }
};

import { Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import {
  selectAnyTldsHaveUnsupportedRenewSupportType,
  selectTldsWithUnsupportedRenewSupportType
} from './selected-domains-slice';

export const DomainDetailsRenewManualRenewUnsupported = () => {
  const anyTldsHaveUnsupportedRenewSupportType = useSelector(
    selectAnyTldsHaveUnsupportedRenewSupportType
  );
  const tldsWithUnsupportedRenewSupportType = useSelector(
    selectTldsWithUnsupportedRenewSupportType
  );

  return anyTldsHaveUnsupportedRenewSupportType ? (
    <>
      <Typography>
        The selection contains the following tld(s) that do not support
        renewals:
      </Typography>
      <ul>
        {tldsWithUnsupportedRenewSupportType.map(tld => (
          <li key={tld}>
            <code>{tld}</code>
          </li>
        ))}
      </ul>
    </>
  ) : null;
};

// if some TLDs do not support renewals
// then display a message that they don't

//   <div>
//     <hr/>
//     Manual renew
//     <!-- This renewal stuff is getting really convoluted. If we add ANY MORE conditional views here, please use ng-switch instead of adding to the ng-if spaghetti -->
//     <div
//       class="adx-alert-bottom adx-alert-warning"
//       ng-if="Domains.domainsActions._perm.oneHasTldProperty('renewSupportType', 'UNSUPPORTED')"
//     >
//       The selection contains the following tld(s) that do not support renewals: {{
//       Domains.getSelectedTldListWithUnsupportedRenewals().join(",") }}
//     </div>

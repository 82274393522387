import { intersection } from 'lodash-es';
import React from 'react';
import { useSelector } from 'react-redux';

import styles from './DomainDetailsManualRenew.module.css';
import { DomainDetailsManualRenewWithConfirm } from './DomainDetailsManualRenewWithConfirm';
import {
  selectAllDomainsHaveRenewalAvailability,
  selectAllTldsHaveSupportedRenewSupportType,
  selectSelectedDomains
} from './selected-domains-slice';

export const DomainDetailsManualRenew = () => {
  const domains = useSelector(selectSelectedDomains);
  const allTldsHaveSupportedRenewSupportType = useSelector(
    selectAllTldsHaveSupportedRenewSupportType
  );
  const allDomainsHaveRenewalAvailability = useSelector(
    selectAllDomainsHaveRenewalAvailability
  );

  const yearsInCommon = intersection(
    ...domains
      .map(({ renewalAvailability }) => renewalAvailability?.years)
      .filter(years => Array.isArray(years))
  );

  return allTldsHaveSupportedRenewSupportType &&
    allDomainsHaveRenewalAvailability &&
    yearsInCommon.length ? (
    <div className={styles.buttons}>
      {yearsInCommon.map(year => (
        <DomainDetailsManualRenewWithConfirm key={year} year={year} />
      ))}
    </div>
  ) : null;
};

// if all selected domains have a supported renew support type AND the TLD renewal length for all is > 0 AND years for products renewal is > 0
// then if the user does not have a MANUAL_RENEW restriction, show a button to renew the domain(s)

//     <div ng-if="!Domains.domainsActions._perm.oneHasTldProperty('renewSupportType', 'UNSUPPORTED') &&
//               Domains.domainsActions.getYearsForTlds('renewal').length > 0 &&
//               Domains.domainsActions.getYearsForProducts('renewal').length > 0">
//       <button
//         id="domains-manual-renew-{{year}}"
//         class="button"
//         ng-repeat="year in Domains.domainsActions.getYearsForProducts('renewal')"
//         ng-disabled="G.ThisUser.hasRestriction('MANUAL_RENEW')"
//         ng-click="Domains.domainsActions.renew(year)">
//         {{ year == 1 ? '1 Year' : year + ' Years' }}
//       </button>
//     </div>
//   </div>

import { Tab, Tooltip } from '@mui/material';
import React from 'react';

export const TabWithTooltip = ({ hide = false, title, ...rest }) => {
  return (
    !hide && (
      <Tooltip arrow={true} followCursor={true} placement="right" title={title}>
        <Tab {...rest}  />
      </Tooltip>
    )
  );
};

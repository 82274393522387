import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  Typography,
  useTheme
} from '@mui/material';
import {
  bindDialog,
  bindTrigger,
  usePopupState
} from 'material-ui-popup-state/hooks';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectCustomerName } from '../../common/store/customers-slice';
import { usePollJobResponseQuery } from '../../common/store/job-response-api-slice';
import {
  useCreateOrderMutation,
  usePriceCheckMutation
} from '../../common/store/orders-api-slice';
import { selectReseller } from '../../common/store/reseller';
import { useGetRestrictionsForUserQuery } from '../../common/store/restricted-users-api-slice';
import { getLocalizedDate } from '../../common/utils/date-time';
import { generateId } from '../../common/utils/generate-id';
import {
  selectCanWrite,
  selectClientId,
  selectUserEmail
} from '../auth/auth-slice';
import { selectSelectedDomains } from './selected-domains-slice';

export const EnableSubscription = () => {
  const selectedDomains = useSelector(selectSelectedDomains);

  const registryLockSupported = selectedDomains[0].registryLockSupported;
  const canWrite = useSelector(selectCanWrite);

  const reseller = useSelector(selectReseller);
  const customerName = useSelector(selectCustomerName);
  const clientId = useSelector(selectClientId);
  const userEmail = useSelector(selectUserEmail);

  const dialogState = usePopupState({
    popupId: 'enable-subscription-dialog',
    variant: 'dialog'
  });

  const [isBackdropOpen, setIsBackdropOpen] = useState(false);
  const [skipQuery, setSkipQuery] = useState(true);

  const registryLockSubscriptionActive =
    selectedDomains[0].registryLockSubscriptionActive;
  const isEnabling = !registryLockSubscriptionActive;

  const { data: restrictions, isFetching: isRestrictionsFetching } =
    useGetRestrictionsForUserQuery({
      customerName,
      reseller,
      userEmail
    });
  const { hasRegistryLockRestriction } = restrictions ?? {
    hasRegistryLockRestriction: false
  };

  const priceLineItem = useRef([
    {
      clientLineItemId: generateId(),
      domain: selectedDomains[0].domainName,
      lineItemNum: 0,
      lineItemType: 'DOMAIN_REGISTRY_LOCK',
      lockStatus: isEnabling,
      years: 1
    }
  ]);
  const registryLockLineItem = useRef([]);

  const orderOptions = { currency: 'USD', paymentType: 'NONE' };

  const theme = useTheme();

  const [priceCheck, { data: orderPrice, isError: isPriceCheckError }] =
    usePriceCheckMutation();
  const [
    createOrder,
    { isError: isCreateOrderError, isSuccess: isCreateOrderSuccess }
  ] = useCreateOrderMutation();

  const { data: jobResultData } = usePollJobResponseQuery(
    {
      clientId,
      clientLineItemIds: [registryLockLineItem.current[0]?.clientLineItemId]
    },
    { skip: skipQuery }
  );

  useEffect(() => {
    if (jobResultData?.anyPending && isCreateOrderSuccess) {
      setSkipQuery(true);
      dialogState.close();
      showSnackbar({ isError: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobResultData]);

  useEffect(() => {
    if (isPriceCheckError || isCreateOrderError) {
      setSkipQuery(true);
      setIsBackdropOpen(false);
      showSnackbar({ isError: true });
      dialogState.close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPriceCheckError, isCreateOrderError]);

  const getPrice = () =>
    priceCheck({
      clientId,
      customerName,
      lineItems: priceLineItem.current,
      orderOptions,
      reseller
    }).finally(() => setIsBackdropOpen(false));

  const isWithinPreviousRegistryLock = selectedDomain => {
    const expiration = selectedDomain.registryLockExpirationDate ?? null;

    if (expiration == null) {
      return false;
    }

    const expirationDate = new Date(expiration);

    return expirationDate > new Date();
  };

  const getSubmitButtonText = () => {
    if (isEnabling && !isWithinPreviousRegistryLock(selectedDomains[0])) {
      return `Submit order (${orderPrice?.$totalPriceFormatted ?? '$0.00'})`;
    } else {
      return 'Yes';
    }
  };

  const showSnackbar = ({ isError }) => {
    enqueueSnackbar(
      isError
        ? 'An unexpected error was encountered while submitting the registry status subscription change request'
        : getSuccessMessage(),
      {
        variant: isError ? 'error' : 'info'
      }
    );
  };

  useEffect(() => {
    // No need to price check for disabling
    if (
      dialogState.isOpen &&
      isEnabling &&
      !isWithinPreviousRegistryLock(selectedDomains[0])
    ) {
      setIsBackdropOpen(true);
      getPrice();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogState.isOpen]);

  const handleSubmitClick = () => {
    setIsBackdropOpen(true);
    registryLockLineItem.current = [
      {
        clientLineItemId: generateId(),
        domain: selectedDomains[0].domainName,
        lineItemNum: 0,
        lineItemType: 'DOMAIN_REGISTRY_LOCK',
        lockStatus: isEnabling,
        years: 1
      }
    ];

    createOrder({
      clientId,
      customerName,
      lineItems: registryLockLineItem.current,
      orderOptions,
      reseller
    }).then(() => setSkipQuery(false));
  };

  const getSuccessMessage = () =>
    isEnabling
      ? 'Thank you for requesting registry lock. A Tracer client service representative will contact you shortly.'
      : 'Your request to disable registry lock for this domain is being processed. A Tracer client service representative will contact you shortly.';

  return (
    registryLockSupported && (
      <Stack
        spacing={2}
        sx={{
          borderBottom: 'solid rgb(189, 189, 194)',
          borderBottomWidth: 1,
          borderTop: 'solid rgb(189, 189, 194)',
          borderTopWidth: 1,
          marginTop: 3,
          paddingBottom: 2,
          paddingLeft: 2,
          paddingTop: 2
        }}
      >
        <Typography variant="body1">REGISTRY LOCK</Typography>
        {selectedDomains[0].registryLockExpirationDate != null && (
          <div>
            <Typography
              sx={{ display: 'inline-block', fontWeight: 600 }}
              variant="body2"
            >
              Expiration date:
            </Typography>
            <Typography sx={{ display: 'inline-block', ml: 1 }} variant="body2">
              {getLocalizedDate(selectedDomains[0].registryLockExpirationDate)}
            </Typography>
          </div>
        )}
        {canWrite && (
          <div>
            <Button
              variant="outlined"
              {...bindTrigger(dialogState)}
              disabled={hasRegistryLockRestriction || isRestrictionsFetching}
            >
              {`${
                registryLockSubscriptionActive ? 'Disable' : 'Enable'
              } subscription`}
            </Button>
            <Typography sx={{ my: 1 }} variant="body2">
              Changing the registry lock status is a manual process; our client
              service representative will contact you within 8 hours and process
              your request
            </Typography>
          </div>
        )}
        <Dialog {...bindDialog(dialogState)} maxWidth="xs">
          <Backdrop
            open={isBackdropOpen}
            sx={{
              backgroundColor: 'rgba(255, 255, 255, 0.9)',
              bottom: 0,
              color: theme.palette.primary.main,
              left: 0,
              position: 'absolute',
              right: 0,
              top: 0,
              zIndex: theme => theme.zIndex.drawer + 1
            }}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <DialogContent>
            <Typography variant="body1">
              {`Are you sure you want to ${
                isEnabling ? 'enable' : 'disable'
              } registry lock for this domain?`}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                dialogState.close();
                registryLockLineItem.current = [];
              }}
              variant="outlined"
            >
              Cancel
            </Button>
            <Button onClick={handleSubmitClick} variant="contained">
              {getSubmitButtonText()}
            </Button>
          </DialogActions>
        </Dialog>
      </Stack>
    )
  );
};

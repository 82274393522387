import type { ManualOrdersFilterType } from './manual-orders';

import { ManualOrderStatusOptions, ManualOrderTypes } from './manual-orders';

export const ManualOrdersGridDefaults: ManualOrdersFilterType = {
  customerNames: undefined,
  dir: 'asc',
  domainName: undefined,
  manualOrderTypes: [
    ManualOrderTypes.DNS_DOMAIN,
    ManualOrderTypes.WEB3_REGISTRATION
  ],
  maxResults: 25,
  offset: 0,
  orderLineItemTypes: [],
  search: undefined,
  sort: 'createdDate',
  statuses: [
    ManualOrderStatusOptions.IN_PROGRESS,
    ManualOrderStatusOptions.PENDING,
    ManualOrderStatusOptions.SUBMITTED
  ]
};

import { Autocomplete, TextField } from '@mui/material';
import React from 'react';

export const NameServersFilterInput = props => {
  const { applyValue, item, nameServersData } = props;

  const handleFilterChange = (event, value) => applyValue({ ...item, value });

  return (
    <Autocomplete
      groupBy={option => option.groupName}
      limitTags={1}
      multiple
      onChange={handleFilterChange}
      options={nameServersData ?? []}
      renderInput={params => (
        <TextField {...params} label="Name Servers" variant="standard" />
      )}
      size="small"
      sx={{ width: '100%' }}
    />
  );
};

import { Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import { ActionHistory } from '../../common/components/action-history/ActionHistory';
import { TableDetailsHeader } from '../../common/components/table-details-header/TableDetailsHeader';
import { selectCustomerName } from '../../common/store/customers-slice';
import { useGetDomainActionHistoryQuery } from '../../common/store/domains-api-slice';
import { selectReseller } from '../../common/store/reseller';
import { selectSelectedDomains } from './selected-domains-slice';
import { domainsTabs } from './tabs';

export const DomainDetailsActionHistory = () => {
  const selectedDomains = useSelector(selectSelectedDomains);
  const reseller = useSelector(selectReseller);
  const customerName = useSelector(selectCustomerName);

  const { data, isError, isLoading } = useGetDomainActionHistoryQuery({
    customerName,
    domainName: selectedDomains[0]?.domainName,
    reseller
  });

  return (
    <>
      {selectedDomains.length === 0 && (
        <TableDetailsHeader
          headerText={domainsTabs.ACTION_HISTORY.label}
          noneSelectedText={
            selectedDomains.length === 0
              ? domainsTabs.ACTION_HISTORY.noneSelectedMessage
              : null
          }
          sx={{ p: 2 }}
        />
      )}
      {selectedDomains.length > 1 && (
        <Typography sx={{ p: 2 }}>
          Select one domain to view{' '}
          {domainsTabs.ACTION_HISTORY.label.toLowerCase()}.
        </Typography>
      )}
      {selectedDomains.length === 1 && (
        <ActionHistory data={data} isError={isError} isLoading={isLoading} />
      )}
    </>
  );
};

import { faBell } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Tooltip,
  useTheme
} from '@mui/material';
import {
  DatePicker,
  TimePicker,
  renderTimeViewClock
} from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { noop } from 'lodash-es';
import {
  bindDialog,
  bindTrigger,
  usePopupState
} from 'material-ui-popup-state/hooks';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';

import { selectCustomerName } from '../../../store/customers-slice';
import {
  useDeleteNoteReminderMutation,
  useGetNotesQuery,
  useSetNoteReminderMutation
} from '../../../store/notes-api-slice';
import { selectReseller } from '../../../store/reseller';
import { noteEntityType } from '../note-entity-type';
import { NOTES_REMINDERS_ERRORS, dateTimeFormSchema } from './schema';

const DATE_TIME_EVENTS = Object.freeze({
  date: 'dateField',
  time: 'timeField'
});

// eslint-disable-next-line complexity
export const NotesReminders = ({
  entityType = noteEntityType.domain,
  noteId,
  selectedDomains
}) => {
  const theme = useTheme();
  const customerName = useSelector(selectCustomerName);
  const reseller = useSelector(selectReseller);
  const domainId = selectedDomains?.[0]?.id;
  const dialogState = usePopupState({
    popupId: 'notesRemindersDialogPopup',
    variant: 'dialog'
  });

  const { isNoteFetching, note } = useGetNotesQuery(
    {
      customerName,
      id: domainId,
      noteEntityType: entityType.value,
      reseller
    },
    {
      selectFromResult: ({ data, isFetching }) => ({
        isNoteFetching: isFetching,
        note: data?.find(note => note.id === noteId)
      }),
      skip: !customerName || selectedDomains.length !== 1
    }
  );
  const hasActiveReminder = note?.reminder ? !note.reminder.notified : false;
  const iconButtonStyling = {
    iconColor: hasActiveReminder
      ? theme.palette.warning.main
      : theme.palette.secondary.main,
    tooltipTitle: hasActiveReminder ? 'View Reminder' : 'Set Reminder'
  };

  const handlePastDateError = () => {
    if (
      dateTimeFormData.dateField.touched &&
      dateTimeFormData.timeField.touched
    ) {
      // @ts-ignore
      dateTimeFormDataDispatch({
        errorMessage: NOTES_REMINDERS_ERRORS.pastDateTime,
        type: 'SET_PAST_DATE_ERROR'
      });
    }
  };

  const handleEmptyDateError = ({ path }) => {
    // @ts-ignore
    dateTimeFormDataDispatch({
      errorMessage: NOTES_REMINDERS_ERRORS.emptyDate,
      path,
      type: 'SET_EMPTY_FIELD_ERROR'
    });
  };

  const handleEmptyTimeError = ({ path }) => {
    // @ts-ignore
    dateTimeFormDataDispatch({
      errorMessage: NOTES_REMINDERS_ERRORS.emptyTime,
      path,
      type: 'SET_EMPTY_FIELD_ERROR'
    });
  };

  const emptyFormState = () =>
    dateTimeFormSchema(
      handlePastDateError,
      handleEmptyDateError,
      handleEmptyTimeError
    ).getDefault();

  const [dateTimeFormData, dateTimeFormDataDispatch] = useReducer(
    (state, action) => {
      if (action.path) {
        action.path = action.path.split('.')[0];
      }
      switch (action.type) {
        case 'INIT':
          state = emptyFormState();
          state.dateTime.value = action.dateTimeValue;
          state.timeField.touched = action.timeFieldTouched;
          state.dateField.touched = action.dateFieldTouched;
          break;
        case 'UPDATE':
          state.dateTime.value = action.dateTimeValue;
          state[action.path].touched = true;
          state[action.path].isError = false;
          state[action.path].errorMessage = '';
          break;
        case 'SET_EMPTY_FIELD_ERROR':
          state[action.path].isError = true;
          state[action.path].errorMessage = action.errorMessage;
          break;
        case 'SET_PAST_DATE_ERROR':
          state.dateTime.isError = true;
          state.dateTime.errorMessage = action.errorMessage;
          state.dateField.isError = true;
          state.timeField.isError = true;
          break;
        case 'RESET':
          state = emptyFormState();
          break;
        default:
          break;
      }
      return { ...state };
    },
    emptyFormState()
  );

  useEffect(() => {
    // @ts-ignore
    dateTimeFormDataDispatch({
      dateFieldTouched: hasActiveReminder,
      dateTimeValue: hasActiveReminder
        ? dayjs(note.reminder.reminderDate).toString()
        : dayjs().minute(0).second(0).millisecond(0).toString(),
      timeFieldTouched: hasActiveReminder,
      type: 'INIT'
    });
  }, [note, hasActiveReminder]);

  const closeDateTimeForm = () => {
    if (!hasActiveReminder) {
      // @ts-ignore
      dateTimeFormDataDispatch({
        type: 'RESET'
      });
    }
    dialogState.close();
  };

  const dialogTitle = `Reminder${
    note?.subject.length > 0 ? ` for ${note.subject}` : ''
  }`;

  const handleDateTimeChange = (event, change) => {
    const getDateTimeValue = (event, change) => {
      const baseTime =
        dateTimeFormData.dateTime.value === 'Invalid Date'
          ? dayjs().minute(0).second(0).millisecond(0)
          : dayjs(dateTimeFormData.dateTime.value);
      switch (change) {
        case DATE_TIME_EVENTS.date:
          return baseTime
            .date(event.date())
            .month(event.month())
            .year(event.year())
            .toString();
        case DATE_TIME_EVENTS.time:
          return baseTime.hour(event.hour()).toString();
      }
    };
    // @ts-ignore
    dateTimeFormDataDispatch({
      dateTimeValue: getDateTimeValue(event, change),
      path: change,
      type: 'UPDATE'
    });
  };

  const [
    setNoteReminder,
    {
      isError: isSetReminderError,
      isLoading: isSetReminderLoading,
      isSuccess: isSetReminderSuccess
    }
  ] = useSetNoteReminderMutation();

  const [
    deleteNoteReminder,
    {
      isError: isDeleteReminderError,
      isLoading: isDeleteReminderLoading,
      isSuccess: isDeleteReminderSuccess
    }
  ] = useDeleteNoteReminderMutation();

  const handleReminderSave = async () => {
    await dateTimeFormSchema(
      handlePastDateError,
      handleEmptyDateError,
      handleEmptyTimeError
    )
      .validate(dateTimeFormData, { abortEarly: false })
      .then(() => {
        setNoteReminder({
          customerName,
          noteId: note.id,
          reminderDateTime: dayjs(dateTimeFormData.dateTime.value).valueOf(),
          reminderId: note.reminder?.id,
          reseller
        });
      })
      .catch(noop);
  };

  const handleReminderDelete = () => {
    deleteNoteReminder({
      customerName,
      noteId: note.id,
      reminderId: note.reminder.id,
      reseller
    });
  };

  useEffect(() => {
    const setReminderSnackbarOptions = {
      message: isSetReminderSuccess
        ? 'The note reminder was successfully set.'
        : 'There was an error setting the note reminder. Please try again.',
      variant: isSetReminderSuccess ? 'success' : 'error'
    };
    if (isSetReminderSuccess || isSetReminderError) {
      enqueueSnackbar(setReminderSnackbarOptions.message, {
        // @ts-ignore
        variant: setReminderSnackbarOptions.variant
      });
      dialogState.close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSetReminderSuccess, isSetReminderError]);

  useEffect(() => {
    const deleteReminderSnackbarOptions = {
      message: isDeleteReminderSuccess
        ? 'The note reminder was successfully removed.'
        : 'There was an error removing the note reminder. Please try again.',
      variant: isDeleteReminderSuccess ? 'success' : 'error'
    };
    if (isDeleteReminderSuccess || isDeleteReminderError) {
      enqueueSnackbar(deleteReminderSnackbarOptions.message, {
        // @ts-ignore
        variant: deleteReminderSnackbarOptions.variant
      });
      dialogState.close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeleteReminderError, isDeleteReminderSuccess]);

  return (
    <>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          height: '30px',
          justifyContent: 'center',
          width: '30px'
        }}
      >
        {isNoteFetching ? (
          <CircularProgress size={20} />
        ) : (
          <Tooltip placement="top" title={iconButtonStyling.tooltipTitle}>
            <IconButton {...bindTrigger(dialogState)}>
              <FontAwesomeIcon
                color={iconButtonStyling.iconColor}
                icon={faBell}
                style={{ fontSize: '15px' }}
              />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      <Dialog
        {...bindDialog(dialogState)}
        fullWidth={true}
        maxWidth="sm"
        onClose={closeDateTimeForm}
      >
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <Stack direction="column" spacing={2}>
            {dateTimeFormData.dateTime.isError && (
              <Alert severity="error">
                {dateTimeFormData.dateTime.errorMessage}
              </Alert>
            )}
            <Stack
              direction="row"
              spacing={2}
              sx={{ display: 'flex', justifyContent: 'center', paddingTop: 1 }}
            >
              <DatePicker
                disablePast
                label="Reminder Date"
                onChange={event =>
                  handleDateTimeChange(event, DATE_TIME_EVENTS.date)
                }
                slotProps={{
                  textField: {
                    error: dateTimeFormData.dateField.isError,
                    helperText: dateTimeFormData.dateField.errorMessage
                  }
                }}
                value={
                  dateTimeFormData.dateField.touched
                    ? dayjs(dateTimeFormData.dateTime.value)
                    : null
                }
              />
              <TimePicker
                label="Reminder Time"
                onChange={event =>
                  handleDateTimeChange(event, DATE_TIME_EVENTS.time)
                }
                slotProps={{
                  textField: {
                    error: dateTimeFormData.timeField.isError,
                    helperText: dateTimeFormData.timeField.errorMessage
                  }
                }}
                value={
                  dateTimeFormData.timeField.touched
                    ? dayjs(dateTimeFormData.dateTime.value)
                    : null
                }
                viewRenderers={{
                  hours: renderTimeViewClock
                }}
                views={['hours']}
              />
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Stack direction="row" spacing={2}>
            <Button onClick={closeDateTimeForm}>Cancel</Button>
            {hasActiveReminder && (
              <LoadingButton
                loading={isDeleteReminderLoading}
                onClick={handleReminderDelete}
              >
                Delete
              </LoadingButton>
            )}
            <LoadingButton
              loading={isSetReminderLoading}
              onClick={handleReminderSave}
              variant="contained"
            >
              Save
            </LoadingButton>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
};

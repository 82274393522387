import React from 'react';
import { useSelector } from 'react-redux';

import { LocalPresenceDisable } from './LocalPresenceDisable';
import { LocalPresenceEnable } from './LocalPresenceEnable';
import {
  selectCanDisableLocalPresence,
  selectCanEnableLocalPresence
} from './selected-domains-slice';

export const LocalPresenceToggle = () => {
  const canDisable = useSelector(selectCanDisableLocalPresence);
  const canEnable = useSelector(selectCanEnableLocalPresence);

  return (
    <>
      {canEnable && <LocalPresenceEnable />}
      {canDisable && <LocalPresenceDisable />}
    </>
  );
};

import {
  // eslint-disable-next-line import/named
  GridToolbarContainer,
  // eslint-disable-next-line import/named
  GridToolbarFilterButton,
  // eslint-disable-next-line import/named
  GridToolbarQuickFilter
} from '@mui/x-data-grid-pro';
import React from 'react';

import DomainImports from '../../../features/domain-imports/DomainImports';
import { DomainsGridToolbarExport } from '../domains-grid-toolbar-export/DomainsGridToolbarExport';
import { SearchTips } from '../search-tips/SearchTips';

export const DomainsToolbar = ({ archived, exportType }) => (
  <GridToolbarContainer
    sx={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between'
    }}
  >
    <div>
      <GridToolbarFilterButton />
      <DomainsGridToolbarExport archived={archived} exportType={exportType} />
      {!archived && <DomainImports />}
    </div>
    <div>
      <SearchTips />
      <GridToolbarQuickFilter debounceMs={1500} sx={{ mr: 1, mt: 1 }} />
    </div>
  </GridToolbarContainer>
);

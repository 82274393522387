import { Autocomplete, TextField } from '@mui/material';
import React from 'react';

export const LabelsFilterInput = props => {
  const { applyValue, item, labelsData } = props;

  const handleFilterChange = (event, value) => applyValue({ ...item, value });

  return (
    <Autocomplete
      groupBy={option => option.labelGroupName}
      limitTags={1}
      multiple
      onChange={handleFilterChange}
      options={labelsData.flat ?? []}
      renderInput={params => (
        <TextField {...params} label="Labels" variant="standard" />
      )}
      size="small"
      sx={{ width: '100%' }}
      value={item.value}
    />
  );
};

import dayjs from 'dayjs';

import { WEB3_TAG, apiSlice } from '../../common/store/api-slice';
import { getAssociatedLabelsForDomain } from '../../common/store/api-slice-utils';
import {
  getLocalizedDate,
  getLocalizedDateTime
} from '../../common/utils/date-time';

const DEFAULT_SORT = 'fullDomainName';
const DEFAULT_FROM_DATE = dayjs('1980-07-31 00:00').format('YYYY-MM-DD');
const getDefaultToDate = () => dayjs().format('YYYY-MM-DD');

export const transferToOptions = Object.freeze({
  CUSTOMER: 'CUSTOMER',
  REGISTRAR: 'REGISTRAR'
});

const web3Statuses = Object.freeze({
  MANAGED_BY_REGISTRAR: {
    key: 'MANAGED_BY_REGISTRAR',
    label: 'Managed by Registrar'
  },
  TRANSFERRED_TO_CUSTOMER_WALLET: {
    key: 'TRANSFERRED_TO_CUSTOMER_WALLET',
    label: 'Transferred to Customer Wallet'
  }
});

// These are the same/also used by traditional domains
const statuses = Object.freeze({
  ACTIVE: 'Active',
  DELETED: 'Deleted',
  EXPIRED: 'Expired',
  IN_PROGRESS: 'In Progress',
  MIGRATED: 'Migrated',
  PENDING: 'Pending',
  SUBMITTED: 'Submitted'
});

const web3ApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    exportWeb3CustomerDomains: builder.mutation({
      query: ({
        customerName,
        dir,
        find,
        fromDate = DEFAULT_FROM_DATE,
        labelIds = [],
        reseller,
        sort = DEFAULT_SORT,
        toDate = getDefaultToDate()
      }) => ({
        body: {
          dir,
          find,
          fromDate,
          labelIds,
          sort,
          toDate
        },
        method: 'POST',
        url: `resellers/${reseller}/customers/${customerName}/web3/export`
      })
    }),
    getWeb3ByCustomer: builder.query({
      providesTags: [WEB3_TAG],
      query: ({
        // eslint-disable-next-line no-unused-vars
        associationsData,
        customerName,
        dir = 'asc',
        find,
        fromDate = DEFAULT_FROM_DATE,
        labelIds = [],
        // eslint-disable-next-line no-unused-vars
        labelsData,
        maxResults = '25',
        offset = 0,
        reseller,
        sort = 'start-date',
        toDate = getDefaultToDate()
      }) => ({
        body: {
          accountName: customerName,
          dir,
          find,
          fromDate,
          labelIds,
          maxResults,
          offset,
          sort,
          toDate
        },
        method: 'POST',
        url: `resellers/${reseller}/customers/${customerName}/web3`
      }),
      transformResponse: (response, meta, { associationsData, labelsData }) => {
        response.entries = response.entries.map(entry => {
          entry.$expirationDateFormatted = entry.expirationDate.startsWith(
            '9999-'
          )
            ? 'n/a'
            : getLocalizedDate(entry.expirationDate);

          entry.$registrarCreationDateFormatted = getLocalizedDate(
            entry.registrarCreationDate
          );

          entry.$labels = getAssociatedLabelsForDomain(
            entry.domainName,
            labelsData,
            associationsData
          );

          entry.$web3StatusFormatted = web3Statuses[entry.web3Status].label;
          entry.$statusFormatted = statuses[entry.status];

          entry.$id = entry.id;
          return entry;
        });
        return response;
      }
    }),
    getWeb3DomainActionHistory: builder.query({
      providesTags: [WEB3_TAG],
      query: ({ customerName, domainName, reseller }) =>
        `resellers/${reseller}/customers/${customerName}/domains/${domainName}/history?maxResults=10000&offset=0`,
      transformResponse: ({ entries }) =>
        entries.map(entry => ({
          ...entry,
          $createdFormatted: getLocalizedDate(entry.created),
          $expirationDateFormatted: getLocalizedDate(entry.expirationDate),
          $historyActionDateFormatted: getLocalizedDateTime(
            entry.historyActionDate
          )
        }))
    }),
    updateManagementStatus: builder.mutation({
      invalidatesTags: [WEB3_TAG],
      query: ({ customerName, ids, reseller, transferTo }) => {
        return {
          body: ids,
          method: 'POST',
          url: `resellers/${reseller}/customers/${customerName}/web3/update-management-status/${
            transferTo === transferToOptions.CUSTOMER
              ? web3Statuses.TRANSFERRED_TO_CUSTOMER_WALLET.key
              : web3Statuses.MANAGED_BY_REGISTRAR.key
          }`
        };
      }
    })
  })
});

export const {
  useExportWeb3CustomerDomainsMutation,
  useGetWeb3ByCustomerQuery,
  useGetWeb3DomainActionHistoryQuery,
  useUpdateManagementStatusMutation
} = web3ApiSlice;

import { Divider, Stack, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import { selectCanWrite } from '../auth/auth-slice';
import { NameServerFields } from './NameServerFields';
import { selectSelectedDomains } from './selected-domains-slice';

export const NameServers = () => {
  const selectedDomains = useSelector(selectSelectedDomains);
  const canWrite = useSelector(selectCanWrite);

  return (
    selectedDomains.length > 0 && (
      <div>
        <Stack spacing={1}>
          {selectedDomains.length === 1 ? (
            <Typography variant="h5">
              {selectedDomains[0].domainName}
            </Typography>
          ) : (
            <Typography variant="h5">Multiple domains</Typography>
          )}
          <Divider sx={{ my: 1 }} />
          <Typography variant="subtitle1">Name Servers</Typography>
          {canWrite && (
            <Typography variant="body2">
              Copy name servers from an existing group or make individual edits
              to your name servers.
            </Typography>
          )}
          <NameServerFields />
        </Stack>
      </div>
    )
  );
};

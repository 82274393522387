import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  List,
  ListItem,
  useTheme
} from '@mui/material';
import {
  bindDialog,
  bindToggle,
  bindTrigger,
  usePopupState
} from 'material-ui-popup-state/hooks';
import React from 'react';

export const SearchTips = () => {
  const theme = useTheme();
  const dialogState = usePopupState({
    popupId: 'domainSearchTips',
    variant: 'dialog'
  });

  return (
    <>
      <Link
        {...bindTrigger(dialogState)}
        sx={{
          cursor: 'pointer',
          display: 'inline-block',
          lineHeight: '40px',
          mr: 3,
          textDecoration: 'none',
          textTransform: 'none'
        }}
        underline="none"
      >
        Search Tips
      </Link>
      <Dialog {...bindDialog(dialogState)}>
        <DialogTitle>Searching for Registered Domains</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The domain search tool utilizes wildcard functionality to allow for
            advanced filtering. The * symbol can be used in place of any
            character(s) to search for registered domains. When the dot is
            omitted from the search query, your search will match any TLD.
          </DialogContentText>
          <DialogContentText sx={{ mt: 2 }}>Examples:</DialogContentText>

          <List
            dense
            sx={{
              color: theme.palette.secondary.main,
              listStyleType: 'disc'
            }}
          >
            <ListItem disableGutters sx={{ display: 'list-item', ml: 3 }}>
              domain-1.com (exact name and TLD search)
            </ListItem>
            <ListItem disableGutters sx={{ display: 'list-item', ml: 3 }}>
              domain-1 (exact name, any TLD)
            </ListItem>
            <ListItem disableGutters sx={{ display: 'list-item', ml: 3 }}>
              *ain-1.com, dom*.com (wildcard name, exact TLD)
            </ListItem>
            <ListItem disableGutters sx={{ display: 'list-item', ml: 3 }}>
              domain*, *domain, dom*in (wildcard name, any TLD)
            </ListItem>
            <ListItem disableGutters sx={{ display: 'list-item', ml: 3 }}>
              domain-1.*uk (exact name, wildcard TLD)
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button {...bindToggle(dialogState)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

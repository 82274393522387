import { camelCase, upperFirst } from 'lodash-es';

import {
  RESTRICTED_USERS_TAG,
  USER_RESTRICTIONS_TAG,
  apiSlice
} from '../../common/store/api-slice';

export const restrictedUsersApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    addRestrictionForUser: builder.mutation({
      invalidatesTags: [USER_RESTRICTIONS_TAG],
      query: ({ customerName, reseller, restriction, userEmail }) => ({
        body: { restriction },
        method: 'POST',
        url: `resellers/${reseller}/customers/${customerName}/restricted-user/${encodeURIComponent(
          userEmail
        )}/restriction`
      })
    }),
    createRestrictedUser: builder.mutation({
      invalidatesTags: [RESTRICTED_USERS_TAG],
      query: ({ customerName, reseller, userEmail }) => ({
        body: { email: userEmail },
        method: 'POST',
        url: `resellers/${reseller}/customers/${customerName}/restricted-user`
      })
    }),
    getRestrictedUsers: builder.query({
      providesTags: [RESTRICTED_USERS_TAG],
      query: ({ customerName, reseller }) =>
        `resellers/${reseller}/customers/${customerName}/restricted-user`,
      transformResponse: response => {
        return response.map(userEmail => ({
          $email: userEmail,
          $id: userEmail
        }));
      }
    }),
    getRestrictionsForUser: builder.query({
      providesTags: [USER_RESTRICTIONS_TAG],
      query: ({ customerName, reseller, userEmail }) =>
        `resellers/${reseller}/customers/${customerName}/restricted-user/${encodeURIComponent(
          userEmail
        )}`,
      transformResponse: response => {
        const restrictions = response?.restrictions?.map(
          restrictionObj => restrictionObj.restriction
        );
        const restrictionsResponse = {};

        restrictions?.forEach(restriction => {
          restrictionsResponse[
            `has${upperFirst(camelCase(restriction))}Restriction`
          ] = true;
        });

        return restrictionsResponse;
      }
    }),
    removeRestrictedUser: builder.mutation({
      invalidatesTags: [RESTRICTED_USERS_TAG],
      query: ({ customerName, reseller, userEmail }) => ({
        method: 'DELETE',
        url: `resellers/${reseller}/customers/${customerName}/restricted-user/${encodeURIComponent(
          userEmail
        )}`
      })
    }),
    removeRestrictionForUser: builder.mutation({
      invalidatesTags: [USER_RESTRICTIONS_TAG],
      query: ({ customerName, reseller, restriction, userEmail }) => ({
        method: 'DELETE',
        url: `resellers/${reseller}/customers/${customerName}/restricted-user/${encodeURIComponent(
          userEmail
        )}/restriction/${restriction}`
      })
    })
  })
});

export const {
  useAddRestrictionForUserMutation,
  useCreateRestrictedUserMutation,
  useGetRestrictedUsersQuery,
  useGetRestrictionsForUserQuery,
  useRemoveRestrictedUserMutation,
  useRemoveRestrictionForUserMutation
} = restrictedUsersApiSlice;

import React from 'react';
import { useSelector } from 'react-redux';

import { TableDetailsHeader } from '../../common/components/table-details-header/TableDetailsHeader';
import { DnsRecords } from './DnsRecords';
import { HostNameServers } from './HostNameServers';
import { NameServers } from './NameServers';
import {
  selectAllDomainsSupportHostManagement,
  selectIsNoneSelected,
  selectIsOneSelected
} from './selected-domains-slice';
import { domainsTabs } from './tabs';

export const DomainDetailsNameServers = () => {
  const isNoneSelected = useSelector(selectIsNoneSelected);
  const isOneSelected = useSelector(selectIsOneSelected);
  const allDomainsSupportHostManagement = useSelector(
    selectAllDomainsSupportHostManagement
  );
  const shouldShowHostNameServers =
    isOneSelected && allDomainsSupportHostManagement;
  // Once selected the header becomes the domain name or 'multiple domains' and thats implemented in a different component in another pr that needs to be revisited
  return (
    <>
      {isNoneSelected && (
        <TableDetailsHeader
          headerText={domainsTabs.NAME_SERVERS.label}
          noneSelectedText={domainsTabs.NAME_SERVERS.noneSelectedMessage}
        />
      )}
      <NameServers />
      {isOneSelected && <DnsRecords />}
      {shouldShowHostNameServers && <HostNameServers />}
    </>
  );
};

import {
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Switch
} from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import { useAppInfoQuery } from '../../common/store/app-info-api-slice';
import { selectCustomerName } from '../../common/store/customers-slice';
import { selectReseller } from '../../common/store/reseller';
import { useGetRestrictionsForUserQuery } from '../../common/store/restricted-users-api-slice';
import { selectUserEmail } from '../auth/auth-slice';
import { useUpdateMaxAutoRenewForDomainsMutation } from './auto-renew-api-slice';
import { selectSelectedDomains } from './selected-domains-slice';

export const DomainDetailsRenewToggleMaxAutoRenewForDomain = () => {
  const reseller = useSelector(selectReseller);
  const customerName = useSelector(selectCustomerName);
  const userEmail = useSelector(selectUserEmail);
  const domains = useSelector(selectSelectedDomains);
  const [updateMaxAutoRenew, { isLoading: isUpdateMaxAutoRenewLoading }] =
    useUpdateMaxAutoRenewForDomainsMutation();

  const { data: appInfoData } = useAppInfoQuery();
  const { data: restrictions, isSuccess: isGetRestrictionsSuccess } =
    useGetRestrictionsForUserQuery({
      customerName,
      reseller,
      userEmail
    });
  const { hasAutoRenewRestriction } = restrictions ?? {};

  const handleMaxAutoRenewChange = event =>
    updateMaxAutoRenew({
      autoRenew: event.target.checked,
      customerName,
      domains: domains.map(({ domainName }) => domainName),
      reseller
    });

  return isGetRestrictionsSuccess &&
    domains.length === 1 &&
    appInfoData?.maxAutorenewEnabled ? (
    <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
      <FormControlLabel
        control={
          <Switch
            checked={domains[0].maxAutorenew}
            disabled={hasAutoRenewRestriction}
            onChange={handleMaxAutoRenewChange}
          />
        }
        label="Max auto renewal"
      />
      {isUpdateMaxAutoRenewLoading && <CircularProgress size={30} />}
    </FormGroup>
  ) : null;
};

// if max auto renew is enabled:
// then if one domain is selected show a toggle to set max auto renew
// then if more than one domain is selected display two buttons to either ON or OFF max auto renew
// then display a notice about max auto renew
//   <div ng-if="Domains.maxAutorenewEnabled">
//     <div ng-show="Domains.domainsActions._perm.selectedExactly(1)">
//       <adx-switch
//         input-id="domains-max-autorenew-toggle"
//         input-checked="Domains.domainsActions.getDetails().get('maxAutorenew')"
//         input-label="Max Autorenew"
//         input-disabled="G.ThisUser.hasRestriction('AUTO_RENEW')"
//         input-change="Domains.domainsActions.toggleMaxAutoRenew()">
//       </adx-switch>
//     </div>

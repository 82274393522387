// TODO move to common
import { Tooltip, Typography } from '@mui/material';
import React from 'react';

export const ExpiresCell = ({ params }) => {
  switch (params.row.status) {
    case 'ACTIVE':
      return params.row.$expirationDateFormatted;
    case 'EXPIRED':
      return (
        <Tooltip title="This domain is expired">
          <Typography sx={{ color: 'error.main', fontSize: 'inherit' }}>
            {params.row.$expirationDateFormatted}
          </Typography>
        </Tooltip>
      );
    case 'SUBMITTED':
      return (
        <Tooltip title="This domain is pending registration at the registry">
          <Typography variant="body2">Pending</Typography>
        </Tooltip>
      );
    default:
      return '';
  }
};

import { faPlus, faTrashCan } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Autocomplete,
  Button,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { noop } from 'lodash-es';
import { useSnackbar } from 'notistack';
import React, { useEffect, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';

import { CopyToClipboard } from '../../common/components/copy-to-clipboard/CopyToClipboard';
import {
  fieldSchema,
  formSchema
} from '../../common/components/name-servers/name-server-schema';
import { SignOffDialog } from '../../common/components/sign-offs/SignOffDialog';
import { SnackbarCloseAction } from '../../common/components/snackbar-close-action/SnackbarCloseAction';
import { CUSTOMER_TAG, apiSlice } from '../../common/store/api-slice';
import { selectCustomerName } from '../../common/store/customers-slice';
import {
  LINE_ITEM_STATUSES,
  POLLING_INTERVAL,
  POLLING_MAX_REQUESTS,
  usePollJobResponseQuery
} from '../../common/store/job-response-api-slice';
import { useGetNameServerGroupsQuery } from '../../common/store/name-servers-api-slice';
import { useCreateOrderMutation } from '../../common/store/orders-api-slice';
import { selectReseller } from '../../common/store/reseller';
import { useGetRestrictionsForUserQuery } from '../../common/store/restricted-users-api-slice';
import { generateId } from '../../common/utils/generate-id';
import {
  selectCanWrite,
  selectClientId,
  selectUserEmail
} from '../auth/auth-slice';
import { DomainDetailsOrderUpdateErrorMessage } from './DomainDetailsOrderUpdateErrorMessage';
import { DomainDetailsOrderUpdatePendingMessage } from './DomainDetailsOrderUpdatePendingMessage';
import { nameServerFieldsReducer } from './name-server-fields-reducer';
import {
  selectHaveNoNameServersConfigured,
  selectMultipleDomainsHaveDifferentNameServers,
  selectNameServersToDisplay,
  selectSelectedDomains
} from './selected-domains-slice';

// eslint-disable-next-line complexity
export const NameServerFields = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const selectedDomains = useSelector(selectSelectedDomains);
  const appliedNameServers = useSelector(selectNameServersToDisplay);
  const noNameServersConfigured = useSelector(
    selectHaveNoNameServersConfigured
  );
  const multipleDomainsHaveDifferentNameServers = useSelector(
    selectMultipleDomainsHaveDifferentNameServers
  );
  const reseller = useSelector(selectReseller);
  const customerName = useSelector(selectCustomerName);
  const clientId = useSelector(selectClientId);
  const canWrite = useSelector(selectCanWrite);
  const userEmail = useSelector(selectUserEmail);
  const [validationErrors, setValidationErrors] = useState(false);
  const [lineItemsToCreate, setLineItemsToCreate] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState('');
  const [refetchCount, setRefetchCount] = useState(0);
  const [signOffDialogIsOpen, setSignOffDialogIsOpen] = useState(false);
  const [forceUpdateSent, setForceUpdateSent] = useState(false);

  const { data: nameServerGroups } = useGetNameServerGroupsQuery(
    {
      customerName,
      reseller
    },
    { skip: !customerName }
  );

  const [
    createOrder,
    { data: createOrderData, isError: isCreateOrderError, isLoading }
  ] = useCreateOrderMutation();
  const shouldSkipPolling = createOrderData?.signOffRequired !== false;

  const { data: jobResultData, refetch } = usePollJobResponseQuery(
    {
      clientId,
      clientLineItemIds: lineItemsToCreate.map(
        ({ clientLineItemId }) => clientLineItemId
      )
    },
    { skip: shouldSkipPolling || !lineItemsToCreate?.length }
  );

  const { data: restrictions, isFetching: isRestrictionsFetching } =
    useGetRestrictionsForUserQuery({
      customerName,
      reseller,
      userEmail
    });
  const { hasNameServerUpdatesRestriction } = restrictions ?? {};

  const isDisabledDueToRestrictions =
    hasNameServerUpdatesRestriction || isRestrictionsFetching;

  const isLoadingOrFetching = isLoading || lineItemsToCreate?.length > 0;

  const handleOptionChange = (event, value) => {
    setSelectedGroup(value);
  };
  const handleCopy = () => {
    if (selectedGroup) {
      const [groupHosts] = nameServerGroups
        .filter(nameServerGroup => nameServerGroup.name === selectedGroup)
        .map(nameServerGroup => nameServerGroup.hosts);
      // @ts-ignore
      formDataDispatch({
        type: 'COPY',
        validationFunction: () => handleNameServersValidation,
        value: groupHosts
      });
    }
  };

  useEffect(() => {
    // @ts-ignore
    formDataDispatch({
      type: 'INIT',
      validationFunction: () => handleNameServersValidation,
      value: appliedNameServers
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDomains, appliedNameServers]);

  const handleNameServersValidation =
    forceTouch =>
    ({ path }) =>
      // @ts-ignore
      formDataDispatch({
        errorMessage: 'Name server is not valid',
        forceTouch,
        path,
        type: 'SET_ERROR',
        validationFunction: () => handleNameServersValidation
      });

  const handleUniquenessValidation = forceTouch => () =>
    // @ts-ignore
    formDataDispatch({
      errorMessage: 'Name servers must be unique',
      forceTouch,
      type: 'SET_UNIQUE_ERROR',
      validationFunction: () => handleNameServersValidation
    });

  const getField = (handleNameServersValidation, value) =>
    fieldSchema(handleNameServersValidation, value).getDefault();

  const [formData, formDataDispatch] = useReducer(
    nameServerFieldsReducer,
    appliedNameServers.map(nameServer =>
      getField(handleNameServersValidation(), nameServer)
    )
  );

  const handleAddMore = () =>
    formDataDispatch({
      type: 'ADD_FIELD',
      validationFunction: () => handleNameServersValidation
    });

  const handleRemove = key =>
    formDataDispatch({
      key,
      type: 'REMOVE_FIELD',
      validationFunction: () => handleNameServersValidation
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedValidation = useDebouncedCallback(
    formSchema =>
      formSchema(handleNameServersValidation(), handleUniquenessValidation())
        .validate(formData)
        .catch(noop),
    1500
  );

  const handleChange = (value, key) => {
    // @ts-ignore
    formDataDispatch({
      key,
      type: 'UPDATE_FIELD',
      validationFunction: () => handleNameServersValidation,
      value
    });
    debouncedValidation(formSchema);
  };

  const handleSubmit = event => {
    event.preventDefault();
    setForceUpdateSent(false);
    formSchema(
      handleNameServersValidation(true),
      handleUniquenessValidation(true)
    )
      .validate(formData)
      .then(() => {
        setValidationErrors(false);
      })
      .then(() => {
        const nameServers = formData.map(item => item.value);
        const lineItems = selectedDomains.map(
          ({ domainName }, lineItemNum) => ({
            clientLineItemId: generateId(),
            domain: domainName,
            lineItemNum,
            lineItemType: 'CHANGE_NAMESERVER',
            nameServers: nameServers
          })
        );
        setLineItemsToCreate(lineItems);
        createOrder({
          clientId,
          customerName,
          lineItems,
          orderOptions: { paymentType: 'NONE' },
          reseller
        });
      })
      .catch(e => {
        window.console.error(e);
        setValidationErrors(true);
      });
  };

  const handleForceUpdate = () => {
    createOrder({
      clientId,
      customerName,
      lineItems: lineItemsToCreate,
      orderOptions: { paymentType: 'NONE' },
      reseller,
      signOffForceUpdate: true
    });
    setSignOffDialogIsOpen(false);
    setForceUpdateSent(true);
    setLineItemsToCreate([]);
    // @ts-ignore
    formDataDispatch({
      type: 'INIT',
      validationFunction: () => handleNameServersValidation,
      value: selectedDomains[0].nameServerInfos
    });
  };

  const handleCloseSignOffDialog = () => {
    setSignOffDialogIsOpen(false);
    setLineItemsToCreate([]);
    // @ts-ignore
    formDataDispatch({
      type: 'INIT',
      validationFunction: () => handleNameServersValidation,
      value: selectedDomains[0].nameServerInfos
    });
  };

  const failedDomainUpdates = jobResultData?.messages
    .filter(message => message.status === LINE_ITEM_STATUSES.failure)
    .map(message => ({
      ...message,
      domainName: lineItemsToCreate.find(
        lineItem => lineItem.clientLineItemId === message.clientLineItemId
      )?.domain
    }));

  const pendingDomainUpdates = jobResultData?.messages
    .filter(message => message.status === LINE_ITEM_STATUSES.pending)
    .map(message => ({
      ...message,
      domainName: lineItemsToCreate.find(
        lineItem => lineItem.clientLineItemId === message.clientLineItemId
      )?.domain
    }));

  // Handles job polling
  useEffect(() => {
    if (jobResultData?.anyPending && refetchCount < POLLING_MAX_REQUESTS) {
      setTimeout(refetch, POLLING_INTERVAL.active);
      setRefetchCount(refetchCount + 1);
    } else if (refetchCount >= POLLING_MAX_REQUESTS) {
      enqueueSnackbar(
        <Stack spacing={1}>
          <DomainDetailsOrderUpdatePendingMessage
            pendingDomainUpdates={pendingDomainUpdates}
          />
          {failedDomainUpdates?.length > 0 && (
            <DomainDetailsOrderUpdateErrorMessage
              failedDomainUpdates={failedDomainUpdates}
            />
          )}
        </Stack>,
        {
          action: snackbarId => (
            <Stack direction="row" spacing={1}>
              <CopyToClipboard
                sx={{
                  borderColor: '#fff !important',
                  color: '#fff !important'
                }}
                textToCopy={failedDomainUpdates
                  .concat(pendingDomainUpdates)
                  .map(({ domainName }) => domainName)
                  .toString()}
                variant="outlined"
              />
              <SnackbarCloseAction snackbarId={snackbarId} />
            </Stack>
          ),
          persist: true,
          variant: 'warning'
        }
      );
      setLineItemsToCreate([]);
      dispatch(apiSlice.util.invalidateTags([CUSTOMER_TAG]));
      setRefetchCount(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobResultData]);

  // Handles snackbar after polling
  useEffect(() => {
    if (jobResultData?.progress === 100 && lineItemsToCreate.length) {
      if (jobResultData.anyFailures) {
        enqueueSnackbar(
          <DomainDetailsOrderUpdateErrorMessage
            failedDomainUpdates={failedDomainUpdates}
          />,
          {
            action: snackbarId => (
              <Stack direction="row" spacing={1}>
                <CopyToClipboard
                  sx={{
                    borderColor: '#fff !important',
                    color: '#fff !important'
                  }}
                  textToCopy={failedDomainUpdates
                    .map(({ domainName }) => domainName)
                    .toString()}
                  variant="outlined"
                />
                <SnackbarCloseAction snackbarId={snackbarId} />
              </Stack>
            ),
            persist: true,
            variant: 'error'
          }
        );
      } else {
        enqueueSnackbar('Successfully updated the selected domain(s).', {
          variant: 'success'
        });
      }
      setLineItemsToCreate([]);
      dispatch(apiSlice.util.invalidateTags([CUSTOMER_TAG]));
      setRefetchCount(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lineItemsToCreate, jobResultData]);

  //Create Order request fails
  useEffect(() => {
    if (isCreateOrderError) {
      enqueueSnackbar(
        'Encountered an error trying to submit name server changes.',
        {
          variant: 'error'
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreateOrderError]);

  //Sign Off Required - none pending
  useEffect(() => {
    if (
      createOrderData?.signOffRequired &&
      !createOrderData?.forceUpdateRequired
    ) {
      enqueueSnackbar('Your request is now pending Sign-Off', {
        variant: 'info'
      });
      setLineItemsToCreate([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createOrderData]);

  //Sign Off Required - sign off pending
  useEffect(() => {
    if (
      createOrderData?.signOffRequired &&
      createOrderData?.forceUpdateRequired &&
      !forceUpdateSent
    ) {
      setSignOffDialogIsOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createOrderData]);

  return (
    <>
      {selectedDomains.length > 0 && canWrite && (
        <Stack
          alignItems="center"
          direction={'row'}
          spacing={2}
          sx={{ mb: 1.5, mt: 1 }}
        >
          <Autocomplete
            disablePortal
            disabled={isDisabledDueToRestrictions}
            id="name-server-groups"
            onChange={handleOptionChange}
            options={nameServerGroups?.map(group => group.name) ?? []}
            renderInput={params => (
              <TextField {...params} label="Name Server Group" />
            )}
            sx={{ width: 300 }}
          />
          <Button
            disabled={isDisabledDueToRestrictions}
            onClick={handleCopy}
            variant="outlined"
          >
            Copy
          </Button>
        </Stack>
      )}
      <form onSubmit={handleSubmit}>
        <Stack spacing={1}>
          {validationErrors && (
            <Alert severity="error">Fix form errors before submitting.</Alert>
          )}
          {multipleDomainsHaveDifferentNameServers && (
            <Typography variant="body2">
              The selected domains have different name servers configured.
            </Typography>
          )}
          {noNameServersConfigured && (
            <Typography variant="body2">No name servers configured.</Typography>
          )}
          {formData.map((nameServer, index) => (
            <Stack direction="column" key={nameServer.key}>
              <Stack direction="row">
                <TextField
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {canWrite && (
                          <IconButton
                            aria-label="Remove"
                            color="inherit"
                            disabled={isDisabledDueToRestrictions}
                            onClick={() => handleRemove(nameServer.key)}
                            size="small"
                          >
                            <FontAwesomeIcon icon={faTrashCan} />
                          </IconButton>
                        )}
                      </InputAdornment>
                    )
                  }}
                  disabled={isDisabledDueToRestrictions ? true : !canWrite}
                  error={nameServer.hasError}
                  fullWidth
                  helperText={nameServer.errorMessage}
                  label={`Name server ${index + 1}`}
                  onChange={event =>
                    handleChange(event.target.value, nameServer.key)
                  }
                  type="text"
                  value={nameServer.value}
                  variant="standard"
                />
              </Stack>
            </Stack>
          ))}
        </Stack>
        {canWrite && (
          <Stack
            direction={{ lg: 'row', xs: 'column' }}
            sx={{
              alignItems: 'flex-start',
              display: 'flex',
              flexWrap: 'wrap',
              gap: 2,
              mt: 2,
              width: '100%'
            }}
          >
            <Button
              color="secondary"
              disabled={
                isDisabledDueToRestrictions ? true : isLoadingOrFetching
              }
              onClick={handleAddMore}
              startIcon={<FontAwesomeIcon icon={faPlus} />}
              variant="contained"
            >
              Add more
            </Button>
            <LoadingButton
              disabled={isDisabledDueToRestrictions}
              loading={isLoadingOrFetching}
              sx={{ ml: 0, width: 134 }}
              type="submit"
              variant="contained"
            >
              Save
            </LoadingButton>
          </Stack>
        )}
      </form>
      <SignOffDialog
        handleClose={handleCloseSignOffDialog}
        handleSubmit={handleForceUpdate}
        isOpen={signOffDialogIsOpen}
      />
    </>
  );
};

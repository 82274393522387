import { LoadingButton } from '@mui/lab';
import React from 'react';
import { useSelector } from 'react-redux';

import { useAppInfoQuery } from '../../common/store/app-info-api-slice';
import { selectCustomerName } from '../../common/store/customers-slice';
import { selectReseller } from '../../common/store/reseller';
import { useGetRestrictionsForUserQuery } from '../../common/store/restricted-users-api-slice';
import { selectUserEmail } from '../auth/auth-slice';
import { useUpdateMaxAutoRenewForDomainsMutation } from './auto-renew-api-slice';
import {
  selectAllMaxRenew,
  selectSelectedDomains
} from './selected-domains-slice';

export const DomainDetailsRenewSetMaxAutoRenewForDomains = () => {
  const domains = useSelector(selectSelectedDomains);
  // const { allMaxRenew /*, anyMaxRenew*/ } = useSelector(selectState);
  const allMaxRenew = useSelector(selectAllMaxRenew);

  const reseller = useSelector(selectReseller);
  const customerName = useSelector(selectCustomerName);
  const [updateAutoRenew, { isLoading }] =
    useUpdateMaxAutoRenewForDomainsMutation();
  const { data: appInfoData } = useAppInfoQuery();

  const userEmail = useSelector(selectUserEmail);

  const { data: restrictions, isSuccess } = useGetRestrictionsForUserQuery({
    customerName,
    reseller,
    userEmail
  });
  const { hasAutoRenewRestriction } = restrictions ?? {};

  const handleMaxAutoRenewClick = ({ autoRenew }) =>
    updateAutoRenew({
      autoRenew,
      customerName,
      domains: domains.map(({ domainName }) => domainName),
      reseller
    });

  return isSuccess && domains.length > 1 && appInfoData?.maxAutorenewEnabled ? (
    <>
      {!allMaxRenew && (
        <LoadingButton
          disabled={hasAutoRenewRestriction}
          loading={isLoading}
          onClick={() => handleMaxAutoRenewClick({ autoRenew: true })}
          variant="outlined"
        >
          Max auto renew on
        </LoadingButton>
      )}
      {allMaxRenew && (
        <LoadingButton
          disabled={hasAutoRenewRestriction}
          loading={isLoading}
          onClick={() => handleMaxAutoRenewClick({ autoRenew: false })}
          variant="outlined"
        >
          Max auto renew off
        </LoadingButton>
      )}
    </>
  ) : null;
};

// if max auto renew is enabled:
// then if one domain is selected show a toggle to set max auto renew
// then if more than one domain is selected display two buttons to either ON or OFF max auto renew
// then display a notice about max auto renew
//   <div ng-if="Domains.maxAutorenewEnabled">
//     <div ng-show="Domains.domainsActions._perm.selectedMoreThan(1)" style="padding-top: 5px;">
//       <button
//         id="domains-max-auto-renew-on"
//         class="button primary"
//         ng-disabled="G.ThisUser.hasRestriction('AUTO_RENEW')"
//         ng-show="Domains.domainsActions._perm.oneHasProperty('maxAutorenew', false)"
//         ng-click="Domains.domainsActions.setMaxAutorenew(true)">
//         Max Autorenew on
//       </button>
//       <button
//         id="domains-max-auto-renew-off"
//         class="button primary"
//         ng-disabled="G.ThisUser.hasRestriction('AUTO_RENEW')"
//         ng-show="Domains.domainsActions._perm.oneHasProperty('maxAutorenew', true)"
//         ng-click="Domains.domainsActions.setMaxAutorenew(false)">
//         Max Autorenew off
//       </button>
//     </div>

import { Alert, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import { selectIsSuperAdmin } from '../auth/auth-slice';
import {
  selectAreAllDomainsManual,
  selectHaveSameExpirationDate,
  selectSelectedDomains
} from './selected-domains-slice';

export const DomainDetailsRenewExpirationDate = () => {
  const isSuperAdmin = useSelector(selectIsSuperAdmin);
  const selectedDomains = useSelector(selectSelectedDomains);
  const areAllManual = useSelector(selectAreAllDomainsManual);
  const haveSameExpirationDate = useSelector(selectHaveSameExpirationDate);

  return (
    <>
      {(!areAllManual || !isSuperAdmin) && (
        <>
          {haveSameExpirationDate && (
            <Typography variant="body1">
              Expiration Date: {selectedDomains[0].$expirationDateFormatted}
            </Typography>
          )}
          {!haveSameExpirationDate && (
            <Typography variant="body1">Multiple Dates Selected</Typography>
          )}
          {!areAllManual && (
            <Alert severity="info">
              You may not modify the expiration date of an automatic domain.
            </Alert>
          )}
        </>
      )}
    </>
  );
};

// If some are not manual OR the user is not a super admin:
// then if exp date is the same for all, show the expiration date in yyyy-MM-dd
// then if the domains do not have a matching expiration date, show "Multiple Dates Selected"
// then if any auto renew, show "You may not modify the expiration date of an automatic domain"

//
// <div ng-show="!Domains.domainsActions._perm.allManual() || !G.Auth.isSuperAdmin()">
//           <span ng-show="Domains.expirationDate"
//                 id="domains-auto-renew-expiration">{{ Domains.expirationDate | date:'yyyy-MM-dd' }}</span>
//           <span ng-show="!Domains.expirationDate"
//                 id="domains-auto-renew-expiration">Multiple Dates Selected</span> <br/>
//           <span class="text-muted" ng-show="!Domains.domainsActions._perm.allManual()"
//                 id="domains-auto-renew-expiration">You may not modify the expiration date of an automatic domain</span>
//           <br/>
//         </div>

import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '../store/store';
import { envDevelopment } from './env-development';
import { envProduction } from './env-production';
import { envProductionRegistrarSafe } from './env-production-registrar-safe';
import { envProductionRegistrarSec } from './env-production-registrar-sec';
import { envStaging } from './env-staging';
import { envTest } from './env-test';
import { envTest2 } from './env-test2';

type Config = {
  API_HOST: string;
  API_PATH_PREFIX: string;
  DEFAULT_TABLE_ROWS_PER_PAGE: number;
  DEFAULT_TABLE_ROWS_PER_PAGE_OPTIONS: number[];
  ENABLE_REDUX_DEV_TOOLS: boolean;
  ENV: string;
  LEGACY_HOST: string;
  LOGO_KEY: string;
};

let cfg: Config;

if (import.meta.env.MODE === 'test') {
  cfg = envDevelopment;
} else {
  switch (location.hostname) {
    // NOTE: these hostnames are also used in index.html
    // to limit which environments Google Analytics runs in
    case 'ui.registrar-prod.appdetex.com':
    case 'ui.registrar-prod.tracer.ai':
      cfg = envProduction;
      break;
    case 'r.registrarsafe.com':
      cfg = envProductionRegistrarSafe;
      break;
    case 'r.registrarsec.com':
      cfg = envProductionRegistrarSec;
      break;
    case 'ui.registrar-stage.appdetex.com':
    case 'ui.registrar-stage.tracer.ai':
      cfg = envStaging;
      break;
    case 'ui-1.registrar-test.appdetex.com':
    case 'ui-1.registrar-test.tracer.ai':
      cfg = envTest;
      break;
    case 'ui-2.registrar-test.appdetex.com':
    case 'ui-2.registrar-test.tracer.ai':
      cfg = envTest2;
      break;
    case 'localhost':
    case 'registrar.localdev':
    case 'registrar.internal':
      cfg = envDevelopment;
      break;
    default:
      throw new Error('Missing app config for this hostname.');
  }
}

export const configReducer = createSlice({
  initialState: cfg,
  name: 'config',
  reducers: {}
}).reducer;

export const config = cfg;

export const selectApiHost = (state: RootState): string =>
  state.config.API_HOST;
export const selectLegacyHost = (state: RootState): string =>
  state.config.LEGACY_HOST;
export const selectLogoKey = (state: RootState): string =>
  state.config.LOGO_KEY;

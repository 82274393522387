import { Stack, Typography } from '@mui/material';
import React from 'react';

import { POLLING_MAX_ITEMS_DISPLAYED } from '../../common/store/job-response-api-slice';

export const DomainDetailsOrderUpdatePendingMessage = ({
  maxDisplayed = POLLING_MAX_ITEMS_DISPLAYED,
  pendingDomainUpdates
}) => (
  <Stack>
    <Typography sx={{ mb: 2 }} variant="body2">
      The following domains are still waiting to update. Check back later or
      contact your account manager.
    </Typography>
    {pendingDomainUpdates?.slice(0, maxDisplayed)?.map(pending => (
      <Typography
        key={pending.domainName}
        sx={{ fontWeight: 600, mr: 1 }}
        variant="caption"
      >
        {pending.domainName}
      </Typography>
    ))}
    {pendingDomainUpdates?.length > maxDisplayed ? (
      <Typography variant="caption">
        + {pendingDomainUpdates.length - maxDisplayed} more
      </Typography>
    ) : null}
  </Stack>
);

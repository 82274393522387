import {
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Switch
} from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import { selectCustomerName } from '../../common/store/customers-slice';
import { selectReseller } from '../../common/store/reseller';
import { useGetRestrictionsForUserQuery } from '../../common/store/restricted-users-api-slice';
import { selectUserEmail } from '../auth/auth-slice';
import { useUpdateAutoRenewForDomainsMutation } from './auto-renew-api-slice';
import { selectSelectedDomains } from './selected-domains-slice';

export const DomainDetailsRenewToggleAutoRenewForDomain = () => {
  const reseller = useSelector(selectReseller);
  const customerName = useSelector(selectCustomerName);
  const userEmail = useSelector(selectUserEmail);
  const domains = useSelector(selectSelectedDomains);
  const [updateAutoRenew, { isLoading: isUpdateAutoRenewLoading }] =
    useUpdateAutoRenewForDomainsMutation();
  const { data: restrictions, isSuccess: isGetRestrictionsSuccess } =
    useGetRestrictionsForUserQuery({
      customerName,
      reseller,
      userEmail
    });
  const { hasAutoRenewRestriction } = restrictions ?? {};

  const handleAutoRenewChange = event =>
    updateAutoRenew({
      autoRenew: event.target.checked,
      customerName,
      domains: domains.map(({ domainName }) => domainName),
      reseller
    });

  return isGetRestrictionsSuccess && domains.length === 1 ? (
    <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
      <FormControlLabel
        control={
          <Switch
            checked={domains[0].autoBilling}
            disabled={hasAutoRenewRestriction}
            onChange={handleAutoRenewChange}
          />
        }
        label="Auto renewal"
      />
      {isUpdateAutoRenewLoading && <CircularProgress size={30} />}
    </FormGroup>
  ) : null;
};

// if one domain is selected:
// then display a toggle to control auto renew
//       <div ng-show="Domains.domainsActions._perm.selectedExactly(1)">
//   <adx-switch
//     input-id="domains-auto-renew-toggle"
//     input-checked="Domains.domainsActions.getDetails().get('autoBilling')"
//     input-label="Auto renewal"
//     input-disabled="G.ThisUser.hasRestriction('AUTO_RENEW')"
//     input-change="Domains.domainsActions.toggleAutoRenew()">
//   </adx-switch>
// </div>

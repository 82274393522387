import { boolean, object, string } from 'yup';

const defaultFieldSchema = handleRequiredValidation =>
  object({
    errorMessage: string().default(''),
    hasError: boolean().default(false),
    touched: boolean().default(false),
    value: string().required(handleRequiredValidation).default('')
  });

export const formSchema = ({ handleRequiredValidation }) =>
  object({
    message: defaultFieldSchema(handleRequiredValidation),
    subject: defaultFieldSchema(handleRequiredValidation)
  });

import { faCheck, faStar, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Stack, Tooltip, useTheme } from '@mui/material';
import React from 'react';

export const AutoRenewCell = ({ params }) => {
  const theme = useTheme();
  return (
    <Stack direction="row" spacing={1}>
      {params.value && (
        <Tooltip title="Auto-renew is enabled">
          <FontAwesomeIcon
            color={theme.palette.success.main}
            data-testid="fa-check"
            icon={faCheck}
          />
        </Tooltip>
      )}
      {!params.value && (
        <Tooltip title="Auto-renew is not enabled">
          <FontAwesomeIcon
            color={theme.palette.error.main}
            data-testid="fa-times"
            icon={faTimes}
          />
        </Tooltip>
      )}
      {params.row.$isMaxAutoRenew && (
        <Tooltip title="Max auto-renew is enabled">
          <FontAwesomeIcon color={theme.palette.success.main} icon={faStar} />
        </Tooltip>
      )}
    </Stack>
  );
};

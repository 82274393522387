import { Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import {
  selectAllLocalPresenceOffered,
  selectHaveSameLocalPresenceExpirationDate,
  selectIsAnySelected,
  selectIsMultipleSelected,
  selectSelectedDomains
} from './selected-domains-slice';

export const LocalPresenceExpirationDate = () => {
  const allLocalPresenceOffered = useSelector(selectAllLocalPresenceOffered);
  const haveSameLocalPresenceExpirationDate = useSelector(
    selectHaveSameLocalPresenceExpirationDate
  );
  const isAnySelected = useSelector(selectIsAnySelected);
  const isMultipleSelected = useSelector(selectIsMultipleSelected);
  const domains = useSelector(selectSelectedDomains);

  return (
    <>
      {allLocalPresenceOffered &&
        isAnySelected &&
        haveSameLocalPresenceExpirationDate && (
          <Typography variant="body1">
            Local Presence Expiration Date:{' '}
            {domains[0].$localPresenceExpirationDateFormatted}
          </Typography>
        )}

      {allLocalPresenceOffered &&
        isMultipleSelected &&
        !haveSameLocalPresenceExpirationDate && (
          <Typography variant="body1">
            Local Presence Expiration Date: Multiple Dates Selected
          </Typography>
        )}
    </>
  );
};

// if all selected domains/tlds have local presence offered
// then if they have the same expiration date, show it
// then if they have different expiration dates, show "Multiple Dates Selected"
// then if only one domain is selected show a toggle for local presence
// then if more than one domain is selected, show two buttons to enable/disable the local presence status

//   <div ng-if="Domains.domainsActions._perm.allHaveTldProperty('localPresenceOffered', true)">
//     <hr/>
//     <strong>Local presence expiration date:</strong>
//     <div>
//       <span
//         ng-if="Domains.localPresenceExpirationDate">{{ Domains.localPresenceExpiradtionDate | date:'yyyy-MM-dd'
//         }}</span>
//       <span ng-if="!Domains.localPresenceExpirationDate">Multiple Dates Selected</span> <br/>
//     </div>
//   </div>
// </div>

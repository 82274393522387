import { Box, Typography } from '@mui/material';
import React from 'react';

export const TableDetailsHeader = ({
  headerText,
  noneSelectedText,
  ...rest
}) => {
  return (
    <Box {...rest}>
      <Typography gutterBottom={true} variant="h5">
        {headerText}
      </Typography>
      {noneSelectedText && (
        <Typography sx={{ mb: 1 }} variant="body1">
          {noneSelectedText}
        </Typography>
      )}
    </Box>
  );
};

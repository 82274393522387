import { LoadingButton } from '@mui/lab';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import dayjs from 'dayjs';
import {
  bindDialog,
  bindToggle,
  bindTrigger,
  usePopupState
} from 'material-ui-popup-state/hooks';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { DomainServiceAgreementForm } from '../../common/components/domain-service-agreement/DomainServiceAgreementForm';
import { selectCustomerName } from '../../common/store/customers-slice';
import { selectReseller } from '../../common/store/reseller';
import { useGetRestrictionsForUserQuery } from '../../common/store/restricted-users-api-slice';
import { selectUserEmail } from '../auth/auth-slice';
import { DomainDetailsManualRenewWithConfirmButton } from './DomainDetailsManualRenewWithConfirmButton';
import { selectSelectedDomains } from './selected-domains-slice';

export const DomainDetailsManualRenewWithConfirm = ({ year }) => {
  const reseller = useSelector(selectReseller);
  const customerName = useSelector(selectCustomerName);
  const userEmail = useSelector(selectUserEmail);
  const selectedDomains = useSelector(selectSelectedDomains);
  const domainAgreementInitialState = Object.freeze({
    accepted: false,
    date: null
  });
  const [domainAgreement, setDomainAgreement] = useState(
    domainAgreementInitialState
  );

  const popupState = usePopupState({
    popupId: 'manualRenewConfirmDialog',
    variant: 'dialog'
  });

  useEffect(() => {
    // Reset domain agreement on dialog close
    if (popupState.isOpen === false) {
      setDomainAgreement(domainAgreementInitialState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popupState.isOpen]);

  const { data: restrictions, isLoading } = useGetRestrictionsForUserQuery({
    customerName,
    reseller,
    userEmail
  });
  const { hasManualRenewRestriction } = restrictions ?? {};

  const title = `Are you sure you want to renew the selected domain${
    selectedDomains.length > 1 ? 's' : ''
  } for ${year} year${year === 1 ? '' : 's'}?`;

  const handleDomainAgreementCheckboxClick = ({ target }) =>
    setDomainAgreement({
      accepted: target.checked,
      date: dayjs().toISOString()
    });

  return (
    <>
      <LoadingButton
        {...bindTrigger(popupState)}
        disabled={hasManualRenewRestriction}
        loading={isLoading}
      >
        {year === 1 ? '1 year' : `${year} years`}
      </LoadingButton>
      <Dialog {...bindDialog(popupState)}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DomainServiceAgreementForm
            checkboxComponent={
              <Checkbox
                checked={domainAgreement.accepted}
                onChange={handleDomainAgreementCheckboxClick}
              />
            }
          />
        </DialogContent>
        <DialogActions>
          <Button {...bindToggle(popupState)}>Cancel</Button>
          <DomainDetailsManualRenewWithConfirmButton
            domainAgreement={domainAgreement}
            popupState={popupState}
            year={year}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

import { Alert, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import { TableDetailsHeader } from '../../common/components/table-details-header/TableDetailsHeader';
import { selectCanWrite } from '../auth/auth-slice';
import { DomainDetailsLockUnlockMulti } from './DomainDetailsLockUnlockMulti';
import { DomainDetailsLockUnlockSingle } from './DomainDetailsLockUnlockSingle';
import {
  selectIsAnySelected,
  selectIsMultipleSelected,
  selectIsNoneSelected,
  selectIsOneSelected,
  selectSelectedDomains
} from './selected-domains-slice';
import { domainsTabs } from './tabs';

export const DomainDetailsLockUnlock = () => {
  const selectedDomains = useSelector(selectSelectedDomains);
  const canWrite = useSelector(selectCanWrite);
  const isNoneSelected = useSelector(selectIsNoneSelected);
  const isAnySelected = useSelector(selectIsAnySelected);
  const isMultipleSelected = useSelector(selectIsMultipleSelected);
  const isOneSelected = useSelector(selectIsOneSelected);

  if (!canWrite) {
    return null;
  }

  const oneSupportsAnyLock = selectedDomains.some(
    domain => domain.$registrarLocks.anyAvailable
  );

  return (
    <>
      {isNoneSelected && (
        <TableDetailsHeader
          headerText={domainsTabs.LOCK_UNLOCK.label}
          noneSelectedText={domainsTabs.LOCK_UNLOCK.noneSelectedMessage}
        />
      )}
      {isAnySelected && oneSupportsAnyLock && (
        <>
          {isOneSelected && <DomainDetailsLockUnlockSingle />}
          {isMultipleSelected && <DomainDetailsLockUnlockMulti />}
        </>
      )}
      {isAnySelected && !oneSupportsAnyLock && (
        <>
          <Typography variant="h5">Registrar Locks</Typography>
          <Alert icon={false} severity="warning" sx={{ marginTop: 2 }}>
            <Typography variant="body1">
              The selected domain name(s) don&apos;t support status updates.
            </Typography>
          </Alert>
        </>
      )}
    </>
  );
};

import React from 'react';
import { useSelector } from 'react-redux';

import { selectCanWrite } from '../../../features/auth/auth-slice';
import { TableDetailsHeader } from '../table-details-header/TableDetailsHeader';
import { NotesForm } from './NotesForm';
import { NotesList } from './NotesList';

export const Notes = ({ detailsTab, entityType, selectedDomains }) => {
  const canWrite = useSelector(selectCanWrite);
  const noneSelected = selectedDomains.length === 0;
  const oneSelected = selectedDomains.length === 1;
  const oneOrMoreSelected = selectedDomains.length > 0;
  const multipleSelected = selectedDomains.length > 1;

  return (
    <>
      <TableDetailsHeader
        headerText={detailsTab.label}
        noneSelectedText={
          // eslint-disable-next-line no-nested-ternary
          noneSelected
            ? detailsTab.noneSelectedMessage
            : multipleSelected
              ? detailsTab.multipleSelectedMessage
              : ''
        }
      />
      {canWrite && oneOrMoreSelected && (
        <NotesForm entityType={entityType} selectedDomains={selectedDomains} />
      )}
      {oneSelected && (
        <NotesList entityType={entityType} selectedDomains={selectedDomains} />
      )}
    </>
  );
};

import { Autocomplete, TextField } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import { useGetContactsQuery } from '../../store/contacts-api-slice';
import { selectCustomerName } from '../../store/customers-slice';
import { selectReseller } from '../../store/reseller';
import { selectDisplayedContactSetId } from './contact-sets-slice';

export const ContactSetsAutocomplete = ({
  contactSetId,
  disabled = false,
  handleApplyContactSet = null,
  handleSetDisplayedContactSetId = null
}) => {
  const reseller = useSelector(selectReseller);
  const customerName = useSelector(selectCustomerName);
  const displayedContactSetId = useSelector(selectDisplayedContactSetId);
  const isForDisplay = typeof handleSetDisplayedContactSetId === 'function';

  const {
    contactSetValueForContactSetId,
    contactSetValueForDisplayedContactSetId,
    data: contactSetsData = [],
    defaultContactSet,
    isLoading: isContactSetsLoading
  } = useGetContactsQuery(
    {
      customerName,
      reseller
    },
    {
      selectFromResult: ({ data, isLoading }) => ({
        contactSetValueForContactSetId: data?.find(
          contactSet => contactSet.id === contactSetId
        ),
        contactSetValueForDisplayedContactSetId: data?.find(
          contactSet => contactSet.id === displayedContactSetId
        ),
        data,
        defaultContactSet: data?.find(
          contactSet => contactSet.defaultSet === true
        ),
        isLoading
      }),
      skip: !customerName
    }
  );

  const getValueForContactDropdown = () => {
    if (contactSetId === -1 && !displayedContactSetId) {
      return 'Select Contact Set';
    } else {
      return isForDisplay
        ? contactSetValueForDisplayedContactSetId
        : contactSetValueForContactSetId;
    }
  };

  const handleChange = (event, { id }) => {
    if (handleApplyContactSet) {
      handleApplyContactSet(id);
    }
    if (handleSetDisplayedContactSetId) {
      handleSetDisplayedContactSetId(id);
    }
  };

  return (
    <Autocomplete
      disableClearable
      disabled={disabled}
      limitTags={1}
      loading={isContactSetsLoading}
      onChange={handleChange}
      options={contactSetsData}
      renderInput={params => <TextField {...params} variant="outlined" />}
      size="small"
      sx={{ width: '100%' }}
      value={
        getValueForContactDropdown() ||
        defaultContactSet ||
        'Select Contact Set'
      }
    />
  );
};

export const ContactSetsAutocompleteMemoized = React.memo(
  ContactSetsAutocomplete
);

export const noteEntityType = Object.freeze({
  domain: {
    label: 'domain',
    value: 'CUSTOMER_PRODUCT'
  },
  manualOrder: {
    label: 'manual order',
    value: 'MANUAL_ORDER'
  }
});

import React from 'react';
import { useSelector } from 'react-redux';

import { LabelsSelection } from '../../common/components/labels-selection/LabelsSelection';
import { TableDetailsHeader } from '../../common/components/table-details-header/TableDetailsHeader';
import { selectSelectedDomains } from './selected-domains-slice';
import { domainsTabs } from './tabs';

export const DomainDetailsDomainLabels = () => {
  const selectedDomains = useSelector(selectSelectedDomains);

  return (
    <>
      <TableDetailsHeader
        headerText={domainsTabs.LABELS.label}
        noneSelectedText={
          selectedDomains.length === 0
            ? domainsTabs.LABELS.noneSelectedMessage
            : null
        }
      />
      {selectedDomains?.length > 0 && (
        <LabelsSelection selectedDomains={selectedDomains} />
      )}
    </>
  );
};

import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AddIcon from '@mui/icons-material/Add';
import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { noop } from 'lodash-es';
import {
  bindDialog,
  bindTrigger,
  usePopupState
} from 'material-ui-popup-state/hooks';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';

import { useGetContactsQuery } from '../../common/store/contacts-api-slice';
import { selectCustomerName } from '../../common/store/customers-slice';
import { useImportDomainMutation } from '../../common/store/domains-api-slice';
import { selectReseller } from '../../common/store/reseller';
import { selectCanWrite, selectIsSuperAdmin } from '../auth/auth-slice';
import { importDomainSchema } from './schema';

const DomainImports = () => {
  const customerName = useSelector(selectCustomerName);
  const reseller = useSelector(selectReseller);
  const isSuperAdmin = useSelector(selectIsSuperAdmin);
  const canWrite = useSelector(selectCanWrite);
  const canAddExistingDomain = isSuperAdmin && canWrite;

  const importDomainDialogState = usePopupState({
    popupId: 'import-domain-dialog-state',
    variant: 'dialog'
  });

  const [
    importDomain,
    {
      error: domainImportError,
      isError: isDomainImportError,
      isLoading: isDomainImportLoading,
      isSuccess: isDomainImportSuccess
    }
  ] = useImportDomainMutation();

  const handleFormChange = (field, value, additionalProps = {}) => {
    // @ts-ignore
    importDomainFormDispatch({
      additionalProps,
      field,
      type: 'UPDATE_FORM',
      value
    });
  };

  const handleInvalidForm = (field, helperText) => {
    // @ts-ignore
    importDomainFormDispatch({
      field,
      helperText,
      type: 'INVALID_FORM'
    });
  };

  const emptyFormState = () =>
    importDomainSchema(handleInvalidForm).getDefault();

  const [importDomainForm, importDomainFormDispatch] = useReducer(
    (state, action) => {
      if (action.path) {
        action.path = action.path.split('.')[0];
      }
      switch (action.type) {
        case 'INIT':
          state = emptyFormState();
          state.contactSetId.value = action.contactSetId;
          break;
        case 'UPDATE_FORM':
          state[action.field] = {
            helperText: '',
            isError: false,
            value: action.value,
            ...action.additionalProps
          };
          break;
        case 'INVALID_FORM':
          state[action.field].isError = true;
          state[action.field].helperText = action.helperText;
          break;
        case 'RESET':
          const { domainName, registryCreationDate } = emptyFormState();
          state = { ...state, domainName, registryCreationDate };
      }
      return { ...state };
    },
    emptyFormState()
  );

  const {
    data: contacts = [],
    isError: isContactsFetchingError,
    isFetching: isContactsFetching,
    isSuccess: isContactsFetchingSuccess
  } = useGetContactsQuery(
    {
      customerName,
      reseller
    },
    {
      skip: !customerName
    }
  );

  useEffect(() => {
    if (
      isContactsFetchingSuccess &&
      importDomainForm.contactSetId.value.length === 0
    ) {
      const defaultSet = contacts?.filter(contact => contact.defaultSet);
      // @ts-ignore
      importDomainFormDispatch({
        contactSetId: defaultSet?.[0]?.id,
        type: 'INIT'
      });
    } else if (isContactsFetchingError) {
      enqueueSnackbar('There was an error getting the contact sets', {
        variant: 'error'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contacts.length, isContactsFetchingSuccess, isContactsFetchingError]);

  useEffect(() => {
    if (isDomainImportSuccess) {
      enqueueSnackbar(
        `The domain ${importDomainForm.domainName.value} was imported successfully`,
        { variant: 'success' }
      );
      importDomainDialogState.close();
    } else if (isDomainImportError) {
      enqueueSnackbar(
        `There was an error importing the domain ${
          importDomainForm.domainName.value
        }: ${domainImportError?.data?.message ?? ''}`,
        { variant: 'error' }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    domainImportError?.data?.message,
    isDomainImportError,
    isDomainImportLoading,
    isDomainImportSuccess
  ]);

  const handleImportDomain = async () => {
    const isValidDate =
      importDomainForm.registryCreationDate.value?.length === 0 ||
      dayjs(importDomainForm.registryCreationDate.value).isValid();
    const requestBody = {
      contactSetId: parseInt(importDomainForm.contactSetId.value)
    };
    if (isValidDate && importDomainForm.registryCreationDate.value.length > 0) {
      requestBody.creationDateOverride = dayjs(
        importDomainForm.registryCreationDate.value
      ).toISOString();
    }

    await importDomainSchema(handleInvalidForm)
      .validate(importDomainForm, {
        abortEarly: false
      })
      .then(() => {
        importDomain({
          body: requestBody,
          customerName,
          domainName: importDomainForm.domainName.value,
          reseller
        });
      })
      .catch(noop);
  };

  const getValueForContactSetDropdown = () => {
    return importDomainForm.contactSetId.value !== ''
      ? contacts.find(
          contact => contact.id === importDomainForm.contactSetId.value
        )?.name
      : '';
  };

  const handleCancelImport = () => {
    importDomainDialogState.close();
    // @ts-ignore
    importDomainFormDispatch({
      type: 'RESET'
    });
  };

  return (
    <>
      {canAddExistingDomain && (
        <Button
          {...bindTrigger(importDomainDialogState)}
          startIcon={<AddIcon />}
        >
          Add existing domain
        </Button>
      )}
      <Dialog
        {...bindDialog(importDomainDialogState)}
        onClose={handleCancelImport}
      >
        <DialogTitle>Import Domain</DialogTitle>
        <DialogContent>
          <Typography sx={{ mb: 3 }} variant="body1">
            This will import a domain that is already owned by this registrar
            into the system.
          </Typography>
          <Stack direction="column" spacing={3}>
            <TextField
              error={importDomainForm.domainName.isError}
              helperText={importDomainForm.domainName.helperText}
              label="Domain Name"
              onChange={event => {
                handleFormChange(
                  'domainName',
                  event.target.value,
                  event.target.value?.length > 0
                    ? { isImportBtnDisabled: false }
                    : { isImportBtnDisabled: true }
                );
              }}
              value={importDomainForm.domainName.value}
            />
            <Autocomplete
              data-testid="contactSetAutocomplete"
              disableClearable
              loading={isContactsFetching}
              onChange={(event, value) =>
                handleFormChange('contactSetId', value.id)
              }
              options={contacts}
              renderInput={params => (
                <TextField
                  {...params}
                  error={importDomainForm.contactSetId.isError}
                  helperText={importDomainForm.contactSetId.helperText}
                  label="Contact Set"
                />
              )}
              value={getValueForContactSetDropdown()}
            />
            <Stack direction="row" spacing={1}>
              <DatePicker
                label="Registry Creation Date"
                onChange={event =>
                  handleFormChange(
                    'registryCreationDate',
                    event?.toString() ?? '',
                    { touched: true }
                  )
                }
                slotProps={{
                  popper: {
                    placement: 'bottom-start',
                    sx: {
                      bottom: '50px',
                      position: 'relative'
                    }
                  },
                  // @ts-ignore
                  textField: {
                    error: importDomainForm.registryCreationDate.isError,
                    helperText: importDomainForm.registryCreationDate.helperText
                  }
                }}
                sx={{ width: '100%' }}
                value={
                  importDomainForm.registryCreationDate.touched
                    ? dayjs(importDomainForm.registryCreationDate.value)
                    : null
                }
              />
              <Box sx={{ position: 'relative', right: '0px', top: '8px' }}>
                <Tooltip
                  placement="top"
                  title="If the registry for this domain doesn’t send the creation date back in their domain:info response, you can specify it here. Otherwise, leaving this field blank will use the date the registry provides."
                >
                  <FontAwesomeIcon
                    color="var(--action-active)"
                    icon={faInfoCircle}
                    size="xl"
                  />
                </Tooltip>
              </Box>
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelImport}>Cancel</Button>
          <LoadingButton
            disabled={importDomainForm.domainName.isImportBtnDisabled}
            loading={isDomainImportLoading}
            onClick={handleImportDomain}
            variant="contained"
          >
            Import
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default DomainImports;

export const gridHandleOnPaginationModelChange = ({
  dir,
  gridBasePath,
  navigate,
  page,
  pageSize,
  sort
}) => {
  const sortSearchParams =
    sort && dir
      ? new URLSearchParams({
          dir,
          sort
        }).toString()
      : '';
  return navigate(
    `${gridBasePath}/page/${page}/page-size/${pageSize}?${sortSearchParams}`
  );
};

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import PopupState from 'material-ui-popup-state';
import {
  bindDialog,
  bindTrigger,
  usePopupState
} from 'material-ui-popup-state/hooks';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { DomainDetailsDeleteConfirmButton } from './DomainDetailsDeleteConfirmButton';
import { selectSelectedDomains } from './selected-domains-slice';

export const DomainDetailsDeleteDialog = () => {
  const popupState: ReturnType<typeof PopupState> = usePopupState({
    popupId: 'domainDeleteDialog',
    variant: 'dialog'
  });

  const [showDaysUntilExpiration, setShowDaysUntilExpiration] = useState(false);

  const selectedDomains = useSelector(selectSelectedDomains);

  useEffect(() => {
    if (selectedDomains && selectedDomains.length === 1) {
      setShowDaysUntilExpiration(true);
    }
  }, [selectedDomains]);

  const daysRemaining = () => {
    const expirationDate = new Date(selectedDomains[0].expirationDate);
    const today = new Date();
    const timeDifference = expirationDate.getTime() - today.getTime();
    const daysDifference = timeDifference / (1000 * 3600 * 24);

    const days = Math.round(daysDifference);

    if (days < 0) {
      setShowDaysUntilExpiration(false);

      return '';
    } else if (days === 1) {
      return `${days} day`;
    } else {
      return `${days} days`;
    }
  };

  const canDomainBeDeleted = () => {
    const isOneDomainSelected = selectedDomains.length === 1;
    const isDeleteLockDisabled = !selectedDomains[0].$deleteLockEnabled;
    const isGTld = selectedDomains[0].$tld.category.toLowerCase() === 'gtld';
    const isAutoRenewDisabled = !selectedDomains[0].autoBilling;

    return (
      isOneDomainSelected &&
      isDeleteLockDisabled &&
      isGTld &&
      isAutoRenewDisabled
    );
  };

  return (
    <>
      <Button
        disabled={!canDomainBeDeleted()}
        size="small"
        {...bindTrigger(popupState)}
        variant="contained"
      >
        Delete
      </Button>
      <Dialog {...bindDialog(popupState)} maxWidth="xs">
        <DialogTitle>Delete {selectedDomains[0].domainName}</DialogTitle>
        <DialogContent>
          {showDaysUntilExpiration &&
            `${selectedDomains[0].domainName} has ${daysRemaining()} until expiration. Are you sure you want to delete it today?`}
          {!showDaysUntilExpiration &&
            'Are you sure you want to delete the selected domain?'}
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <Button onClick={() => popupState.close()} variant="outlined">
            Cancel
          </Button>
          <DomainDetailsDeleteConfirmButton popupState={popupState} />
        </DialogActions>
      </Dialog>
    </>
  );
};

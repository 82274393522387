import { LoadingButton } from '@mui/lab';
import { Alert, Stack, TextField, Typography } from '@mui/material';
import { noop } from 'lodash-es';
import React, { useEffect, useReducer, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';

import { selectCustomerName } from '../../store/customers-slice';
import { useAddNoteMutation } from '../../store/notes-api-slice';
import { selectReseller } from '../../store/reseller';
import { formSchema } from './domain-details-notes-form-schema';

export const NotesForm = ({ entityType, selectedDomains }) => {
  const [validationErrors, setValidationErrors] = useState(false);
  const reseller = useSelector(selectReseller);
  const customerName = useSelector(selectCustomerName);
  const fileInputRef = useRef();

  const [addNote, { data, isError, isLoading, isSuccess }] =
    useAddNoteMutation();

  const handleRequiredValidation = ({ path }) =>
    formDataDispatch({
      errorMessage: 'This field is required',
      forceTouch: false,
      path,
      type: 'SET_ERROR'
    });
  const handleRequiredValidationOnSubmit = ({ path }) =>
    formDataDispatch({
      errorMessage: 'This field is required.',
      forceTouch: true,
      path,
      type: 'SET_ERROR'
    });

  const emptyFormState = () =>
    formSchema({ handleRequiredValidation }).getDefault();

  const getBasePathFromYupPath = path => path.split('.')[0];

  const [formData, formDataDispatch] = useReducer(
    (state, { errorMessage, forceTouch, path = '', type, value }) => {
      path = getBasePathFromYupPath(path);

      switch (type) {
        case 'INIT':
          state = emptyFormState();
          fileInputRef.current.value = '';
          break;
        case 'UPDATE':
          state[path].value = value;
          state[path].touched = true;
          state[path].errorMessage = '';
          state[path].hasError = false;
          break;
        case 'SET_ERROR':
          if (state[path].touched || forceTouch) {
            state[path].hasError = true;
            state[path].errorMessage = errorMessage;
          }
          break;
        default:
          break;
      }

      return { ...state };
    },
    emptyFormState()
  );

  useEffect(() => {
    if (isSuccess) {
      formDataDispatch({
        type: 'INIT'
      });
    }
  }, [isSuccess, data]);

  const handleSubmit = e => {
    e.preventDefault();

    setValidationErrors(false);

    const params = {
      customerName,
      entityIds: selectedDomains.map(({ id }) => id),
      reseller
    };

    formSchema({ handleRequiredValidation: handleRequiredValidationOnSubmit })
      .validate(formData)
      .then(() => {
        setValidationErrors(false);
      })
      .then(() => {
        const userDataFromForm = Object.keys(formData).reduce(
          (reducingUserData, formDataKey) => {
            reducingUserData[formDataKey] = formData[formDataKey].value;
            return reducingUserData;
          },
          {}
        );
        addNote({
          ...params,
          ...userDataFromForm,
          files: fileInputRef.current.files,
          noteEntityType: entityType.value
        });
      })
      .catch(e => {
        window.console.error(e);
        setValidationErrors(true);
      });
  };

  const validateOnChange = (formSchema, formData) =>
    formSchema({
      handleRequiredValidation
    })
      .validate(formData)
      .catch(noop);

  const debouncedValidation = useDebouncedCallback(validateOnChange, 1500);

  const handleChange = (event, path, isImmediate) => {
    setValidationErrors(false);
    formDataDispatch({
      path,
      type: 'UPDATE',
      value: event.target.value
    });

    isImmediate
      ? validateOnChange(formSchema, formData)
      : debouncedValidation(formSchema, formData);
  };
  return (
    <form noValidate onSubmit={handleSubmit}>
      <Stack spacing={3} sx={{ mb: 4 }}>
        {validationErrors && (
          <Alert severity="error">Fix form errors before submitting.</Alert>
        )}
        {isError && (
          <Alert severity="error">
            An error occurred while creating your note. Try again.
          </Alert>
        )}
        <Typography sx={{ fontWeight: 'bold' }} variant="body1">
          Add Note
        </Typography>
        <TextField
          error={formData.subject.hasError}
          helperText={formData.subject.errorMessage}
          label="Subject"
          onBlur={event => handleChange(event, 'subject', true)}
          onChange={event => handleChange(event, 'subject')}
          value={formData.subject.value}
        />
        <TextField
          error={formData.message.hasError}
          helperText={formData.message.errorMessage}
          label="Message"
          multiline
          onBlur={event => handleChange(event, 'message', true)}
          onChange={event => handleChange(event, 'message')}
          value={formData.message.value}
        />
        <input
          accept=".pdf, .png, .jpeg, .img, .csv, .doc, .docx, .xls, .xlsx, .jpg, .JPG, .PDF, .PNG, .JPEG, .IMG, .CSV, .DOC, .DOCX, .XLS, .XLSX"
          multiple
          ref={fileInputRef}
          type="file"
        />
        <LoadingButton
          disabled={validationErrors}
          loading={isLoading}
          type="submit"
          variant="contained"
        >
          Save
        </LoadingButton>
      </Stack>
    </form>
  );
};

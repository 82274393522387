import { LoadingButton } from '@mui/lab';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Stack,
  Typography
} from '@mui/material';
import dayjs from 'dayjs';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectDisplayedContactSetId } from '../../common/components/contact-sets/contact-sets-slice';
import { CopyToClipboard } from '../../common/components/copy-to-clipboard/CopyToClipboard';
import { SignOffDialog } from '../../common/components/sign-offs/SignOffDialog';
import { SnackbarCloseAction } from '../../common/components/snackbar-close-action/SnackbarCloseAction';
import { CUSTOMER_TAG, apiSlice } from '../../common/store/api-slice';
import { useAppInfoQuery } from '../../common/store/app-info-api-slice';
import { selectCustomerName } from '../../common/store/customers-slice';
import {
  LINE_ITEM_STATUSES,
  POLLING_INTERVAL,
  POLLING_MAX_REQUESTS,
  usePollJobResponseQuery
} from '../../common/store/job-response-api-slice';
import { useCreateOrderMutation } from '../../common/store/orders-api-slice';
import { selectReseller } from '../../common/store/reseller';
import { generateId } from '../../common/utils/generate-id';
import { selectClientId } from '../auth/auth-slice';
import { DomainDetailsOrderUpdateErrorMessage } from './DomainDetailsOrderUpdateErrorMessage';
import { DomainDetailsOrderUpdatePendingMessage } from './DomainDetailsOrderUpdatePendingMessage';
import { selectSelectedDomains } from './selected-domains-slice';

export const RegistrantChangedDialog = ({
  handleClose,
  isOpen,
  parentDialogState
}) => {
  const reseller = useSelector(selectReseller);
  const customerName = useSelector(selectCustomerName);
  const clientId = useSelector(selectClientId);
  const displayedContactSetId = useSelector(selectDisplayedContactSetId);
  const dispatch = useDispatch();
  const selectedDomains = useSelector(selectSelectedDomains);
  const [lineItemsToUpdate, setLineItemsToUpdate] = useState([]);
  const [isSignOffDialogOpen, setIsSignOffDialogOpen] = useState(false);
  const pollingCount = useRef(0);
  const [createOrder, { data: createOrderData, isError: isCreateOrderError }] =
    useCreateOrderMutation();
  const [checked, setChecked] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { data: appInfoData } = useAppInfoQuery();

  const shouldSkipPolling =
    createOrderData?.signOffRequired !== false ||
    !clientId ||
    !lineItemsToUpdate.length;

  const { data: jobResultData, refetch } = usePollJobResponseQuery(
    {
      clientId,
      clientLineItemIds: lineItemsToUpdate.map(
        lineItem => lineItem.clientLineItemId
      )
    },
    { skip: shouldSkipPolling }
  );

  const handleCloseSignOffDialog = () => {
    setIsSignOffDialogOpen(false);
    setLineItemsToUpdate([]);
  };

  useEffect(() => {
    // sign off required - no sign off pending
    if (
      createOrderData?.signOffRequired &&
      !createOrderData?.forceUpdateRequired
    ) {
      enqueueSnackbar('Your request is now pending Sign-Off', {
        persist: false,
        variant: 'info'
      });
      setLineItemsToUpdate([]);
      setIsSubmitting(false);
      handleCloseClick();
    }
    // sign off required - sign off pending
    else if (
      createOrderData?.signOffRequired &&
      createOrderData?.forceUpdateRequired
    ) {
      setIsSignOffDialogOpen(true);
      setIsSubmitting(false);
      handleCloseClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createOrderData]);

  const handleForceUpdate = () => {
    createOrder({
      clientId,
      customerName,
      lineItems: lineItemsToUpdate,
      orderOptions: { paymentType: 'NONE' },
      reseller,
      signOffForceUpdate: true
    });
    setIsSignOffDialogOpen(false);
    setLineItemsToUpdate([]);
  };

  useEffect(() => {
    if (!jobResultData) {
      return;
    }

    pollingCount.current++;

    const reachedMaxPollingRequests =
      pollingCount.current >= POLLING_MAX_REQUESTS;
    const donePolling = reachedMaxPollingRequests || jobResultData.allComplete;

    if (!donePolling && jobResultData.anyPending) {
      setTimeout(refetch, POLLING_INTERVAL.active);
    }

    if (donePolling) {
      setIsSubmitting(false);
      dispatch(apiSlice.util.invalidateTags([CUSTOMER_TAG]));
      pollingCount.current = 0;
      handleCloseClick();

      if (jobResultData.allSuccess) {
        enqueueSnackbar(
          <Typography sx={{ pr: 4 }} variant="body2">
            Successfully updated the contact set for the selected domain(s).
          </Typography>,
          {
            variant: 'success'
          }
        );
        parentDialogState.close();
      }

      if (jobResultData.anyFailures) {
        const failedDomainUpdates = jobResultData.messages
          .filter(message => message.status === LINE_ITEM_STATUSES.failure)
          .map(message => ({
            ...message,
            domainName: lineItemsToUpdate.find(
              lineItem => lineItem.clientLineItemId === message.clientLineItemId
            )?.domain
          }));

        enqueueSnackbar(
          <DomainDetailsOrderUpdateErrorMessage
            failedDomainUpdates={failedDomainUpdates}
          />,
          {
            action: snackbarId => (
              <Stack direction="row" spacing={1}>
                <CopyToClipboard
                  sx={{
                    borderColor: '#fff !important',
                    color: '#fff !important'
                  }}
                  textToCopy={failedDomainUpdates
                    .map(({ domainName }) => domainName)
                    .toString()}
                  variant="outlined"
                />
                <SnackbarCloseAction snackbarId={snackbarId} />
              </Stack>
            ),
            persist: true,
            variant: 'error'
          }
        );
      }

      if (jobResultData.anyPending) {
        const pendingDomainUpdates = jobResultData.messages
          .filter(message => message.status === LINE_ITEM_STATUSES.pending)
          .map(message => ({
            ...message,
            domainName: lineItemsToUpdate.find(
              lineItem => lineItem.clientLineItemId === message.clientLineItemId
            )?.domain
          }));
        enqueueSnackbar(
          <DomainDetailsOrderUpdatePendingMessage
            pendingDomainUpdates={pendingDomainUpdates}
          />,
          {
            action: snackbarId => (
              <Stack direction="row" spacing={1}>
                <CopyToClipboard
                  sx={{
                    borderColor: '#fff !important',
                    color: '#fff !important'
                  }}
                  textToCopy={pendingDomainUpdates
                    .map(({ domainName }) => domainName)
                    .toString()}
                  variant="outlined"
                />
                <SnackbarCloseAction snackbarId={snackbarId} />
              </Stack>
            ),
            persist: true,
            variant: 'warning'
          }
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobResultData]);

  useEffect(() => {
    if (isCreateOrderError) {
      setIsSubmitting(false);
      enqueueSnackbar(
        <Typography sx={{ pr: 4 }} variant="body2">
          There was an error updating the contact set.
        </Typography>,
        {
          variant: 'error'
        }
      );
      setLineItemsToUpdate([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreateOrderError]);

  const handleApplyContactSet = () => {
    setIsSubmitting(true);
    const lineItems = selectedDomains.map((domain, lineItemNum) => ({
      agreementDate: dayjs().toISOString(),
      agreementTerms: appInfoData?.registrantSixtyDayHoldOptOutAgreementText,
      clientLineItemId: generateId(),
      customerContactSetId: displayedContactSetId,
      domain: domain.domainName,
      lineItemNum,
      lineItemType: 'UPDATE_DOMAIN_CONTACTS'
    }));
    const orderOptions = {
      currency: 'USD',
      paymentType: 'NONE'
    };

    setLineItemsToUpdate(lineItems);
    createOrder({
      clientId,
      customerName,
      lineItems,
      orderOptions,
      reseller
    });
  };

  const handleCloseClick = () => {
    handleClose();
    setChecked(false);
  };

  return (
    <>
      <Dialog
        aria-describedby="dialog-registrant-changed"
        keepMounted
        onClose={handleCloseClick}
        open={isOpen}
      >
        <DialogTitle>Confirm Changes</DialogTitle>
        <DialogContent sx={{ px: 2.5, py: 0 }}>
          <DialogContentText
            id="registrant-dialog-description"
            sx={{ fontStyle: 'italic', pb: 1 }}
          >
            {appInfoData?.registrantSixtyDayHoldOptOutAgreementText}
          </DialogContentText>
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                data-testid="checkbox"
                onChange={() => setChecked(!checked)}
              />
            }
            disabled={isSubmitting}
            label="By checking this box you agree to the statement above"
          />
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Stack direction="row" justifyContent="end" spacing={2}>
            <Button
              disabled={isSubmitting}
              onClick={handleCloseClick}
              variant="outlined"
            >
              Disagree
            </Button>
            <LoadingButton
              disabled={!checked || isSubmitting}
              loading={isSubmitting}
              onClick={handleApplyContactSet}
              variant="contained"
            >
              Agree
            </LoadingButton>
          </Stack>
        </DialogActions>
        <SignOffDialog
          handleClose={handleCloseSignOffDialog}
          handleSubmit={handleForceUpdate}
          isOpen={isSignOffDialogOpen}
        />
      </Dialog>
    </>
  );
};

import { config } from '../../config/config';

export const downloadFileFromArrayBuffer = async ({
  accessToken,
  customerName,
  downloadKey,
  filename,
  noteId,
  reseller
}) => {
  const url = `${config.API_HOST}${config.API_PATH_PREFIX}resellers/${reseller}/customers/${customerName}/notes/download-attachment/${noteId}/${downloadKey}`;

  const headers = new Headers({
    authorization: `Bearer ${accessToken}`,
    responseType: 'arrayBuffer'
  });

  return fetch(url, { headers })
    .then(response => {
      if (!response.ok) {
        throw new Error(JSON.stringify(response));
      }

      return response.blob();
    })
    .then(response => {
      const anchor = document.createElement('a');
      anchor.style = 'display: none';
      anchor.href = URL.createObjectURL(
        new Blob([response], {
          type: 'application/octet-stream'
        })
      );
      anchor.download = filename;

      const clickEvent = new MouseEvent('click', {
        bubbles: true,
        cancelable: false,
        view: window
      });

      anchor.dispatchEvent(clickEvent);
    });
};

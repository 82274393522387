import { Button } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectDisplayedContactSetHasChanged,
  setDisplayedContactSetId
} from '../../common/components/contact-sets/contact-sets-slice';
import { selectCustomerName } from '../../common/store/customers-slice';
import { selectReseller } from '../../common/store/reseller';
import { useGetRestrictionsForUserQuery } from '../../common/store/restricted-users-api-slice';
import { selectCanWrite, selectUserEmail } from '../auth/auth-slice';
import { RegistrantChangedDialog } from './RegistrantChangedDialog';

export const ContactSetsActions = ({ bindToggle, dialogState }) => {
  const dispatch = useDispatch();
  const reseller = useSelector(selectReseller);
  const customerName = useSelector(selectCustomerName);
  const userEmail = useSelector(selectUserEmail);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const displayedContactSetHasChanged = useSelector(
    selectDisplayedContactSetHasChanged
  );
  const canWrite = useSelector(selectCanWrite);

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleSubmitClick = () => {
    setIsDialogOpen(true);
  };

  const handleCloseClick = () => {
    dispatch(setDisplayedContactSetId(null));
    dialogState.close();
  };

  const { data: restrictions, isFetching: isRestrictionsFetching } =
    useGetRestrictionsForUserQuery({
      customerName,
      reseller,
      userEmail
    });
  const { hasContactSetUpdatesRestriction } = restrictions ?? {
    hasContactSetUpdatesRestriction: false
  };

  const isApplyBtnDisabled =
    !displayedContactSetHasChanged ||
    isRestrictionsFetching ||
    hasContactSetUpdatesRestriction;

  return (
    <>
      <Button onClick={handleCloseClick}>Cancel</Button>
      {canWrite && (
        <Button
          {...bindToggle(dialogState)}
          disabled={isApplyBtnDisabled}
          onClick={handleSubmitClick}
          variant="contained"
        >
          Apply
        </Button>
      )}
      <RegistrantChangedDialog
        handleClose={handleCloseDialog}
        isOpen={isDialogOpen}
        parentDialogState={dialogState}
      />
    </>
  );
};

import { faPlus, faTrashCan } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CopyToClipboard } from '../../common/components/copy-to-clipboard/CopyToClipboard';
import { SnackbarCloseAction } from '../../common/components/snackbar-close-action/SnackbarCloseAction';
import {
  CUSTOMER_TAG,
  HOST_NAME_SERVER_TAG,
  apiSlice
} from '../../common/store/api-slice';
import { selectCustomerName } from '../../common/store/customers-slice';
import { useGetCustomerDomainQuery } from '../../common/store/domains-api-slice';
import {
  LINE_ITEM_STATUSES,
  POLLING_INTERVAL,
  POLLING_MAX_REQUESTS,
  usePollJobResponseQuery
} from '../../common/store/job-response-api-slice';
import { useCreateOrderMutation } from '../../common/store/orders-api-slice';
import { selectReseller } from '../../common/store/reseller';
import { useGetRestrictionsForUserQuery } from '../../common/store/restricted-users-api-slice';
import { generateId } from '../../common/utils/generate-id';
import {
  selectCanWrite,
  selectClientId,
  selectUserEmail
} from '../auth/auth-slice';
import styles from './DomainDetailsDnssec.module.css';
import { DomainDetailsOrderUpdateErrorMessage } from './DomainDetailsOrderUpdateErrorMessage';
import { DomainDetailsOrderUpdatePendingMessage } from './DomainDetailsOrderUpdatePendingMessage';
import { hostNameServerReducer } from './host-name-server-reducer';
import { formSchema } from './host-name-server-schema';
import { selectSelectedDomains } from './selected-domains-slice';

// eslint-disable-next-line complexity
export const HostNameServers = () => {
  const dispatch = useDispatch();
  const reseller = useSelector(selectReseller);
  const clientId = useSelector(selectClientId);
  const canWrite = useSelector(selectCanWrite);
  const [lineItemsToUpdate, setLineItemsToUpdate] = useState([]);
  const customerName = useSelector(selectCustomerName);
  const userEmail = useSelector(selectUserEmail);
  const [validationErrors, setValidationErrors] = useState(false);
  const selectedDomains = useSelector(selectSelectedDomains);
  const [refetchCount, setRefetchCount] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const [createOrder, { isError: isCreateOrderError, isLoading }] =
    useCreateOrderMutation();

  const isLoadingOrFetching = isLoading || lineItemsToUpdate?.length > 0;

  const { data: jobResultData, refetch } = usePollJobResponseQuery(
    {
      clientId,
      clientLineItemIds: lineItemsToUpdate.map(
        lineItem => lineItem.clientLineItemId
      )
    },
    { skip: !clientId || !lineItemsToUpdate.length }
  );

  const { data: restrictions, isFetching: isUserRestrictionsFetching } =
    useGetRestrictionsForUserQuery({
      customerName,
      reseller,
      userEmail
    });
  const { hasHostManagementRestriction } = restrictions ?? {};

  const isDisabledDueToRestrictions =
    isUserRestrictionsFetching || hasHostManagementRestriction;

  const handleHostNameStringValidation = params => {
    // @ts-ignore
    formDataDispatch({
      errorMessage: 'Host is not valid.',
      forceTouch: false,
      path: params.path,
      type: 'SET_ERROR'
    });
  };

  const handleIpAddressStringValidation = params => {
    // @ts-ignore
    formDataDispatch({
      errorMessage:
        'IP address is not valid. Examples: 44.73.182.162, 1d67:d71:56d9:1f3:5499:25b:cc84:f7e4',
      forceTouch: false,
      path: params.path,
      type: 'SET_ERROR'
    });
  };

  const handleRequiredValidation = ({ path }) =>
    // @ts-ignore
    formDataDispatch({
      errorMessage: 'This field is required',
      path,
      type: 'SET_ERROR'
    });

  const { data: domain } = useGetCustomerDomainQuery(
    {
      customerName,
      domainName: selectedDomains[0].domainName,
      reseller
    },
    { skip: !customerName }
  );

  const appliedNameServers = domain?.$hostNameServers;

  const [formData, formDataDispatch] = useReducer(hostNameServerReducer, []);

  useEffect(() => {
    if (appliedNameServers?.length) {
      // @ts-ignore
      formDataDispatch({
        handleHostNameStringValidation,
        handleIpAddressStringValidation,
        handleRequiredValidation,
        type: 'INIT',
        value: appliedNameServers
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appliedNameServers]);

  const handleChange = (event, id, field) => {
    setValidationErrors(false);
    // @ts-ignore
    formDataDispatch({
      field,
      id,
      type: 'UPDATE',
      value: event.target.value
    });
  };
  const handleAddMore = () =>
    // @ts-ignore
    formDataDispatch({
      type: 'ADD_FIELD'
    });

  // @ts-ignore
  const handleRemove = id => formDataDispatch({ id, type: 'REMOVE_FIELD' });

  const getLineItem = ({ action, host, ipAddress }) => ({
    action,
    clientLineItemId: generateId(),
    domain: domain.domainName,
    host,
    ipAddresses: [ipAddress],
    lineItemType: 'DOMAIN_HOST_ACTIONS'
  });

  const getHostLineItems = () => {
    return formData
      .flatMap(({ host, id, ipAddress, isDeleted, isNewRecord, touched }) => {
        if (!isNewRecord && isDeleted) {
          return getLineItem({
            action: 'DELETE',
            host: host.value,
            ipAddress: ipAddress.value
          });
        } else if (!isNewRecord && touched) {
          const recordToDelete = appliedNameServers?.find(
            nameServer => nameServer.id === id
          );
          return [
            getLineItem({
              action: 'DELETE',
              host: recordToDelete.host,
              ipAddress: recordToDelete.ipAddress
            }),
            getLineItem({
              action: 'SAVE',
              host: host.value,
              ipAddress: ipAddress.value
            })
          ];
        } else if (isNewRecord) {
          return getLineItem({
            action: 'SAVE',
            host: host.value,
            ipAddress: ipAddress.value
          });
        }
      })
      .filter(hostNameServer => hostNameServer !== undefined)
      .map((hostNameServer, lineItemNum) => ({
        ...hostNameServer,
        lineItemNum
      }));
  };

  const handleSubmit = event => {
    event.preventDefault();
    // @ts-ignore
    formSchema({
      handleHostNameStringValidation,
      handleIpAddressStringValidation,
      handleRequiredValidation
    })
      .validate(formData)
      .then(() => {
        setValidationErrors(false);
      })
      .then(() => {
        const lineItems = getHostLineItems();
        const orderOptions = {
          currency: 'USD',
          paymentType: 'NONE'
        };
        setLineItemsToUpdate(lineItems);
        createOrder({
          clientId,
          customerName,
          lineItems,
          orderOptions,
          reseller
        });
      })
      .catch(e => {
        window.console.error(e);
        setValidationErrors(true);
      });
  };

  const failedDomainUpdates = jobResultData?.messages
    .filter(message => message.status === LINE_ITEM_STATUSES.failure)
    .map(message => ({
      ...message,
      domainName: lineItemsToUpdate.find(
        lineItem => lineItem.clientLineItemId === message.clientLineItemId
      )?.domain
    }));

  const pendingDomainUpdates = jobResultData?.messages
    .filter(message => message.status === LINE_ITEM_STATUSES.pending)
    .map(message => ({
      ...message,
      domainName: lineItemsToUpdate.find(
        lineItem => lineItem.clientLineItemId === message.clientLineItemId
      )?.domain
    }));

  const haveUpdatesThatHaveBeenPolled =
    lineItemsToUpdate.length &&
    jobResultData?.messages.some(
      message =>
        message.clientLineItemId === lineItemsToUpdate[0].clientLineItemId
    );

  // Handles job polling
  useEffect(() => {
    if (jobResultData?.anyPending && refetchCount < POLLING_MAX_REQUESTS) {
      setTimeout(refetch, POLLING_INTERVAL.active);
      setRefetchCount(refetchCount + 1);
    } else if (refetchCount >= POLLING_MAX_REQUESTS) {
      enqueueSnackbar(
        <Stack spacing={1}>
          <DomainDetailsOrderUpdatePendingMessage
            pendingDomainUpdates={pendingDomainUpdates}
          />
          {failedDomainUpdates?.length > 0 && (
            <DomainDetailsOrderUpdateErrorMessage
              failedDomainUpdates={failedDomainUpdates}
            />
          )}
        </Stack>,
        {
          action: snackbarId => (
            <Stack direction="row" spacing={1}>
              <CopyToClipboard
                sx={{
                  borderColor: '#fff !important',
                  color: '#fff !important'
                }}
                textToCopy={failedDomainUpdates
                  .concat(pendingDomainUpdates)
                  .map(({ domainName }) => domainName)
                  .toString()}
                variant="outlined"
              />
              <SnackbarCloseAction snackbarId={snackbarId} />
            </Stack>
          ),
          persist: true,
          variant: 'warning'
        }
      );
      setLineItemsToUpdate([]);
      dispatch(
        apiSlice.util.invalidateTags([CUSTOMER_TAG, HOST_NAME_SERVER_TAG])
      );
      setRefetchCount(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobResultData, lineItemsToUpdate]);

  // Handles snackbar after polling
  useEffect(() => {
    if (jobResultData?.allComplete && haveUpdatesThatHaveBeenPolled) {
      if (jobResultData.anyFailures) {
        enqueueSnackbar(
          <DomainDetailsOrderUpdateErrorMessage
            failedDomainUpdates={failedDomainUpdates}
          />,
          {
            action: snackbarId => (
              <Stack direction="row" spacing={1}>
                <CopyToClipboard
                  sx={{
                    borderColor: '#fff !important',
                    color: '#fff !important'
                  }}
                  textToCopy={failedDomainUpdates
                    .map(({ domainName }) => domainName)
                    .toString()}
                  variant="outlined"
                />
                <SnackbarCloseAction snackbarId={snackbarId} />
              </Stack>
            ),
            persist: true,
            variant: 'error'
          }
        );
      } else {
        enqueueSnackbar('Successfully updated the selected domain(s).', {
          variant: 'success'
        });
      }
      setLineItemsToUpdate([]);
      dispatch(
        apiSlice.util.invalidateTags([CUSTOMER_TAG, HOST_NAME_SERVER_TAG])
      );
      setRefetchCount(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lineItemsToUpdate, jobResultData]);

  //Create Order request fails
  useEffect(() => {
    if (isCreateOrderError) {
      enqueueSnackbar(
        'Encountered an error trying to submit name server changes.',
        {
          variant: 'error'
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreateOrderError]);

  const hasEntriesToDisplay = formData?.some(entry => !entry.isDeleted);

  return (
    <>
      <Divider sx={{ mb: 2, mt: 2 }} />
      <Typography>Host Name Servers</Typography>
      <form noValidate={true} onSubmit={handleSubmit}>
        <Stack>
          {validationErrors && (
            <Alert severity="error" sx={{ maxWidth: '280px', mt: 1 }}>
              Fix form errors before submitting.
            </Alert>
          )}
          {!hasEntriesToDisplay && (
            <Typography sx={{ my: 1 }} variant="body2">
              No host name servers configured.
            </Typography>
          )}
          {formData?.map(({ host, id, ipAddress, isDeleted }, index) => (
            <Stack
              className={isDeleted && styles.recordHidden}
              key={index}
              sx={{ my: 1.5, position: 'relative' }}
            >
              <Stack alignItems="end" direction="row" spacing={1}>
                <TextField
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        .{domain?.domainName}
                      </InputAdornment>
                    )
                  }}
                  disabled={isDisabledDueToRestrictions ? true : !canWrite}
                  error={host?.hasError}
                  helperText={host?.errorMessage}
                  label="Host"
                  onChange={event => handleChange(event, id, 'host')}
                  size="small"
                  sx={{ maxWidth: '80%' }}
                  value={host?.value ?? ''}
                />
              </Stack>

              <Stack direction="row" spacing={1} sx={{ my: 2 }}>
                <TextField
                  disabled={isDisabledDueToRestrictions ? true : !canWrite}
                  error={ipAddress?.hasError}
                  helperText={ipAddress?.errorMessage}
                  label="IP Address"
                  onChange={event => handleChange(event, id, 'ipAddress')}
                  value={ipAddress?.value ?? null}
                />
              </Stack>
              {canWrite && (
                <IconButton
                  aria-label="Remove"
                  disabled={
                    isDisabledDueToRestrictions ? true : isLoadingOrFetching
                  }
                  onClick={() => handleRemove(id)}
                  size="small"
                  sx={{ position: 'absolute', right: 0 }}
                >
                  <FontAwesomeIcon icon={faTrashCan} />
                </IconButton>
              )}
              <Divider />
            </Stack>
          ))}
        </Stack>
        {canWrite && (
          <Stack
            direction={{ lg: 'row', xs: 'column' }}
            sx={{
              alignItems: 'flex-start',
              display: 'flex',
              flexWrap: 'wrap',
              gap: 2,
              paddingTop: 1,
              width: '100%'
            }}
          >
            <Button
              color="secondary"
              disabled={
                isDisabledDueToRestrictions ? true : isLoadingOrFetching
              }
              onClick={handleAddMore}
              startIcon={<FontAwesomeIcon icon={faPlus} />}
              variant="contained"
            >
              Add more
            </Button>
            <LoadingButton
              disabled={
                isDisabledDueToRestrictions
                  ? true
                  : !formData.some(hostNameServer => hostNameServer.touched)
              }
              loading={isLoadingOrFetching}
              sx={{ ml: 0, width: 134 }}
              type="submit"
              variant="contained"
            >
              Save
            </LoadingButton>
          </Stack>
        )}
      </form>
    </>
  );
};

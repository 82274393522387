import type { PreloadedState } from '@reduxjs/toolkit';

// eslint-disable-next-line import/named
import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { authReducer } from '../../features/auth/auth-slice';
import { domainFiltersReducer } from '../../features/domains/domain-filters-slice';
import { selectedDomainsReducer } from '../../features/domains/selected-domains-slice';
import { invoiceFiltersReducer } from '../../features/invoices/invoice-filters-slice';
import { manualOrdersFilterReducer } from '../../features/manual-orders/manual-orders-filter-slice';
import { manualOrdersReducer } from '../../features/manual-orders/manual-orders-slice';
import { preregistrationsFiltersReducer } from '../../features/preregistrations/preregistrations-filters-slice';
import { registerDomainsReducer } from '../../features/register-domains/register-domains-slice';
import { signOffContextReducer } from '../../features/sign-off-context/sign-off-context-slice';
import { selectedTldCostsReducer } from '../../features/tld-cost/selected-tld-costs-slice';
import { tldResourcesFilterReducer } from '../../features/tld-resources/tld-resources-filters-slice';
import { transferReducer } from '../../features/transfer/transfer-slice';
import { selectedTransfersReducer } from '../../features/transfers/selected-transfers-slice';
import { selectedWeb3Reducer } from '../../features/web3/selected-web3-slice';
import { web3FiltersReducer } from '../../features/web3/web3-filters-slice';
import { selectedRecordsReducer } from '../../features/zone-management/selected-record-slice';
import { selectedZonesReducer } from '../../features/zone-management/selected-zone-slice';
import { contactSetsReducer } from '../components/contact-sets/contact-sets-slice';
import { config, configReducer } from '../config/config';
import { apiSlice } from './api-slice';
import { customersReducer } from './customers-slice';
import { pendingUserReducer } from './pending-user-slice';
import { resellerReducer } from './reseller';

const reducers = {
  [apiSlice.reducerPath]: apiSlice.reducer,
  auth: authReducer,
  config: configReducer,
  contactSets: contactSetsReducer,
  customers: customersReducer,
  domainFilters: domainFiltersReducer,
  invoiceFilters: invoiceFiltersReducer,
  manualOrders: manualOrdersReducer,
  manualOrdersFilter: manualOrdersFilterReducer,
  pendingUser: pendingUserReducer,
  preregistrationsFilters: preregistrationsFiltersReducer,
  registerDomains: registerDomainsReducer,
  reseller: resellerReducer,
  selectedDomains: selectedDomainsReducer,
  selectedRecords: selectedRecordsReducer,
  selectedTldCosts: selectedTldCostsReducer,
  selectedTransfers: selectedTransfersReducer,
  selectedWeb3Domains: selectedWeb3Reducer,
  selectedZones: selectedZonesReducer,
  signOffContext: signOffContextReducer,
  tldResourcesFilters: tldResourcesFilterReducer,
  transfer: transferReducer,
  web3Filters: web3FiltersReducer
};

const rootReducer = combineReducers(reducers);
export type RootState = ReturnType<typeof rootReducer>;

const resettableRootReducer = (
  state: Parameters<typeof rootReducer>[0],
  action: Parameters<typeof rootReducer>[1]
) => rootReducer(action.type === 'store/reset' ? undefined : state, action);

export const setupStore = (preloadedState: PreloadedState<RootState>) =>
  configureStore({
    devTools: config.ENABLE_REDUX_DEV_TOOLS,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware().concat(apiSlice.middleware),
    preloadedState,
    reducer: resettableRootReducer
  });

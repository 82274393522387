import { Typography } from '@mui/material';
import { intersection } from 'lodash-es';
import React from 'react';
import { useSelector } from 'react-redux';

import {
  selectAllTldsHaveSupportedRenewSupportType,
  selectSelectedDomains
} from './selected-domains-slice';

// TODO should likely be able to simplify this based on the renewalAvailability object in the domains response
const haveRenewalDataForAllDomains = domains =>
  domains.every(
    domain => domain?.$tld.$prices?.[domain?.$tld.phase]?.renewal?.[0]?.years
  );

const getRenewalYearsInCommonForDomains = domains => {
  const renewalYearsForEachDomainsTld = domains
    .map(domain => domain?.$tld.$prices?.[domain?.$tld.phase]?.renewal)
    .map(renewal => renewal.map(renewal => renewal.years));

  const renewalYearsInCommon = intersection.apply(
    this,
    renewalYearsForEachDomainsTld
  );

  return renewalYearsInCommon.length > 0;
};

export const DomainDetailsRenewMissingRenewalData = () => {
  const allTldsHaveSupportedRenewSupportType = useSelector(
    selectAllTldsHaveSupportedRenewSupportType
  );
  const domains = useSelector(selectSelectedDomains);
  const haveRenewalYears = haveRenewalDataForAllDomains(domains);
  const haveRenewalYearsInCommon = getRenewalYearsInCommonForDomains(domains);

  const message =
    domains.length > 1
      ? 'There is no renewal data for at least one selected domain.'
      : 'There is no renewal data at this time.';

  return allTldsHaveSupportedRenewSupportType &&
    !haveRenewalYears &&
    !haveRenewalYearsInCommon ? (
    <Typography>{message}</Typography>
  ) : null;
};

// if any domains have tlds that have a supported renew support type and the renewal length is 0
// then display a message

//     <div
//       id="domains-missing-data-warning"
//       class="adx-alert-bottom adx-alert-warning"
//       ng-if="!Domains.domainsActions._perm.oneHasTldProperty('renewSupportType', 'UNSUPPORTED') &&
//             Domains.domainsActions.getYearsForTlds('renewal').length == 0">
//       There is no renewal data
//       {{ Domains.domainsActions._perm.selectedMoreThan(1) ? 'for at least one selected domain' : '' }}
//       at this time.
//     </div>

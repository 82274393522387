import { Alert } from '@mui/material';
import React from 'react';

import { useAppInfoQuery } from '../../common/store/app-info-api-slice';

export const DomainDetailsRenewMaxAutoRenewMessage = () => {
  const { data: appInfoData } = useAppInfoQuery();

  return appInfoData?.maxAutorenewEnabled ? (
    <Alert severity="info">
      When Max Auto Renew is enabled, the domain will automatically be renewed
      to the maximum duration allowed by the registry.
    </Alert>
  ) : null;
};

// if max auto renew is enabled:
// then if one domain is selected show a toggle to set max auto renew
// then if more than one domain is selected display two buttons to either ON or OFF max auto renew
// then display a notice about max auto renew
//   <div ng-if="Domains.maxAutorenewEnabled">
//
//
//     <p>When Max Autorenew is enabled, the domain will automatically be renewed to the maximum duration allowed by the registry.</p>
//   </div>
// </div>

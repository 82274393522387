import { Stack, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import { TableDetailsHeader } from '../../common/components/table-details-header/TableDetailsHeader';
import { selectCanWrite } from '../auth/auth-slice';
import { DomainDetailsManualRenew } from './DomainDetailsManualRenew';
import { DomainDetailsRenewExpirationDate } from './DomainDetailsRenewExpirationDate';
import { DomainDetailsRenewManualRenewUnsupported } from './DomainDetailsRenewManualRenewUnsupported';
import { DomainDetailsRenewMaxAutoRenewMessage } from './DomainDetailsRenewMaxAutoRenewMessage';
import { DomainDetailsRenewMaximum } from './DomainDetailsRenewMaximum';
import { DomainDetailsRenewMissingRenewalData } from './DomainDetailsRenewMissingRenewalData';
import { DomainDetailsRenewSetAutoRenewForDomains } from './DomainDetailsRenewSetAutoRenewForDomains';
import { DomainDetailsRenewSetExpirationDate } from './DomainDetailsRenewSetExpirationDate';
import { DomainDetailsRenewSetMaxAutoRenewForDomains } from './DomainDetailsRenewSetMaxAutoRenewForDomains';
import { DomainDetailsRenewToggleAutoRenewForDomain } from './DomainDetailsRenewToggleAutoRenewForDomain';
import { DomainDetailsRenewToggleMaxAutoRenewForDomain } from './DomainDetailsRenewToggleMaxAutoRenewForDomain';
import { selectSelectedDomains } from './selected-domains-slice';
import { domainsTabs } from './tabs';

export const DomainDetailsRenew = () => {
  const canWrite = useSelector(selectCanWrite);
  const selectedDomains = useSelector(selectSelectedDomains);

  if (!canWrite) {
    return null;
  }

  return (
    <>
      <TableDetailsHeader
        headerText={domainsTabs.RENEW.label}
        noneSelectedText={
          selectedDomains.length === 0
            ? domainsTabs.RENEW.noneSelectedMessage
            : null
        }
      />
      {selectedDomains.length > 0 && (
        <Stack direction="column" spacing={2}>
          <DomainDetailsRenewExpirationDate />
          <DomainDetailsRenewSetExpirationDate />
          <DomainDetailsRenewToggleAutoRenewForDomain />
          <DomainDetailsRenewSetAutoRenewForDomains />
          <DomainDetailsRenewToggleMaxAutoRenewForDomain />
          <DomainDetailsRenewSetMaxAutoRenewForDomains />
          <DomainDetailsRenewMaxAutoRenewMessage />
          <Typography variant="h6">Manual Renew</Typography>
          <DomainDetailsRenewManualRenewUnsupported />
          <DomainDetailsRenewMissingRenewalData />
          <DomainDetailsRenewMaximum />
          <DomainDetailsManualRenew />
        </Stack>
      )}
    </>
  );
};

import { Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import {
  selectAllTldsHaveSupportedRenewSupportType,
  selectAnyHaveMaxRenewalReached,
  selectSelectedDomains
} from './selected-domains-slice';

export const DomainDetailsRenewMaximum = () => {
  const domains = useSelector(selectSelectedDomains);
  const allTldsHaveSupportedRenewSupportType = useSelector(
    selectAllTldsHaveSupportedRenewSupportType
  );
  const anyHaveMaxRenewalReached = useSelector(selectAnyHaveMaxRenewalReached);

  const message = `No renewal term available. ${
    domains.length > 1 ? 'At least one selected domain' : 'This domain'
  } has reached the ten year maximum.`;

  return allTldsHaveSupportedRenewSupportType && anyHaveMaxRenewalReached ? (
    <Typography>{message}</Typography>
  ) : null;
};

import { FormControlLabel } from '@mui/material';
import React from 'react';

import { DomainServiceAgreementLabel } from './DomainServiceAgreementLabel';

/**
 * Domain Service Agreement Form
 * @param {Object} params
 * @param {ReactElement} params.checkboxComponent
 * @returns
 */
export const DomainServiceAgreementForm = ({ checkboxComponent }) => {
  return (
    <FormControlLabel
      control={checkboxComponent}
      label={<DomainServiceAgreementLabel />}
    />
  );
};

import { LoadingButton } from '@mui/lab';
import { CircularProgress } from '@mui/material';
import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { useJobResponse } from '../../common/hooks/use-job-response';
import { CUSTOMER_TAG } from '../../common/store/api-slice';
import { selectCustomerName } from '../../common/store/customers-slice';
import {
  useCreateOrderMutation,
  usePriceCheckMutation
} from '../../common/store/orders-api-slice';
import { selectReseller } from '../../common/store/reseller';
import { generateId } from '../../common/utils/generate-id';
import { selectClientId } from '../auth/auth-slice';
import {
  selectAreAllDomainsManual,
  selectSelectedDomains
} from './selected-domains-slice';

export const DomainDetailsManualRenewWithConfirmButton = ({
  domainAgreement,
  popupState,
  year
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const shouldStartPolling = useRef(false);
  const selectedDomains = useSelector(selectSelectedDomains);
  const clientId = useSelector(selectClientId);
  const reseller = useSelector(selectReseller);
  const customerName = useSelector(selectCustomerName);
  const areAllDomainsManual = useSelector(selectAreAllDomainsManual);

  const lineItems = useRef([]);
  useEffect(() => {
    lineItems.current = selectedDomains.map(
      ({ domainName, expirationDate }, lineItemNum) => ({
        clientLineItemId: generateId(),
        customerProductExpirationDate: expirationDate,
        domain: domainName,
        lineItemNum,
        lineItemType: 'DOMAIN_RENEWAL',
        years: year
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDomains]);

  const orderOptions = {
    currency: 'USD',
    domainServiceAgreementAccepted: true,
    domainServiceAgreementAcceptedDate: dayjs().toISOString(),
    paymentType: 'FUTURE'
  };

  const [priceCheck, { data: priceCheckData, isLoading }] =
    usePriceCheckMutation();
  const [
    createOrder,
    {
      isError: isCreateOrderError,
      isLoading: isCreateOrderLoading,
      isSuccess: isCreateOrderSuccess
    }
  ] = useCreateOrderMutation();

  const { isLoading: isPollJobLoading } = useJobResponse({
    failureMessage: 'Manual renewal failed',
    lineItems: lineItems.current,
    onComplete: () => popupState.close(),
    shouldStartPolling: shouldStartPolling.current,
    skipCondition: !isCreateOrderSuccess || areAllDomainsManual,
    successMessage: 'Manual renewal succeeded for:',
    tagsToInvalidate: [CUSTOMER_TAG]
  });

  useEffect(() => {
    if (isCreateOrderSuccess && areAllDomainsManual) {
      const request = selectedDomains.length === 1 ? 'A request' : 'Requests';
      const domainName =
        selectedDomains.length === 1
          ? selectedDomains[0].domainName + ' has'
          : 'the selected domains have';

      const snackbarMessage = `${request} to renew ${domainName} been submitted. This change requires a manual process. Your account manager has been notified.`;
      enqueueSnackbar(snackbarMessage, { variant: 'success' });
      popupState.close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreateOrderSuccess, areAllDomainsManual]);

  useEffect(() => {
    if (customerName) {
      priceCheck({
        clientId,
        customerName,
        lineItems: lineItems.current,
        orderOptions,
        reseller
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerName]);

  useEffect(() => {
    if (isCreateOrderError) {
      enqueueSnackbar(
        'An error occurred while creating your order. Try again.',
        { variant: 'error' }
      );
      popupState.close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreateOrderError]);

  const handleManualRenewSubmit = () => {
    shouldStartPolling.current = true;
    createOrder({
      clientId,
      customerName,
      lineItems: lineItems.current,
      orderOptions,
      reseller
    });
  };

  const submitButtonLabelPrice = priceCheckData ? (
    `(${priceCheckData.$totalPriceFormatted})`
  ) : (
    <CircularProgress size={16} sx={{ ml: 2 }} />
  );

  return (
    <>
      <LoadingButton
        disabled={!domainAgreement.accepted || isLoading}
        loading={isCreateOrderLoading || isPollJobLoading}
        onClick={handleManualRenewSubmit}
        variant="contained"
      >
        Submit order {submitButtonLabelPrice}
      </LoadingButton>
    </>
  );
};

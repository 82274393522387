import {
  Backdrop,
  Box,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Switch,
  Typography,
  useTheme
} from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SignOffDialog } from '../../common/components/sign-offs/SignOffDialog';
import { CUSTOMER_TAG, apiSlice } from '../../common/store/api-slice';
import { selectCustomerName } from '../../common/store/customers-slice';
import {
  LINE_ITEM_STATUSES,
  POLLING_INTERVAL,
  usePollJobResponseQuery
} from '../../common/store/job-response-api-slice';
import { useCreateOrderMutation } from '../../common/store/orders-api-slice';
import { selectReseller } from '../../common/store/reseller';
import { useGetRestrictionsForUserQuery } from '../../common/store/restricted-users-api-slice';
import { generateId } from '../../common/utils/generate-id';
import { selectClientId, selectUserEmail } from '../auth/auth-slice';
import { EnableSubscription } from './EnableSubscription';
import { LockUnlockAll } from './LockUnlockAll';
import {
  LOCK_TYPES,
  getClientProhibitedValue,
  getEnabledValue
} from './registrar-locks';
import { selectSelectedDomains } from './selected-domains-slice';

// eslint-disable-next-line complexity
export const DomainDetailsLockUnlockSingle = () => {
  const selectedDomains = useSelector(selectSelectedDomains);
  const userEmail = useSelector(selectUserEmail);
  const selectedDomain = selectedDomains[0];
  const domainName = selectedDomain.domainName;

  const registryLocked = selectedDomain.registryLocked;
  const updateLockSupport = selectedDomain.$tld?.updateLockSupport;
  const transferLockSupport = selectedDomain.$tld?.transferLockSupport;
  const deleteLockSupport = selectedDomain.$tld?.deleteLockSupport;

  const registrarLocks = selectedDomain.$registrarLocks?.types;

  const theme = useTheme();
  const dispatch = useDispatch();

  const [switches, setSwitches] = useState({
    deleteLock: getEnabledValue(registrarLocks, LOCK_TYPES.DELETE),
    transferLock: getEnabledValue(registrarLocks, LOCK_TYPES.TRANSFER),
    updateLock: getEnabledValue(registrarLocks, LOCK_TYPES.UPDATE)
  });

  useEffect(() => {
    setSwitches({
      deleteLock: getEnabledValue(registrarLocks, LOCK_TYPES.DELETE),
      transferLock: getEnabledValue(registrarLocks, LOCK_TYPES.TRANSFER),
      updateLock: getEnabledValue(registrarLocks, LOCK_TYPES.UPDATE)
    });
  }, [registrarLocks, selectedDomain]);

  const [isBackdropOpen, setIsBackdropOpen] = useState(false);
  const [isSignOffDialogOpen, setIsSignOffDialogOpen] = useState(false);

  const clientId = useSelector(selectClientId);
  const reseller = useSelector(selectReseller);
  const customerName = useSelector(selectCustomerName);

  const { data: restrictions, isFetching: isRestrictionsFetching } =
    useGetRestrictionsForUserQuery({
      customerName,
      reseller,
      userEmail
    });
  const { hasTransferLockRestriction } = restrictions ?? {
    hasTransferLockRestriction: false
  };

  const [createOrder, { data: createOrderData }] = useCreateOrderMutation();

  const lineItem = useRef({});
  const clickedSwitchDetails = useRef({ clickedSwitch: '', operation: '' });
  const jobStatus = useRef('');

  const shouldSkipPolling =
    createOrderData?.signOffRequired !== false ||
    Object.keys(lineItem.current).length === 0;

  const handleCloseSignOffDialog = () => {
    setIsSignOffDialogOpen(false);
    lineItem.current = {};
  };

  const showSnackbar = ({ snackbarContent, variant }) => {
    enqueueSnackbar(snackbarContent, { variant });
  };

  const revertSwitch = () => {
    // revert the lock switch if an operation failed, or if a sign off is required
    setSwitches({
      ...switches,
      [clickedSwitchDetails.current.clickedSwitch]:
        !clickedSwitchDetails.current.operation
    });
  };

  const handleSwitchChange = event => {
    setSwitches({ ...switches, [event.target.name]: event.target.checked });
    clickedSwitchDetails.current = {
      clickedSwitch: event.target.name,
      operation: event.target.checked
    };
    const domainLock = getClientProhibitedValue(event.target.name);
    const domainStatuses = { [domainLock]: event.target.checked };

    // If locking a domain for updates, also lock it for deletion.
    if (
      event.target.checked &&
      event.target.name === 'updateLock' &&
      deleteLockSupport &&
      !getEnabledValue(registrarLocks, LOCK_TYPES.DELETE)
    ) {
      domainStatuses.clientDeleteProhibited = true;
    }

    lineItem.current = [
      {
        clientLineItemId: generateId(),
        domain: domainName,
        domainStatuses: domainStatuses,
        lineItemNum: 0,
        lineItemType: 'UPDATE_DOMAIN_STATUSES'
      }
    ];

    createOrder({
      clientId,
      customerName,
      lineItems: lineItem.current,
      orderOptions: { paymentType: 'NONE' },
      reseller
    });
    setIsBackdropOpen(true);
  };

  useEffect(() => {
    // sign off required
    if (createOrderData?.signOffRequired) {
      // no sign off pending
      if (!createOrderData?.forceUpdateRequired) {
        showSnackbar({
          snackbarContent: 'Your request is now pending Sign-Off',
          variant: 'info'
        });
        lineItem.current = {};
        setIsBackdropOpen(false);
      }
      //sign off pending
      else if (createOrderData?.forceUpdateRequired) {
        setIsSignOffDialogOpen(true);
        setIsBackdropOpen(false);
      }
      revertSwitch();
      setIsBackdropOpen(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createOrderData]);

  const { data: jobResultData, refetch } = usePollJobResponseQuery(
    {
      clientId,
      clientLineItemIds: [lineItem.current[0]?.clientLineItemId]
    },
    { skip: shouldSkipPolling }
  );

  const handleForceUpdate = () => {
    createOrder({
      clientId,
      customerName,
      lineItems: lineItem.current,
      orderOptions: { paymentType: 'NONE' },
      reseller,
      signOffForceUpdate: true
    });
    setIsBackdropOpen(true);
    setIsSignOffDialogOpen(false);
    lineItem.current = {};
  };

  useEffect(() => {
    // FIXME this will poll forever
    if (jobResultData?.anyPending) {
      setTimeout(refetch, POLLING_INTERVAL.active);
    } else {
      const messageObject = jobResultData?.messages?.find(
        message =>
          message.clientLineItemId === lineItem.current[0].clientLineItemId
      );
      jobStatus.current = messageObject?.status;
      if (jobStatus.current) {
        setIsBackdropOpen(false);
        showSnackbar(getJobSnackbarProperties());
      }
      if (jobStatus.current === LINE_ITEM_STATUSES.failure) {
        revertSwitch();
      } else if (jobStatus.current === LINE_ITEM_STATUSES.success) {
        dispatch(apiSlice.util.invalidateTags([CUSTOMER_TAG]));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobResultData]);

  const renderBackdrop = () => {
    return (
      <Backdrop
        open={isBackdropOpen}
        sx={{
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          bottom: 0,
          color: theme.palette.primary.main,
          left: 0,
          position: 'absolute',
          right: 0,
          top: 0,
          zIndex: theme => theme.zIndex.drawer + 1
        }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  const getJobSnackbarProperties = () => {
    const isSuccess = jobStatus.current === LINE_ITEM_STATUSES.success;

    const displaySuccessMessage = () => {
      // @ts-ignore
      if (clickedSwitchDetails.current.operation === true) {
        return `The lock was successfully applied to ${domainName}`;
      } else {
        return `The lock was successfully removed from ${domainName}`;
      }
    };

    return {
      snackbarContent: isSuccess
        ? displaySuccessMessage()
        : `An error was encountered while updating the locks for ${domainName}`,
      variant: isSuccess ? 'success' : 'error'
    };
  };

  return (
    <Box>
      <Box sx={{ width: 350 }}>
        <Typography variant="h6">{domainName}</Typography>
        <FormGroup sx={{ paddingLeft: 2, paddingTop: 2, width: '100%' }}>
          {updateLockSupport && (
            <FormControlLabel
              control={
                <Switch
                  checked={switches.updateLock}
                  disabled={registryLocked}
                  name="updateLock"
                  onChange={handleSwitchChange}
                />
              }
              label={`Updates ${switches.updateLock ? 'Locked' : 'Unlocked'}`}
              sx={{ paddingBottom: 1 }}
            />
          )}
          {transferLockSupport && (
            <FormControlLabel
              control={
                <Switch
                  checked={switches.transferLock}
                  disabled={
                    hasTransferLockRestriction || isRestrictionsFetching
                      ? true
                      : registryLocked
                  }
                  name="transferLock"
                  onChange={handleSwitchChange}
                />
              }
              label={`Transfers ${
                switches.transferLock ? 'Locked' : 'Unlocked'
              }`}
              sx={{ paddingBottom: 1 }}
            />
          )}
          {deleteLockSupport && (
            <FormControlLabel
              control={
                <Switch
                  checked={switches.deleteLock}
                  disabled={registryLocked}
                  name="deleteLock"
                  onChange={handleSwitchChange}
                />
              }
              label={`Deletion ${switches.deleteLock ? 'Locked' : 'Unlocked'}`}
              sx={{ paddingBottom: 1 }}
            />
          )}
        </FormGroup>
        <LockUnlockAll position={'leftRow'} />
        {renderBackdrop()}
        <SignOffDialog
          handleClose={handleCloseSignOffDialog}
          handleSubmit={handleForceUpdate}
          isOpen={isSignOffDialogOpen}
        />
      </Box>
      <EnableSubscription />
    </Box>
  );
};

import { Alert, Box, Divider, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { DomainDetailsDeleteDialog } from './DomainDetailsDeleteDialog';
import { selectSelectedDomains } from './selected-domains-slice';
import { domainsTabs } from './tabs';

export const DomainDetailsDeleteSingle = () => {
  const selectedDomains = useSelector(selectSelectedDomains);

  const [invalidDeleteReasons, setInvalidDeleteReasons] = useState<string[]>(
    []
  );

  useEffect(() => {
    if (selectedDomains) {
      setInvalidDeleteReasons(getInvalidDeleteReasons());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDomains]);

  const getInvalidDeleteReasons = () => {
    const reasons: string[] = [];

    if (selectedDomains.length > 1) {
      reasons.push('Only one domain can be deleted at a time.');

      // since we don't know which domain is meant to be
      // deleted, we don't know which domain to check other
      // restrictions for. return immediately.
      return reasons;
    }

    const isDeleteLockEnabled = selectedDomains[0].$deleteLockEnabled;

    if (isDeleteLockEnabled) {
      reasons.push(
        'The Deletion Lock is currently enabled for this domain. Toggle it to off to continue with deletion.'
      );
    }

    const isGTld = selectedDomains[0].$tld.category.toLowerCase() === 'gtld';

    if (!isGTld) {
      reasons.push('Only gTLD domains can be deleted.');
    }

    const isAutoRenewEnabled = selectedDomains[0].autoBilling;

    if (isAutoRenewEnabled) {
      reasons.push(
        `${selectedDomains[0].domainName} has auto-renew turned ON. You must turn auto-renew OFF before you can delete a domain.`
      );
    }

    return reasons;
  };

  return (
    <Box>
      <Typography gutterBottom={true} variant="h5">
        {domainsTabs.DELETE.label}
      </Typography>
      {invalidDeleteReasons.map((reason, index) => {
        return (
          <>
            <Alert
              key={`reason-alert-${index}`}
              severity="warning"
              sx={{ mb: 2, mt: 2 }}
            >
              {reason}
            </Alert>
          </>
        );
      })}
      <Divider />
      <Stack
        spacing={2}
        sx={{
          pb: 2,
          pl: 1,
          pt: 3
        }}
      >
        <Typography variant="body2">
          Click the delete button to submit a deletion request to the registry
          and remove this domain from the current portfolio.
        </Typography>
        <Box>
          <DomainDetailsDeleteDialog />
        </Box>
      </Stack>
    </Box>
  );
};

import React from 'react';
import { useSelector } from 'react-redux';

import { TableDetailsHeader } from '../../common/components/table-details-header/TableDetailsHeader';
import { DomainDetailsDeleteSingle } from './DomainDetailsDeleteSingle';
import { selectSelectedDomains } from './selected-domains-slice';
import { domainsTabs } from './tabs';

export const DomainDetailsDelete = () => {
  const selectedDomains = useSelector(selectSelectedDomains);
  return (
    <>
      {selectedDomains.length === 0 && (
        <TableDetailsHeader
          headerText={domainsTabs.DELETE.label}
          noneSelectedText={domainsTabs.DELETE.noneSelectedMessage}
        />
      )}
      {selectedDomains.length > 0 && <DomainDetailsDeleteSingle />}
    </>
  );
};

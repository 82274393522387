import { faDash, faLock, faUnlock } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Stack, Tooltip, useTheme } from '@mui/material';
import React from 'react';

export const LockCell = ({ params }) => {
  const theme = useTheme();
  return (
    <Stack direction="row" spacing={1}>
      {params.row.registryLocked && (
        <Tooltip title="This domain is locked at the registry level">
          <FontAwesomeIcon color={theme.palette.success.dark} icon={faLock} />
        </Tooltip>
      )}
      {params.row.$registrarLocks.allEnabled && (
        <Tooltip title="This domain is locked for all types of updates at the registrar level">
          <FontAwesomeIcon color={theme.palette.success.light} icon={faLock} />
        </Tooltip>
      )}
      {params.row.$registrarLocks.someEnabled && (
        <Tooltip title="This domain is unlocked for some types of updates at the registrar level">
          <FontAwesomeIcon color={theme.palette.warning.main} icon={faUnlock} />
        </Tooltip>
      )}
      {params.row.$registrarLocks.noneEnabled && (
        <Tooltip title="This domain is unlocked for all types of updates at the registrar level">
          <FontAwesomeIcon color={theme.palette.error.main} icon={faUnlock} />
        </Tooltip>
      )}
      {params.row.$registrarLocks.noneAvailable && (
        <Tooltip title="This domain does not support any locks">
          <FontAwesomeIcon icon={faDash} />
        </Tooltip>
      )}
    </Stack>
  );
};

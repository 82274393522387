const getNoneSelectedMessage = thing => `Select a domain to view ${thing}.`;

export const domainsTabs = Object.freeze({
  ACTION_HISTORY: {
    label: 'Action History',
    noneSelectedMessage: getNoneSelectedMessage('action history'),
    value: 'actionHistory'
  },
  CONTACT_SETS: {
    label: 'Contact Sets',
    noneSelectedMessage: getNoneSelectedMessage('contact sets'),
    value: 'contactSets'
  },
  DELETE: {
    label: 'Delete',
    noneSelectedMessage: getNoneSelectedMessage('deletion options'),
    value: 'delete'
  },
  INFO: {
    label: 'Domain Details',
    noneSelectedMessage: getNoneSelectedMessage('details'),
    value: 'info'
  },
  LABELS: {
    label: 'Labels',
    noneSelectedMessage: getNoneSelectedMessage('labels'),
    value: 'labels'
  },
  LOCK_UNLOCK: {
    label: 'Registrar Locks',
    noneSelectedMessage: getNoneSelectedMessage('registrar locks'),
    value: 'lockunlock'
  },
  NAME_SERVERS: {
    label: 'Name Servers',
    noneSelectedMessage: getNoneSelectedMessage('name servers'),
    value: 'nameServers'
  },
  NOTES: {
    label: 'Notes',
    multipleSelectedMessage: 'Select only one domain to view notes.',
    noneSelectedMessage: getNoneSelectedMessage('notes'),
    value: 'notes'
  },
  RENEW: {
    label: 'Renew',
    noneSelectedMessage: getNoneSelectedMessage('renew details'),
    value: 'renew'
  }
});

import {
  faCheck,
  faCircleCheck,
  faLockKeyhole,
  faLockKeyholeOpen,
  faXmark
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import { selectCanWrite } from '../auth/auth-slice';
import styles from './DomainDetailsInfo.module.css';
import { SendAuthCode } from './SendAuthCode';
import { SyncDomains } from './SyncDomains';
import { LOCK_TYPES, getEnabledValue } from './registrar-locks';
import { selectSelectedDomains } from './selected-domains-slice';

// eslint-disable-next-line complexity
export const DomainDetailsInfoSingle = () => {
  const selectedDomains = useSelector(selectSelectedDomains);
  const canWrite = useSelector(selectCanWrite);

  const theme = useTheme();

  const domain = selectedDomains[0];

  const domainName = domain.domainName;
  const registryLocked = domain.registryLocked;
  const registrarLocks = domain.$registrarLocks?.types;
  const updateLock = registrarLocks
    ? getEnabledValue(registrarLocks, LOCK_TYPES.UPDATE)
    : undefined;
  const transferLock = registrarLocks
    ? getEnabledValue(registrarLocks, LOCK_TYPES.TRANSFER)
    : undefined;
  const deleteLock = registrarLocks
    ? getEnabledValue(registrarLocks, LOCK_TYPES.DELETE)
    : undefined;
  const privateWhois = domain.whoIsPrivacy;

  return (
    <>
      <Box sx={{ width: 350 }}>
        <Typography variant="h6">{domainName}</Typography>
        <Stack
          spacing={3}
          sx={{ paddingLeft: 1, paddingTop: 3, width: '100%' }}
        >
          <div>
            <div className={styles.infoIcon}>
              <FontAwesomeIcon
                icon={registryLocked ? faLockKeyhole : faLockKeyholeOpen}
                style={{
                  color: registryLocked
                    ? theme.palette.success.dark
                    : theme.palette.error.main,
                  width: 15
                }}
              />
            </div>
            <div className={styles.infoText}>{`Registry ${
              registryLocked ? 'Locked' : 'Unlocked'
            }`}</div>
          </div>
          <div>
            <div className={styles.infoIcon}>
              <FontAwesomeIcon
                icon={updateLock ? faLockKeyhole : faLockKeyholeOpen}
                style={{
                  color: updateLock
                    ? theme.palette.success.main
                    : theme.palette.error.main,
                  width: 15
                }}
              />
            </div>
            <div className={styles.infoText}>
              {`Registrar Update ${updateLock ? 'Locked' : 'Unlocked'}`}
            </div>
          </div>
          <div>
            <div className={styles.infoIcon}>
              <FontAwesomeIcon
                icon={transferLock ? faLockKeyhole : faLockKeyholeOpen}
                style={{
                  color: transferLock
                    ? theme.palette.success.main
                    : theme.palette.error.main,
                  width: 15
                }}
              />
            </div>
            <div className={styles.infoText}>
              {`Registrar Transfer ${transferLock ? 'Locked' : 'Unlocked'}`}
            </div>
          </div>
          <div>
            <div className={styles.infoIcon}>
              <FontAwesomeIcon
                icon={deleteLock ? faLockKeyhole : faLockKeyholeOpen}
                style={{
                  color: deleteLock
                    ? theme.palette.success.main
                    : theme.palette.error.main,
                  width: 15
                }}
              />
            </div>
            <div className={styles.infoText}>
              {`Registrar Deletion ${deleteLock ? 'Locked' : 'Unlocked'}`}
            </div>
          </div>
          <div>
            <div className={styles.infoIcon}>
              <FontAwesomeIcon
                icon={privateWhois ? faCheck : faXmark}
                style={{
                  color: privateWhois
                    ? theme.palette.success.main
                    : theme.palette.error.main,
                  width: 15
                }}
              />
            </div>
            <div className={styles.infoText}>Private Whois</div>
          </div>
          {canWrite && (
            <Stack direction="row">
              <div className={styles.infoIcon}>
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  style={{
                    marginTop: 8,
                    width: 15
                  }}
                />
              </div>
              <div className={styles.infoText}>
                Authorization Code <SendAuthCode />
              </div>
            </Stack>
          )}
        </Stack>
      </Box>
      <SyncDomains />
    </>
  );
};

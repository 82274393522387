import { DOMAIN_NOTE_TAG, apiSlice } from '../../common/store/api-slice';
import { getLocalizedDateTime } from '../utils/date-time';

export const notesApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    addNote: builder.mutation({
      invalidatesTags: [DOMAIN_NOTE_TAG],
      query: ({
        customerName,
        entityIds,
        files,
        message,
        noteEntityType = 'CUSTOMER_PRODUCT',
        reseller,
        subject
      }) => {
        const formData = new FormData();
        formData.append('noteEntityType', noteEntityType);
        formData.append('entityIds', entityIds);
        formData.append('subject', subject);
        formData.append('message', message);
        Array.from(files).forEach(file => formData.append('files', file));

        return {
          body: formData,
          method: 'POST',
          url: `resellers/${reseller}/customers/${customerName}/notes`
        };
      }
    }),
    deleteNoteReminder: builder.mutation({
      invalidatesTags: [DOMAIN_NOTE_TAG],
      query: ({ customerName, noteId, reminderId, reseller }) => ({
        method: 'DELETE',
        url: `resellers/${reseller}/customers/${customerName}/notes/reminders/${noteId}/${reminderId}`
      })
    }),
    getNotes: builder.query({
      providesTags: [DOMAIN_NOTE_TAG],
      query: ({
        customerName,
        id,
        noteEntityType = 'CUSTOMER_PRODUCT',
        reseller
      }) =>
        `resellers/${reseller}/customers/${customerName}/notes/${noteEntityType}/${id}`,
      transformResponse: response =>
        response.map(note => ({
          ...note,
          $createdFormatted: getLocalizedDateTime(note.created)
        }))
    }),
    setNoteReminder: builder.mutation({
      invalidatesTags: [DOMAIN_NOTE_TAG],
      query: ({
        customerName,
        noteId,
        reminderDateTime,
        reminderId,
        reseller
      }) => ({
        body: { id: reminderId, reminderDate: reminderDateTime },
        method: 'POST',
        url: `resellers/${reseller}/customers/${customerName}/notes/reminders/${noteId}`
      })
    })
  })
});

export const {
  useAddNoteMutation,
  useDeleteNoteReminderMutation,
  useGetNotesQuery,
  useSetNoteReminderMutation
} = notesApiSlice;

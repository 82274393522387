import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid
} from '@mui/material';
import { noop } from 'lodash-es';
import {
  bindDialog,
  bindToggle,
  bindTrigger,
  usePopupState
} from 'material-ui-popup-state/hooks';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ContactSetsAutocomplete } from './ContactSetsAutocomplete';
import { ContactSetsTabs } from './ContactSetsTabs';
import { ContactSetsTitle } from './ContactSetsTitle';
import {
  selectDisplayedContactSetId,
  setDisplayedContactSetHasChanged,
  setDisplayedContactSetId,
  setSelectedTldForSpecialRequirments
} from './contact-sets-slice';

export const ContactSetsDialog = ({
  contactSetId,
  domains,
  handleApplyContactSet = noop,
  lockAutocomplete = false,
  renderActions,
  renderTrigger
}) => {
  const dispatch = useDispatch();
  const dialogState = usePopupState({
    popupId: 'configureContactSetsDialog',
    variant: 'dialog'
  });
  const displayedContactSetId = useSelector(selectDisplayedContactSetId);

  //Clears selected special requirement when changing contact sets,
  //and tracks when displayed contact set has changed from the applied set
  useEffect(() => {
    dispatch(setSelectedTldForSpecialRequirments(null));
    dispatch(
      setDisplayedContactSetHasChanged(displayedContactSetId !== contactSetId)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayedContactSetId]);

  const handleSetDisplayedContactSetId = displayedContactSetId =>
    dispatch(setDisplayedContactSetId(displayedContactSetId));

  const handleDialogTriggerClick = event => {
    if (contactSetId !== -1) {
      dispatch(setDisplayedContactSetId(contactSetId));
    }
    dialogState.open(event);
  };

  return (
    <>
      {renderTrigger({
        ...bindTrigger(dialogState),
        onClick: handleDialogTriggerClick
      })}
      <Dialog {...bindDialog(dialogState)} fullWidth maxWidth={false}>
        <DialogTitle>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <ContactSetsTitle domains={domains} />
            </Grid>
            <Grid item xs={6}>
              <ContactSetsAutocomplete
                contactSetId={contactSetId}
                disabled={lockAutocomplete}
                handleSetDisplayedContactSetId={handleSetDisplayedContactSetId}
              />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <ContactSetsTabs contactSetId={contactSetId} />
        </DialogContent>
        <DialogActions>
          {renderActions({
            bindToggle,
            dialogState,
            domains,
            handleApplyContactSet
          })}
        </DialogActions>
      </Dialog>
    </>
  );
};

import { LoadingButton } from '@mui/lab';
import { List, ListItem, Stack, Typography } from '@mui/material';
import PopupState from 'material-ui-popup-state';
import { useSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { SignOffDialog } from '../../common/components/sign-offs/SignOffDialog';
import {
  entityTypes,
  useJobResponse
} from '../../common/hooks/use-job-response';
import { CUSTOMER_TAG, apiSlice } from '../../common/store/api-slice';
import { selectCustomerName } from '../../common/store/customers-slice';
import { useCreateOrderMutation } from '../../common/store/orders-api-slice';
import { selectReseller } from '../../common/store/reseller';
import { generateId } from '../../common/utils/generate-id';
import { selectClientId } from '../auth/auth-slice';
import { selectSelectedDomains } from './selected-domains-slice';

type DomainDetailsDeleteConfirmButtonProps = {
  popupState: ReturnType<typeof PopupState>;
};

export const DomainDetailsDeleteConfirmButton = ({
  popupState
}: DomainDetailsDeleteConfirmButtonProps) => {
  const reseller = useSelector(selectReseller);
  const customerName = useSelector(selectCustomerName);
  const clientId = useSelector(selectClientId);
  const selectedDomains = useSelector(selectSelectedDomains);

  const lineItems: React.MutableRefObject<LineItem[]> = useRef([]);

  const shouldStartPolling = useRef(false);

  const [needsForceUpdate, setNeedsForceUpdate] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const orderOptions = {
    currency: 'USD',
    paymentType: 'NONE'
  };

  const [
    createOrder,
    {
      data: orderCreationResponse,
      error: orderCreationError,
      isError: isCreateOrderError,
      isSuccess: isCreateOrderSuccess
    }
  ] = useCreateOrderMutation();

  const { isLoading } = useJobResponse({
    entityType: entityTypes.domain,
    failureMessage: `Failed to delete the following domain. Please try again later or contact support.`,
    lineItems: lineItems.current,
    onComplete: () => {
      setIsSubmitting(false);

      popupState.close();
    },
    shouldStartPolling: shouldStartPolling.current,
    skipCondition: !isCreateOrderSuccess,
    successMessage: `Successfully deleted the following domain.`,
    tagsToInvalidate: [CUSTOMER_TAG] as Parameters<
      typeof apiSlice.util.invalidateTags
    >[0]
  });

  useEffect(() => {
    const isErrorResponse = orderCreationError && 'data' in orderCreationError;

    const errorData = isErrorResponse
      ? (orderCreationError.data as { invalidReasons: string[] | undefined })
      : undefined;

    const errorIncludesInvalidReasons =
      isErrorResponse && errorData?.invalidReasons;

    if (isCreateOrderError && errorIncludesInvalidReasons) {
      const invalidReasons = errorData!.invalidReasons!;

      enqueueSnackbar(
        <Stack direction="column" sx={{ mb: 2 }}>
          <Typography variant="body2">
            Failed to submit deletion order.
          </Typography>
          <List sx={{ listStyleType: 'disc', pl: 2, py: 0 }}>
            {Array.isArray(invalidReasons) &&
              invalidReasons.map(reason => {
                return (
                  <ListItem key={reason} sx={{ display: 'list-item', p: 0 }}>
                    {reason}
                  </ListItem>
                );
              })}
          </List>
        </Stack>,
        { variant: 'error' }
      );

      popupState.close();
    } else if (isCreateOrderError) {
      enqueueSnackbar(
        `An unexpected error occurred while trying to submit a deletion request. Please contact support.`,
        { variant: 'error' }
      );

      popupState.close();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreateOrderError]);

  useEffect(() => {
    if (isCreateOrderSuccess && orderCreationResponse) {
      const { forceUpdateRequired, signOffRequired } = orderCreationResponse;

      if (signOffRequired && forceUpdateRequired) {
        setIsSubmitting(false);

        setNeedsForceUpdate(true);
      } else if (signOffRequired) {
        handleSignOff();
      } else {
        shouldStartPolling.current = true;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreateOrderSuccess, orderCreationResponse]);

  const handleSignOff = () => {
    enqueueSnackbar('Your delete request is now pending sign-off', {
      variant: 'info'
    });

    setIsSubmitting(false);

    popupState.close();
  };

  const handleDomainDelete = (forceUpdate: boolean = false) => {
    setIsSubmitting(true);

    const domainToDelete = selectedDomains[0];
    const clientLineItemId = generateId();

    lineItems.current = [
      {
        clientLineItemId: clientLineItemId,
        domain: domainToDelete.domainName,
        lineItemNum: 0,
        lineItemType: 'DOMAIN_DELETE'
      }
    ];

    createOrder({
      clientId,
      customerName,
      lineItems: lineItems.current,
      orderOptions,
      reseller,
      signOffForceUpdate: forceUpdate
    });
  };

  const handleForceUpdate = () => handleDomainDelete(true);

  const handleCloseDialog = () => popupState.close();

  return (
    <>
      <LoadingButton
        disabled={isSubmitting || isLoading}
        loading={isSubmitting || isLoading}
        onClick={() => handleDomainDelete()}
        variant="contained"
      >
        Delete
      </LoadingButton>
      <SignOffDialog
        handleClose={handleCloseDialog}
        handleSubmit={handleForceUpdate}
        isOpen={needsForceUpdate}
        loading={isSubmitting}
      />
    </>
  );
};

import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { Alert, Button, Snackbar } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { selectApiFilters } from '../../../features/domains/domain-filters-slice';
import {
  DEFAULT_DOMAINS_SORT_DIR,
  DEFAULT_DOMAINS_SORT_FIELD
} from '../../../features/domains/domains-sort-defaults';
import { useExportWeb3CustomerDomainsMutation } from '../../../features/web3/web3-api-slice';
import {
  DEFAULT_WEB3_SORT_DIR,
  DEFAULT_WEB3_SORT_FIELD
} from '../../../features/web3/web3-sort-defaults';
import { selectCustomerName } from '../../store/customers-slice';
import { useExportCustomerDomainsMutation } from '../../store/domains-api-slice';
import { selectReseller } from '../../store/reseller';
import { exportTypes } from './export-types';

/**
 * Get sort defaults
 * @param {String} exportType
 * @returns {Object} defaults
 */
const getSortDefaults = exportType => {
  let defaultSortField;
  let defaultSortDir;

  switch (exportType) {
    case exportTypes.TRADITIONAL_DOMAINS:
      defaultSortField = DEFAULT_DOMAINS_SORT_FIELD;
      defaultSortDir = DEFAULT_DOMAINS_SORT_DIR;
      break;
    case exportTypes.WEB3_DOMAINS:
      defaultSortField = DEFAULT_WEB3_SORT_FIELD;
      defaultSortDir = DEFAULT_WEB3_SORT_DIR;
      break;
    default:
      throw new Error('Unknown export type');
  }
  return { defaultSortDir, defaultSortField };
};

export const DomainsGridToolbarExport = ({ archived = false, exportType }) => {
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);
  const [isErrorOpen, setIsErrorOpen] = useState(false);
  const reseller = useSelector(selectReseller);
  const customerName = useSelector(selectCustomerName);
  const [
    exportCustomerDomains,
    { isError: isExportDomainsError, isSuccess: isExportDomainsSuccess }
  ] = useExportCustomerDomainsMutation();
  const [
    exportWeb3CustomerDomains,
    { isError: isExportWeb3DomainsError, isSuccess: isExportWeb3DomainsSuccess }
  ] = useExportWeb3CustomerDomainsMutation();

  const { automationType, find, fromDate, labelIds, nameServers, toDate } =
    useSelector(selectApiFilters);

  const [searchParams] = useSearchParams();

  const { defaultSortDir, defaultSortField } = getSortDefaults(exportType);
  const sort = searchParams.get('sort') ?? defaultSortField;
  const dir = searchParams.get('dir') ?? defaultSortDir;

  const handleExportClick = () => {
    switch (exportType) {
      case exportTypes.TRADITIONAL_DOMAINS:
        exportCustomerDomains({
          active: !archived,
          automationType,
          customerName,
          dir,
          find,
          fromDate,
          labelIds,
          nameServers,
          reseller,
          sort,
          toDate
        });
        break;
      case exportTypes.WEB3_DOMAINS:
        exportWeb3CustomerDomains({
          customerName,
          dir,
          find,
          fromDate,
          labelIds,
          reseller,
          sort,
          toDate
        });
        break;
      default:
        throw new Error('Unknown export type');
    }
  };

  useEffect(() => {
    if (isExportDomainsSuccess || isExportWeb3DomainsSuccess) {
      setIsSuccessOpen(true);
    }
  }, [isExportDomainsSuccess, isExportWeb3DomainsSuccess]);

  useEffect(() => {
    if (isExportDomainsError || isExportWeb3DomainsError) {
      setIsErrorOpen(true);
    }
  }, [isExportDomainsError, isExportWeb3DomainsError]);

  return (
    <>
      <Button
        onClick={handleExportClick}
        size="small"
        startIcon={<SaveAltIcon />}
      >
        Export
      </Button>
      <Snackbar
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top'
        }}
        autoHideDuration={6000}
        onClose={() => setIsSuccessOpen(false)}
        open={isSuccessOpen}
      >
        <Alert severity="success">
          Your export has been started. You will receive an email with the
          results shortly.
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top'
        }}
        autoHideDuration={6000}
        onClose={() => setIsErrorOpen(false)}
        open={isErrorOpen}
      >
        <Alert severity="error">Your export failed. Try again.</Alert>
      </Snackbar>
    </>
  );
};

import { array, boolean, object, string } from 'yup';

/**
 * Schema for each host name server record in the form schema
 * @param {*} object containing validation functions and form values
 * @returns defined record schema object
 */
export const recordSchema = ({
  handleHostNameStringValidation,
  handleIpAddressStringValidation,
  handleRequiredValidation,
  value
}) =>
  object({
    host: object({
      errorMessage: string().default(''),
      hasError: boolean().default(false),
      touched: boolean().default(false),
      value: string()
        .required(handleRequiredValidation)
        .matches(
          /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9])$/,
          handleHostNameStringValidation
        )
        .default(() => value?.host || '')
    }),
    id: string().default(value?.id || undefined),
    ipAddress: object({
      errorMessage: string().default(''),
      hasError: boolean().default(false),
      touched: boolean().default(false),
      value: string()
        .required(handleRequiredValidation)
        .matches(
          /(^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$)|(^([0-9a-fA-Z]){0,4}(:([0-9a-fA-Z]){0,4}){7}$)/,
          handleIpAddressStringValidation
        )
        .default(() => value?.ipAddress || '')
    }),
    isDeleted: boolean().default(false),
    isNewRecord: boolean().default(value?.isNewRecord),
    touched: boolean().default(value?.touched)
  });

/**
 * Schema for host name server records form
 * @param {*} object containing validation functions and form values
 * @returns defined form schema object
 */
export const formSchema = ({
  handleHostNameStringValidation,
  handleIpAddressStringValidation,
  handleRequiredValidation,
  value
}) =>
  array().of(
    recordSchema({
      handleHostNameStringValidation,
      handleIpAddressStringValidation,
      handleRequiredValidation,
      value
    })
  );

import { LoadingButton } from '@mui/lab';
import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { useJobResponse } from '../../common/hooks/use-job-response';
import { CUSTOMER_TAG } from '../../common/store/api-slice';
import { selectCustomerName } from '../../common/store/customers-slice';
import {
  useCreateOrderMutation,
  usePriceCheckMutation
} from '../../common/store/orders-api-slice';
import { selectReseller } from '../../common/store/reseller';
import { generateId } from '../../common/utils/generate-id';
import { selectClientId } from '../auth/auth-slice';
import {
  selectCanEnableLocalPresence,
  selectSelectedDomains
} from './selected-domains-slice';

export const LocalPresenceConfirmButton = ({ domainAgreement, popupState }) => {
  const reseller = useSelector(selectReseller);
  const customerName = useSelector(selectCustomerName);
  const clientId = useSelector(selectClientId);
  const selectedDomains = useSelector(selectSelectedDomains);
  const { enqueueSnackbar } = useSnackbar();

  const canEnable = useSelector(selectCanEnableLocalPresence);
  const shouldStartPolling = useRef(false);

  const orderOptions = {
    currency: 'USD',
    domainServiceAgreementAccepted: true,
    domainServiceAgreementAcceptedDate: dayjs().toISOString(),
    paymentType: 'FUTURE'
  };

  const lineItems = useRef([]);
  useEffect(() => {
    lineItems.current = selectedDomains
      .filter(({ usingLocalPresence }) => usingLocalPresence === false)
      .map(({ domainName }, lineItemNum) => ({
        clientLineItemId: generateId(),
        domain: domainName,
        lineItemNum,
        lineItemType: 'LOCAL_PRESENCE',
        useLocalPresence: true,
        years: 1
      }));
  }, [selectedDomains]);

  const [priceCheck, { data: priceCheckData, isLoading: isPriceCheckLoading }] =
    usePriceCheckMutation();

  const [
    createOrder,
    {
      isError: isCreateOrderError,
      isLoading: isCreateOrderLoading,
      isSuccess: isCreateOrderSuccess
    }
  ] = useCreateOrderMutation();

  const { isLoading: isPollJobLoading } = useJobResponse({
    failureMessage: 'Local presence failed',
    lineItems: lineItems.current,
    onComplete: () => popupState.close(),
    shouldStartPolling: shouldStartPolling.current,
    skipCondition: !isCreateOrderSuccess || !canEnable,
    successMessage: 'Local presence enabled for:',
    tagsToInvalidate: [CUSTOMER_TAG]
  });

  useEffect(() => {
    if (customerName) {
      priceCheck({
        clientId,
        customerName,
        lineItems: lineItems.current,
        orderOptions,
        reseller
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerName]);

  useEffect(() => {
    if (isCreateOrderError) {
      enqueueSnackbar(
        'An error occurred while creating your order. Try again.',
        { variant: 'error' }
      );
      popupState.close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreateOrderError]);

  const handleLocalPresenceSubmit = () => {
    shouldStartPolling.current = true;
    createOrder({
      clientId,
      customerName,
      lineItems: lineItems.current,
      orderOptions,
      reseller
    });
  };

  const submitButtonLabelPrice = priceCheckData
    ? `(${priceCheckData.$totalPriceFormatted})`
    : null;

  return (
    <LoadingButton
      disabled={!domainAgreement.accepted || isPriceCheckLoading}
      loading={isCreateOrderLoading || isPollJobLoading}
      onClick={handleLocalPresenceSubmit}
      variant="contained"
    >
      Submit order {submitButtonLabelPrice}
    </LoadingButton>
  );
};

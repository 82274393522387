import { LoadingButton } from '@mui/lab';
import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { SnackbarCloseAction } from '../../common/components/snackbar-close-action/SnackbarCloseAction';
import { useJobResponse } from '../../common/hooks/use-job-response';
import { CUSTOMER_TAG } from '../../common/store/api-slice';
import { useVerifyContactSetsForDomainsQuery } from '../../common/store/contacts-api-slice';
import { selectCustomerName } from '../../common/store/customers-slice';
import { useCreateOrderMutation } from '../../common/store/orders-api-slice';
import { selectReseller } from '../../common/store/reseller';
import { generateId } from '../../common/utils/generate-id';
import { selectClientId } from '../auth/auth-slice';
import { getContactSetsInvalidWithoutLocalPresenceMessage } from './get-contact-sets-invalid-without-local-presence-message';
import {
  selectCanDisableLocalPresence,
  selectSelectedDomains
} from './selected-domains-slice';

// Filter on *.de to find domains in test with local presence
export const LocalPresenceDisable = () => {
  const canDisable = useSelector(selectCanDisableLocalPresence);
  const reseller = useSelector(selectReseller);
  const customerName = useSelector(selectCustomerName);
  const clientId = useSelector(selectClientId);
  const selectedDomains = useSelector(selectSelectedDomains);
  const { enqueueSnackbar } = useSnackbar();
  const shouldStartPolling = useRef(false);

  const orderOptions = {
    currency: 'USD',
    domainServiceAgreementAccepted: true,
    domainServiceAgreementAcceptedDate: dayjs().toISOString(),
    paymentType: 'FUTURE'
  };

  const lineItems = useRef([]);
  useEffect(() => {
    lineItems.current = selectedDomains
      .filter(({ usingLocalPresence }) => usingLocalPresence === true)
      .map(({ domainName }, index) => ({
        clientLineItemId: generateId(),
        domain: domainName,
        lineItemNum: index,
        lineItemType: 'LOCAL_PRESENCE',
        useLocalPresence: false,
        years: 1
      }));
  }, [selectedDomains]);

  const {
    data: contactSetsValidityData,
    isLoading: isVerifyContactSetsLoading
  } = useVerifyContactSetsForDomainsQuery(
    {
      customerName,
      domainsAndContactSetIds: selectedDomains.map(
        ({ customerContactSetId, domainName }) => ({
          contactSetId: customerContactSetId,
          domainName
        })
      ),
      reseller
    },
    { skip: !customerName || !canDisable }
  );

  const [
    createOrder,
    {
      isError: isCreateOrderError,
      isLoading: isCreateOrderLoading,
      isSuccess: isCreateOrderSuccess
    }
  ] = useCreateOrderMutation();

  const { isLoading: isPollJobLoading } = useJobResponse({
    failureMessage: 'Local presence failed',
    lineItems: lineItems.current,
    shouldStartPolling: shouldStartPolling.current,
    skipCondition: !isCreateOrderSuccess || !canDisable,
    successMessage: 'Local presence disabled for:',
    tagsToInvalidate: [CUSTOMER_TAG]
  });

  useEffect(() => {
    if (isCreateOrderError) {
      enqueueSnackbar(
        'An error occurred while creating your order. Try again.',
        {
          action: snackbarId => <SnackbarCloseAction snackbarId={snackbarId} />,
          persist: true,
          variant: 'error'
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreateOrderError]);

  const handleDisableLocalPresence = () => {
    if (contactSetsValidityData?.invalidDomainNames.length > 0) {
      enqueueSnackbar(
        getContactSetsInvalidWithoutLocalPresenceMessage(
          contactSetsValidityData?.invalidDomainNames
        ),
        {
          action: snackbarId => <SnackbarCloseAction snackbarId={snackbarId} />,
          persist: true,
          variant: 'warning'
        }
      );
    } else {
      // Start or restart polling
      shouldStartPolling.current = true;
      createOrder({
        clientId,
        customerName,
        lineItems: lineItems.current,
        orderOptions,
        reseller
      });
    }
  };

  return (
    canDisable && (
      <LoadingButton
        disabled={isVerifyContactSetsLoading}
        loading={
          isVerifyContactSetsLoading || isCreateOrderLoading || isPollJobLoading
        }
        onClick={handleDisableLocalPresence}
      >
        Disable local presence
      </LoadingButton>
    )
  );
};

// if all selected domains/tlds have local presence offered
// then if more than one domain is selected, show two buttons to enable/disable the local presence status

//   <div ng-if="Domains.domainsActions._perm.allHaveTldProperty('localPresenceOffered', true)">
//     <div ng-show="Domains.domainsActions._perm.selectedMoreThan(1)" style="padding-top: 5px;">
//       <button
//         class="button"
//         ng-show="Domains.domainsActions._perm.oneHasProperty('localPresenceInUse', false)"
//         ng-click="Domains.domainsActions.setLocalPresence(true)">
//         Enable Local Presence
//       </button>
//       <button
//         class="button"
//         ng-show="Domains.domainsActions._perm.oneHasProperty('localPresenceInUse', true)"
//         ng-click="Domains.domainsActions.setLocalPresence(false)">
//         Disable Local Presence
//       </button>
//     </div>
//   </div>
// </div>

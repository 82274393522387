import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  Stack,
  Typography,
  useTheme
} from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SignOffDialog } from '../../common/components/sign-offs/SignOffDialog';
import { SnackbarCloseAction } from '../../common/components/snackbar-close-action/SnackbarCloseAction';
import { CUSTOMER_TAG, apiSlice } from '../../common/store/api-slice';
import { selectCustomerName } from '../../common/store/customers-slice';
import {
  LINE_ITEM_STATUSES,
  POLLING_INTERVAL,
  POLLING_MAX_REQUESTS,
  usePollJobResponseQuery
} from '../../common/store/job-response-api-slice';
import { useCreateOrderMutation } from '../../common/store/orders-api-slice';
import { selectReseller } from '../../common/store/reseller';
import { generateId } from '../../common/utils/generate-id';
import { selectClientId } from '../auth/auth-slice';
import { LockUnlockAll } from './LockUnlockAll';
import {
  LOCK_TYPES,
  PROHIBITED_TYPES,
  defineJobDomainStatus,
  defineJobStatus,
  getEnabledValue,
  getProhibitedValue
} from './registrar-locks';
import { selectSelectedDomains } from './selected-domains-slice';

export const DomainDetailsLockUnlockMulti = () => {
  const selectedDomains = useSelector(selectSelectedDomains);

  const updateLockSupport = selectedDomains.some(
    domain => domain.$tld?.updateLockSupport
  );
  const transferLockSupport = selectedDomains.some(
    domain => domain.$tld?.transferLockSupport
  );
  const deleteLockSupport = selectedDomains.some(
    domain => domain.$tld?.deleteLockSupport
  );

  const theme = useTheme();
  const dispatch = useDispatch();

  const [isBackdropOpen, setIsBackdropOpen] = useState(false);
  const [isSignOffDialogOpen, setIsSignOffDialogOpen] = useState(false);
  const [fetchCount, setFetchCount] = useState(0);

  const clientId = useSelector(selectClientId);
  const reseller = useSelector(selectReseller);
  const customerName = useSelector(selectCustomerName);

  const [createOrder, { data: createOrderData }] = useCreateOrderMutation();

  const lineItems = useRef([]);
  const operation = useRef({ lockType: '', value: false });
  const clientLineItemIds = useRef([]);
  const jobStatus = useRef({
    failureCount: 0,
    pendingCount: 0,
    successCount: 0,
    total: 0
  });
  const jobDomainStatus = useRef([]);

  const shouldSkipPolling =
    createOrderData?.signOffRequired !== false ||
    Object.keys(lineItems.current).length === 0;

  const isLockAllBtnDisabled = lockType => {
    const lockSupportName = `${lockType}LockSupport`;
    return (
      selectedDomains.every(domain => domain.registryLocked) ||
      !selectedDomains.some(
        domain =>
          domain.$tld?.[lockSupportName] &&
          getEnabledValue(domain.$registrarLocks.types, lockType) === false
      )
    );
  };

  const isUnlockAllBtnDisabled = prohibitedType => {
    return (
      selectedDomains.every(domain => domain.registryLocked) ||
      selectedDomains.every(
        domain => !getProhibitedValue(domain.domainStatuses, prohibitedType)
      )
    );
  };

  const getLineItems = () => {
    const lockValue = operation.current.value;
    const operationLockType = operation.current.lockType;

    const getDomainStatuses = domain => {
      const { deleteLockSupport, transferLockSupport, updateLockSupport } =
        domain.$tld;
      const lockSupport = Object.freeze({
        [LOCK_TYPES.DELETE]: deleteLockSupport,
        [LOCK_TYPES.TRANSFER]: transferLockSupport,
        [LOCK_TYPES.UPDATE]: updateLockSupport
      });

      const domainStatuses = {};

      // only send the locks that the domains support using
      if (lockSupport[operationLockType]) {
        domainStatuses[PROHIBITED_TYPES[operationLockType.toUpperCase()]] =
          lockValue;
      }

      // If locking a domain for updates, also lock it for deletion.
      if (
        lockValue &&
        operationLockType === LOCK_TYPES.UPDATE &&
        lockSupport[LOCK_TYPES.DELETE]
      ) {
        domainStatuses.clientDeleteProhibited = lockValue;
      }

      return domainStatuses;
    };

    const anyLockAvailableSelectedDomains = selectedDomains.filter(domain => {
      const prohibitedType = getProhibitedValue(
        domain.domainStatuses,
        PROHIBITED_TYPES[operationLockType.toUpperCase()]
      );
      return (
        domain.$registrarLocks.anyAvailable && prohibitedType !== lockValue
      );
    });

    return anyLockAvailableSelectedDomains.map((domain, index) => ({
      clientLineItemId: generateId(),
      domain: domain.domainName,
      domainStatuses: getDomainStatuses(domain),
      lineItemNum: index,
      lineItemType: 'UPDATE_DOMAIN_STATUSES'
    }));
  };

  const sendOrder = () => {
    setFetchCount(0);
    lineItems.current = getLineItems();
    clientLineItemIds.current = lineItems.current.map(
      lineItem => lineItem.clientLineItemId
    );

    clientLineItemIds.current.length > 0
      ? (createOrder({
          clientId,
          customerName,
          lineItems: lineItems.current,
          orderOptions: { paymentType: 'NONE' },
          reseller
        }),
        setIsBackdropOpen(true))
      : showSnackbar(getJobSnackbarProperties());
  };

  const handleLockAll = event => {
    operation.current = {
      lockType: event.target.name,
      value: true
    };
    sendOrder();
  };

  const handleUnlockAll = event => {
    operation.current = {
      lockType: event.target.name,
      value: false
    };
    sendOrder();
  };

  const clearLineItems = () => {
    lineItems.current = [];
    clientLineItemIds.current = [];
  };

  const { data: jobResultData, refetch } = usePollJobResponseQuery(
    { clientId, clientLineItemIds: clientLineItemIds.current },
    { skip: shouldSkipPolling }
  );

  const handleCloseSignOffDialog = () => {
    setIsSignOffDialogOpen(false);
    clearLineItems();
  };

  const showSnackbar = ({ persist = false, snackbarContent, variant }) => {
    enqueueSnackbar(snackbarContent, {
      action: snackbarId =>
        persist && <SnackbarCloseAction snackbarId={snackbarId} />,
      persist,
      variant
    });
  };

  useEffect(() => {
    // sign off required - no sign off pending
    if (
      createOrderData?.signOffRequired &&
      !createOrderData?.forceUpdateRequired
    ) {
      showSnackbar({
        snackbarContent: 'Your request is now pending Sign-off',
        variant: 'info'
      });
      clearLineItems();
      setIsBackdropOpen(false);
    }
    // sign off required - sign off pending
    else if (
      createOrderData?.signOffRequired &&
      createOrderData?.forceUpdateRequired
    ) {
      setIsSignOffDialogOpen(true);
      setIsBackdropOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createOrderData]);

  const handleForceUpdate = () => {
    createOrder({
      clientId,
      customerName,
      lineItems: lineItems.current,
      orderOptions: { paymentType: 'NONE' },
      reseller,
      signOffForceUpdate: true
    });
    setIsBackdropOpen(true);
    setIsSignOffDialogOpen(false);
    clearLineItems();
  };

  useEffect(() => {
    if (jobResultData?.anyPending && fetchCount < POLLING_MAX_REQUESTS) {
      setFetchCount(prevCount => prevCount + 1);
      setTimeout(refetch, POLLING_INTERVAL.active);
    } else {
      let messageObject;
      // only get the message object if at least one domain was sent
      if (clientLineItemIds.current.length > 0) {
        messageObject = jobResultData?.messages.filter(message =>
          clientLineItemIds.current.includes(message.clientLineItemId)
        );
      }

      const isMessageObjectDetermined = messageObject?.length > 0;
      if (isMessageObjectDetermined) {
        jobStatus.current = defineJobStatus(messageObject);
        jobDomainStatus.current = defineJobDomainStatus(
          messageObject,
          lineItems.current
        );
        setIsBackdropOpen(false);
        showSnackbar(getJobSnackbarProperties());
      }

      if (isMessageObjectDetermined && jobStatus.current.successCount > 0) {
        dispatch(apiSlice.util.invalidateTags([CUSTOMER_TAG]));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobResultData]);

  const renderBackdrop = () => {
    return (
      <Backdrop
        open={isBackdropOpen}
        sx={{
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          bottom: 0,
          color: theme.palette.primary.main,
          left: 0,
          position: 'absolute',
          right: 0,
          top: 0,
          zIndex: theme => theme.zIndex.drawer + 1
        }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  const getJobSnackbarProperties = () => {
    const jobStatuses = jobStatus?.current;
    const isAllSuccess = jobStatuses.successCount === jobStatuses.total;
    const isAllFailure = jobStatuses.failureCount === jobStatuses.total;
    const numberLocks = lineItems.current.domainStatuses
      ? Object.keys(lineItems.current.domainStatuses).length
      : 0;
    const anyPending = jobStatuses.pendingCount > 0;

    // eslint-disable-next-line complexity
    const displayMixedCaseMessage = () => {
      if (anyPending) {
        return `Of the ${jobStatuses.total} domains sent, ${
          operation.current.value ? 'applying' : 'removing'
        } the ${operation.current.lockType} ${
          numberLocks === 1 ? 'lock' : 'locks'
        } succeeded for ${jobStatuses.successCount} domains and failed for ${
          jobStatuses.failureCount
        } domains. ${jobStatuses.pendingCount} domains are pending response.`;
      } else if (jobStatuses.failureCount <= 5) {
        const failedDomainNames = jobDomainStatus.current
          .filter(job => job.status === LINE_ITEM_STATUSES.failure)
          .map(failedJob => failedJob.domain);
        return `An error was encountered while ${
          operation.current.value ? 'applying' : 'removing'
        } the ${operation.current.lockType} ${
          numberLocks === 1 ? 'lock' : 'locks'
        } for the following ${
          failedDomainNames.length > 1 ? 'domains' : 'domain'
        } : ${failedDomainNames.join(',\n')}`;
      } else if (jobStatuses.successCount <= 5) {
        const succeededDomainNames = jobDomainStatus.current
          .filter(job => job.status === LINE_ITEM_STATUSES.success)
          .map(suceededJob => suceededJob.domain);
        return `The ${operation.current.lockType} ${
          numberLocks === 1 ? 'lock was' : 'locks were'
        } successful for the following ${
          succeededDomainNames.length > 1 ? 'domains' : 'domain'
        } : ${succeededDomainNames.join(',\n')}`;
      } else {
        return `Of the ${jobStatuses.total} domains sent, ${
          operation.current.value ? 'applying' : 'removing'
        } the ${operation.current.lockType} ${
          numberLocks === 1 ? 'lock' : 'locks'
        } succeeded for ${jobStatuses.successCount} domains and failed for ${
          jobStatuses.failureCount
        } domains`;
      }
    };

    const displayMessage = () => {
      if (clientLineItemIds.current.length === 0) {
        return `All the selected domains are already ${
          operation.current.lockType
        } ${
          operation.current.value ? 'locked' : 'unlocked'
        } or they don't support this operation.`;
      } else if (isAllSuccess) {
        return `The ${operation.current.lockType} lock${
          numberLocks === 1 ? ' was' : 's were'
        } successfully ${
          operation.current.value ? 'applied to' : 'removed from'
        } all selected domains`;
      } else if (isAllFailure) {
        return `An error was encountered while ${
          operation.current.value ? 'applying' : 'removing'
        } the ${operation.current.lockType} ${
          numberLocks === 1 ? 'lock' : 'locks'
        } ${operation.current.value ? 'for' : 'from'} the selected domains`;
      } else {
        return displayMixedCaseMessage();
      }
    };

    return {
      // in case of mixed success / failure message, the snackbar should be persisted.
      persist: !(isAllSuccess || isAllFailure),
      snackbarContent: displayMessage(),
      // eslint-disable-next-line no-nested-ternary
      variant: isAllSuccess ? 'success' : isAllFailure ? 'error' : 'warning'
    };
  };

  const renderLockColumn = () => {
    return (
      <Stack
        spacing={1}
        sx={{ alignItems: 'center', paddingTop: 3, width: '100%' }}
      >
        {updateLockSupport && (
          <Button
            disabled={isLockAllBtnDisabled(LOCK_TYPES.UPDATE)}
            name={LOCK_TYPES.UPDATE}
            onClick={handleLockAll}
            sx={{
              height: { lg: 60, xs: 50 },
              width: { xl: 150, xs: 130 }
            }}
            variant="outlined"
          >
            Lock all updates
          </Button>
        )}
        {transferLockSupport && (
          <Button
            disabled={isLockAllBtnDisabled(LOCK_TYPES.TRANSFER)}
            name={LOCK_TYPES.TRANSFER}
            onClick={handleLockAll}
            sx={{
              height: { lg: 60, xs: 50 },
              width: { xl: 150, xs: 130 }
            }}
            variant="outlined"
          >
            Lock all transfers
          </Button>
        )}
        {deleteLockSupport && (
          <Button
            disabled={isLockAllBtnDisabled(LOCK_TYPES.DELETE)}
            name={LOCK_TYPES.DELETE}
            onClick={handleLockAll}
            sx={{
              height: { lg: 60, xs: 50 },
              width: { xl: 150, xs: 130 }
            }}
            variant="outlined"
          >
            Lock all deletions
          </Button>
        )}
      </Stack>
    );
  };

  const renderUnlockColumn = () => {
    return (
      <Stack
        spacing={1}
        sx={{ alignItems: 'center', paddingTop: 3, width: '100%' }}
      >
        {updateLockSupport && (
          <Button
            disabled={isUnlockAllBtnDisabled(PROHIBITED_TYPES.UPDATE)}
            name={LOCK_TYPES.UPDATE}
            onClick={handleUnlockAll}
            sx={{
              height: { lg: 60, xs: 50 },
              width: { xl: 150, xs: 130 }
            }}
            variant="outlined"
          >
            Unlock all updates
          </Button>
        )}
        {transferLockSupport && (
          <Button
            disabled={isUnlockAllBtnDisabled(PROHIBITED_TYPES.TRANSFER)}
            name={LOCK_TYPES.TRANSFER}
            onClick={handleUnlockAll}
            sx={{
              height: { lg: 60, xs: 50 },
              width: { xl: 150, xs: 130 }
            }}
            variant="outlined"
          >
            Unlock all transfers
          </Button>
        )}
        {deleteLockSupport && (
          <Button
            disabled={isUnlockAllBtnDisabled(PROHIBITED_TYPES.DELETE)}
            name={LOCK_TYPES.DELETE}
            onClick={handleUnlockAll}
            sx={{
              height: { lg: 60, xs: 50 },
              width: { xl: 150, xs: 130 }
            }}
            variant="outlined"
          >
            Unlock all deletions
          </Button>
        )}
      </Stack>
    );
  };

  return (
    <Box>
      <Typography variant="h6">Multiple Domains</Typography>
      <Grid container sx={{ paddingBottom: 2, width: '100%' }}>
        <Grid container item lg={6} xs={12}>
          {renderLockColumn()}
        </Grid>
        <Grid container item lg={6} xs={12}>
          {renderUnlockColumn()}
        </Grid>
      </Grid>
      <LockUnlockAll position />
      {renderBackdrop()}
      <SignOffDialog
        handleClose={handleCloseSignOffDialog}
        handleSubmit={handleForceUpdate}
        isOpen={isSignOffDialogOpen}
      />
    </Box>
  );
};
